import React from 'react'

import { colors, sizes } from '~/assets/styles/variables'
import useWindowDimensions from '~/utils/useWindowDimensions'

import Tag from '~/components/atoms/Tag'
import { P3, P4 } from '~/components/atoms/Paragraph'
import ProposalTypeEnum from '~/models/enums/ProposalTypeEnum'

import { ProposalConditionInterface } from '~/models/interfaces/services/Proposal'

import { useFormats } from '~/utils/useFormats'
import { usePluralization } from '~/utils/usePluralization'

import { ConditionNumberColumn } from './ConditionListItem.styles'

const { darkGray } = colors
const { size0 } = sizes

interface ConditionListItemInterface {
  condition: ProposalConditionInterface
  type: ProposalTypeEnum
}

const ConditionListItem = (
  { condition, type }: ConditionListItemInterface,
) => {
  const {
    formatDateDefault, formatCurrency, formatShortDateDefault, formatNumberStartWithZero,
  } = useFormats()
  const { pluralize } = usePluralization()
  const { md } = useWindowDimensions()

  return (
    <ConditionNumberColumn>
      {type === ProposalTypeEnum.Deal ? (
        <>
          <div>
            <P4 color={darkGray}>
              Quitação por:
            </P4>
            <P3 marginBottom={size0}>
              {formatCurrency(condition.portionValue)}
            </P3>
          </div>
          <div>
            <P4 color={darkGray}>
              Vencimento:
            </P4>
            <P3 marginBottom={size0}>
              {formatDateDefault(new Date(condition.initialDueDate))}
            </P3>
          </div>
          <Tag small>
            {!md ? 'À vista' : 'Pagamento à vista'}
          </Tag>
        </>
      ) : (
        <>
          <div>
            <P4 color={darkGray}>
              Valor entrada:
            </P4>
            <P3 marginBottom={size0}>
              {formatCurrency(condition.inputValue)}
            </P3>
          </div>
          <div>
            <P4 color={darkGray}>
              Venc. entrada:
            </P4>
            <P3 marginBottom={size0}>
              {!md ? formatShortDateDefault(new Date(condition.emissionDate)) : formatDateDefault(new Date(condition.emissionDate))}
            </P3>
          </div>
          <div>
            <P4 color={darkGray}>
              {formatNumberStartWithZero(condition.numberInstallments)}
              {' '}
              {`${pluralize(condition.numberInstallments, 'parcela')} de:`}
            </P4>
            <P3 marginBottom={size0}>
              {formatCurrency(condition.portionValue)}
            </P3>
          </div>
          <div>
            <P4 color={darkGray}>
              {!md ? '1º venc.:' : '1º vencimento:'}
            </P4>
            <P3 marginBottom={size0}>
              {!md ? formatShortDateDefault(new Date(condition.initialDueDate)) : formatDateDefault(new Date(condition.initialDueDate))}
            </P3>
          </div>
        </>
      )}
    </ConditionNumberColumn>
  )
}

export default ConditionListItem
