import React from 'react'
import { Col, Row } from 'react-grid-system'
import { useHistory } from 'react-router-dom'

import { colors, sizes } from '~/assets/styles/variables'
import { ErrorIcon } from '~/assets/icons'

import Button from '~/components/atoms/Button'
import { H5Bold } from '~/components/atoms/Heading'
import PageContainer from '~/components/atoms/PageContainer'
import Wizard from '~/components/molecules/Wizard'
import ContactMethods from '~/components/organisms/ContactMethods'
import PortalTemplate from '~/components/templates/PortalTemplate'

import { pageWidth } from '~/config/layout/pageWidth'
import setTitle from '~/config/title'

import { WizardInputInterface } from '~/models/interfaces/components/Wizard'

import { H4Styled } from './EffectuationError.styles'

const { size32, size38, size64 } = sizes
const { red } = colors

interface EffectuationErrorInterface {
  title: string,
  wizardElements: WizardInputInterface[]
}

const EffectuationError = ({ title, wizardElements }: EffectuationErrorInterface) => {
  setTitle(title)

  const history = useHistory()

  const containerPageWidth = pageWidth.medium

  const handleRedirect = (page: string) => history.push(page)

  return (
    <PortalTemplate actionBar={false}>
      <Wizard>{wizardElements}</Wizard>

      <PageContainer width={containerPageWidth}>
        <Row justify="center">
          <Col md={10}>
            <H4Styled>
              <ErrorIcon
                color={red}
                size={size38}
              />
              Ops! Infelizmente não conseguimos efetivar sua negociação nessas condições.
            </H4Styled>

            <H5Bold marginBottom={size32}>
              Por favor, escolha abaixo um canal de contato de sua preferência para te ajudarmos no que precisar.
            </H5Bold>

            <ContactMethods marginBottom={size64} />

            <Button
              onClick={() => handleRedirect('/menu-cliente')}
              type="button"
              displayBlock
            >
              Retornar ao menu
            </Button>
          </Col>
        </Row>
      </PageContainer>
    </PortalTemplate>
  )
}

export default EffectuationError
