import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Form, Formik } from 'formik'
import * as yup from 'yup'

import { Col, Row } from 'react-grid-system'
import { colors, sizes } from '~/assets/styles/variables'

import { CampaignBanner } from '~/components/atoms/Banner/common'
import { H4 } from '~/components/atoms/Heading'
import PageContainer from '~/components/atoms/PageContainer'
import { P2, P3 } from '~/components/atoms/Paragraph'
import ActionBar from '~/components/molecules/ActionBar'
import Wizard from '~/components/molecules/Wizard'
import PortalTemplate from '~/components/templates/PortalTemplate'

import { pageWidth } from '~/config/layout/pageWidth'
import setTitle from '~/config/title'

import { WizardInputInterface } from '~/models/interfaces/components/Wizard'

import { RootState } from '~/store/reducers'
import { getNegotiationsByCustomer } from '~/services/Negotiations'
import FormControl from '~/components/molecules/FormControl'
import { RadioButtonValuesInterface } from '~/models/interfaces/components/FormControl'
import { Card, CardBody } from '~/components/atoms/Card'
import Button from '~/components/atoms/Button'
import NegotiationRadioButtonContent from './NegotiationRadioButtonContent'

const { darkGray } = colors
const { size0, size24, size64 } = sizes

interface StateInterface {
  negotiationId: string | null
}

const initialValues: StateInterface = { negotiationId: null }

const validationSchema: yup.SchemaOf<StateInterface> = yup.object({
  negotiationId: yup.string().required(''),
})

const Negotiations = () => {
  setTitle('Negociações disponíveis - Renegociação')

  const wizardElements: WizardInputInterface[] = [
    {
      title: 'Negociações disponíveis',
      active: true,
    },
    {
      title: 'Revisão de contratos',
    },
    {
      title: 'Valor da entrada e datas de vencimento',
    },
    {
      title: 'Geração de boleto',
    },
  ]

  const history = useHistory()

  const authSelector = useSelector(
    ({ auth }: RootState) => auth,
  )

  const { user } = authSelector

  const userDataSelector = useSelector(
    ({ userDataProcessing }: RootState) => userDataProcessing,
  )

  const { idEntryAutomation } = userDataSelector

  const containerPageWidth = pageWidth.medium

  const [isLoaded,
    setIsLoaded] = useState<boolean>(false)

  const [negotiations,
    setNegotiations] = useState<RadioButtonValuesInterface[]>([])

  useEffect(() => {
    if (!user) return

    getNegotiationsByCustomer(user.id, user.cpfCnpj, idEntryAutomation)
      .then((response) => {
        const hasContracts = response.filter((negotiation) => negotiation.hasContracts)
        const availableNegotiations:RadioButtonValuesInterface[] = hasContracts.map((negotiation) => ({
          id: negotiation.externalCode,
          label: <NegotiationRadioButtonContent title={negotiation.name} />,
        }))
        setNegotiations(availableNegotiations)
      })
      .finally(() => setIsLoaded(true))
  }, [idEntryAutomation, user])

  const handleSubmit = (values: StateInterface) => {
    history.push('/renegociacao/revisao-contratos', values)
  }

  const handleRedirect = (page: string) => history.push(page)

  return (
    <PortalTemplate>
      {isLoaded && (
        <>
          {negotiations.length ? (
            <>
              <Wizard>{wizardElements}</Wizard>

              <PageContainer width={containerPageWidth}>
                <CampaignBanner />

                <H4 marginBottom={size24}>
                  Para renegociar suas dívidas, selecione uma das negociações disponíveis abaixo:
                </H4>

                <P3 color={darkGray} marginBottom={size64}>
                  Ao selecionar uma renegociação e clicar para continuar, incluíremos de
                  {' '}
                  forma automática todos os contratos que podem ser negociados juntos.
                </P3>

                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  validationSchema={validationSchema}
                  validateOnMount
                >
                  {({
                    handleChange, isValid, values,
                  }) => (
                    <Form>
                      <FormControl
                        marginBottom={size0}
                        name="negotiationId"
                        onChange={handleChange}
                        showError={false}
                        textAlign="left"
                        type="radio"
                        value={values.negotiationId}
                        valuesRadio={negotiations}
                      />

                      <ActionBar
                        returnAction={() => handleRedirect('/menu-cliente')}
                        submitActive={isValid}
                        width={containerPageWidth}
                      />
                    </Form>
                  )}
                </Formik>
              </PageContainer>
            </>
          )
            : (
              <PageContainer textAlign="center" width={pageWidth.small}>
                <H4 marginTop={size64}>
                  No momento não é possível negociar nenhum dos seus contratos.
                </H4>

                <Row justify="center">
                  <Col xs={11} sm={7}>
                    <Card marginBottom={size0} negative>
                      <CardBody>
                        <P2 color={darkGray}>
                          Mesmo assim, você ainda pode quitar valores à vista com desconto.
                        </P2>

                        <Button
                          displayBlock
                          onClick={() => handleRedirect('/quitacao')}
                          type="button"
                        >
                          Quitar valores à vista
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </PageContainer>
            )}
        </>
      )}
    </PortalTemplate>
  )
}

export default Negotiations
