import { AuthActionTypes } from './types'
import initialState from './initialState'
import { AuthInterface } from '~/models/interfaces/services/Auth'
import AuthEnum from '~/models/enums/AuthEnum'

export function authReducer(
  state = initialState,
  action: AuthActionTypes,
): AuthInterface {
  switch (action.type) {
    case 'LOGIN':
      localStorage.setItem(AuthEnum.TOKEN, action.payload.token || '')
      localStorage.setItem(AuthEnum.USER, JSON.stringify(action.payload.user))

      return {
        ...state,
        ...action.payload,
        loggedIn: true,
      }

    case 'LOGOUT':
      localStorage.clear()

      return {
        ...initialState,
        loggedIn: false,
      }

    default:
      return state
  }
}
