import styled from 'styled-components'
import { pageWidth } from '~/config/layout/pageWidth'

import { sizes } from '~/assets/styles/variables'
import { device } from '~/config/device'

const { size16, size32, size100Percent } = sizes

interface ContentInterface {
  textAlign?: string,
  width?: number
}

export const Container = styled.div`
  padding-inline: ${size16};
  
  @media ${device.md} {
    padding-inline: ${size32};
  }
`

export const Content = styled.div`
  margin: auto;
  max-width: ${({ width }: ContentInterface) => (width ? `${width}px` : `${pageWidth.medium}px`)};
  text-align: ${({ textAlign }: ContentInterface) => textAlign || 'left'};
  width: ${size100Percent};
`
