import React, {
  useCallback, useEffect, useRef, useState,
} from 'react'

import { Hidden, Row, Visible } from 'react-grid-system'
import { useHistory, useLocation } from 'react-router-dom'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import { useSelector } from 'react-redux'

import { colors, sizes } from '~/assets/styles/variables'

import { CampaignBanner } from '~/components/atoms/Banner/common'
import Button from '~/components/atoms/Button'
import { Card } from '~/components/atoms/Card'
import { H4 } from '~/components/atoms/Heading'
import PageContainer from '~/components/atoms/PageContainer'
import { P1Bold, P2, P4 } from '~/components/atoms/Paragraph'
import Span from '~/components/atoms/Span'
import FormControl from '~/components/molecules/FormControl'
import ActionBar from '~/components/molecules/ActionBar'
import Wizard from '~/components/molecules/Wizard'
import PortalTemplate from '~/components/templates/PortalTemplate'

import { pageWidth } from '~/config/layout/pageWidth'
import setTitle from '~/config/title'

import {
  RadioButtonValuesInterface,
  SelectOptionsInterface,
} from '~/models/interfaces/components/FormControl'

import GoogleAnalyticsEventCategoryTypeEnum from '~/models/enums/GoogleAnalyticsEventCategoryTypeEnum'
import GoogleAnalyticsEventTypeEnum from '~/models/enums/GoogleAnalyticsEventTypeEnum'
import ModalDialogTypeEnum from '~/models/enums/ModalDialogTypeEnum'
import { WizardInputInterface } from '~/models/interfaces/components/Wizard'

import {
  RenegotiationEffectuationInputInterface,
  RenegotiationEffectuationInstallmentInputInterface,
  RenegotiationEffectuationParcelingInputInterface,
  RenegotiationSimulationOutputInterface,
  RenegotiationSimulationParamsInterface,
} from '~/models/interfaces/services/Negotiations'

import { effectuate, simulate } from '~/services/Negotiations'

import { RootState } from '~/store/reducers'
import store from '~/store'

import { useFormats } from '~/utils/useFormats'
import { useGoogleAnalytics } from '~/utils/useGoogleAnalytics'

import {
  ColStyled, DiscountValueCardBodyStyled, H2Styled, ParcelingLabel,
} from './Effectuation.styles'

const {
  size0, size16, size24, size32, size48,
} = sizes

const { focusBlue, gray, orange } = colors

interface StateInterface {
  negotiationId: string
  installmentIds: string[]
}

interface EffectuationInputInterface {
  customerId: string
  installmentIds: string[]
  issueDate: Date | string
  negotiationId: string
  valueFirstInstallment: number | string
  dueDate?: string
  amountInstallments: number | string
}

const validationSchema: yup.SchemaOf<EffectuationInputInterface> = yup.object({
  customerId: yup.string().required(''),
  installmentIds: yup.array().min(1, ''),
  issueDate: yup.string().required(''),
  negotiationId: yup.string().required(''),
  valueFirstInstallment: yup.number().required(''),
  dueDate: yup.string().required(''),
  amountInstallments: yup.number().required(''),
})

const Effectuation = () => {
  setTitle('Efetivação - Renegociação')

  const wizardElements: WizardInputInterface[] = [
    {
      title: 'Negociações disponíveis',
    },
    {
      title: 'Revisão de contratos',
    },
    {
      title: 'Valor da entrada e datas de vencimento',
      active: true,
    },
    {
      title: 'Geração de boleto',
    },
  ]

  const containerPageWidth = pageWidth.medium

  const { regEventGa } = useGoogleAnalytics()
  const history = useHistory()

  const { formatCurrency, formatDateDefault, formatDateFilters } = useFormats()

  const location = useLocation()
  const state = location.state as StateInterface

  const authSelector = useSelector(
    ({ auth }: RootState) => auth,
  )

  const { user } = authSelector

  const userDataSelector = useSelector(
    ({ userDataProcessing }: RootState) => userDataProcessing,
  )

  const { idEntryAutomation } = userDataSelector

  const [isLoaded,
    setIsLoaded] = useState<boolean>(false)

  const [initialValues,
    setInitialValues] = useState<EffectuationInputInterface>({
      customerId: '',
      installmentIds: [],
      issueDate: '',
      negotiationId: '',
      valueFirstInstallment: '',
      dueDate: '',
      amountInstallments: '',
    })

  const [simulationOutput,
    setSimulationOutput] = useState<RenegotiationSimulationOutputInterface | null>(null)

  const [issueDateRange,
    setIssueDateRange] = useState<SelectOptionsInterface[]>([])

  const [simulationActive,
    setSimulationActive] = useState<boolean>(true)

  const [calculationActive,
    setCalculationActive] = useState<boolean>(true)

  const [showDueDate,
    setShowDueDate] = useState<boolean>(false)

  const [dueDateRange,
    setDueDateRange] = useState<SelectOptionsInterface[]>([])

  const [parcelings,
    setParcelings] = useState<RadioButtonValuesInterface[]>([])

  const [showParcelings,
    setShowParcelings] = useState<boolean>(false)

  const convertDateRangeToSelectOption = useCallback((date: Date | string): SelectOptionsInterface => (
    {
      label: formatDateDefault(new Date(date)),
      value: formatDateFilters(new Date(date)),
    }
  ), [formatDateDefault, formatDateFilters])

  useEffect(() => {
    if (!user
      || !state
      || !state.negotiationId
      || simulationOutput) return

    const simulationInput: RenegotiationSimulationParamsInterface = {
      cpfCnpj: user.cpfCnpj,
      customerId: user.id,
      negotiationId: state.negotiationId,
      renegotiationStep: 2,
      eventDescription: '',
      idEntryAutomation,
    }

    simulate(simulationInput)
      .then((response) => {
        setSimulationOutput(response)

        setInitialValues({
          ...initialValues,
          customerId: user.id,
          installmentIds: state.installmentIds,
          negotiationId: state.negotiationId,
          valueFirstInstallment: response.valueFirstInstallment,
        })
      })
      .finally(() => setIsLoaded(true))
  }, [idEntryAutomation, initialValues, simulationOutput, state, user])

  useEffect(() => {
    if (!simulationOutput
      || !simulationOutput.issueDateRange
      || issueDateRange.length) return

    const newIssueDateRange: SelectOptionsInterface[] = simulationOutput.issueDateRange.map((issueDate) => convertDateRangeToSelectOption(issueDate))

    setIssueDateRange(newIssueDateRange)
  }, [
    convertDateRangeToSelectOption,
    issueDateRange.length,
    simulationOutput,
  ])

  useEffect(() => {
    if (!simulationOutput) return

    const newParcelings: RadioButtonValuesInterface[] = []

    simulationOutput.parcelings.forEach((p) => {
      const label = (
        <ParcelingLabel>
          <Hidden xs>
            <b>{formatCurrency(p.valueTotal)}</b>
            {' com entrada de '}
            <b>{formatCurrency(p.valueFirstInstallment)}</b>
            {` + ${p.numberInstallments}x`}
          </Hidden>

          <Visible xs>
            {formatCurrency(p.valueFirstInstallment)}
            {' + '}
            <b>{`${p.numberInstallments}x`}</b>
          </Visible>
          {' de '}
          <b>{formatCurrency(p.valueInstallment)}</b>
        </ParcelingLabel>
      )

      newParcelings.push({
        id: `${p.numberInstallments}`,
        label,
      })
    })

    setParcelings(newParcelings)
  }, [
    formatCurrency,
    simulationOutput,
  ])

  const simulationResultRef = useRef<HTMLDivElement>(null)
  const effectuationResultRef = useRef<HTMLDivElement>(null)

  const handleChangeSimulation = (setFieldValue: any) => {
    setFieldValue('dueDate', '')
    setFieldValue('amountInstallments', '')

    setSimulationActive(true)
    setShowDueDate(false)
    setShowParcelings(false)
  }

  const handleSimulate = (values: EffectuationInputInterface) => {
    if (!user
      || !simulationOutput) return

    regEventGa(
      GoogleAnalyticsEventCategoryTypeEnum.Renegociacao,
      GoogleAnalyticsEventTypeEnum.RenegociacaoSimular,
    )

    if (
      +values.valueFirstInstallment < simulationOutput.valueFirstInstallmentMin
       || +values.valueFirstInstallment > simulationOutput.valueFirstInstallmentMax
    ) {
      const title = +values.valueFirstInstallment < simulationOutput.valueFirstInstallmentMin
        ? 'O valor de entrada informado é inferior ao mínimo permitido!'
        : 'O valor de entrada informado é superior ao máximo permitido!'

      store.dispatch({
        type: 'SET_MODAL_DIALOG_INFO',
        payload: {
          cancelButtonText: 'Ok, fechar',
          Children: () => (
            <P2>
              {+values.valueFirstInstallment < simulationOutput.valueFirstInstallmentMin
                ? 'Por favor, revise o valor informado e realize sua simulação novamente.'
                : (
                  <>
                    {'Valor máximo: '}
                    <Span color={orange}>
                      {formatCurrency(simulationOutput.valueFirstInstallmentMax)}
                    </Span>
                    ! Altere o valor informado e realize sua simulação novamente.
                  </>
                )}
            </P2>
          ),
          handleCancel: null,
          handleConfirm: null,
          title,
          type: ModalDialogTypeEnum.Warning,
        },
      })

      store.dispatch({ type: 'SET_VISIBLE_MODAL_DIALOG' })

      return
    }

    setSimulationActive(false)
    setCalculationActive(true)

    const {
      customerId, negotiationId, issueDate, valueFirstInstallment, dueDate,
    } = values

    const issueDateLabel = issueDateRange.find((d) => d.value === issueDate)?.label
    const newIssueDateRange = [...issueDateRange]
    const minIssueDate = newIssueDateRange.shift()
    const maxIssueDate = newIssueDateRange.pop()

    const eventDescription = `Data mínima da entrada: ${minIssueDate?.label}, `
                           + `Data máxima da entrada: ${maxIssueDate?.label}, `
                           + `Data da entrada selecionada: ${issueDateLabel}`
                           + `\nValor informado para entrada: ${formatCurrency(+valueFirstInstallment)}`

    const simulationInput: RenegotiationSimulationParamsInterface = {
      cpfCnpj: user.cpfCnpj,
      customerId,
      negotiationId,
      renegotiationStep: 3,
      eventDescription,
      idEntryAutomation,
      issueDate,
      dueDate,
      valueFirstInstallment: valueFirstInstallment ? +valueFirstInstallment : undefined,
    }

    simulate(simulationInput)
      .then((response) => {
        setSimulationOutput(response)

        const newDueDateRange = response.dueDateRange.map((due) => convertDateRangeToSelectOption(due))

        setDueDateRange(newDueDateRange)
        setShowDueDate(true)

        if (simulationResultRef.current) {
          window.scrollTo({
            behavior: 'smooth',
            top: simulationResultRef.current.offsetTop,
          })
        }
      })
  }

  const handleChangeDueDate = (
    event: React.ChangeEvent<HTMLSelectElement>,
    setFieldValue: any,
  ) => {
    setFieldValue('amountInstallments', '')

    setCalculationActive(true)
    setShowParcelings(false)
  }

  const handleCalculate = (values: EffectuationInputInterface) => {
    if (!user) return

    regEventGa(
      GoogleAnalyticsEventCategoryTypeEnum.Renegociacao,
      GoogleAnalyticsEventTypeEnum.RenegociacaoCalcular,
    )

    setCalculationActive(false)

    const {
      customerId, negotiationId, issueDate, valueFirstInstallment, dueDate,
    } = values

    const dueDateLabel = dueDateRange.find((d) => d.value === dueDate)?.label
    const newDueDateRange = [...dueDateRange]
    const minDueDate = newDueDateRange.shift()
    const maxDueDate = newDueDateRange.pop()

    const eventDescription = `Data mínima do primeiro vencimento: ${minDueDate?.label}, `
                           + `Data máxima do primeiro vencimento: ${maxDueDate?.label}`
                           + `\nData do primeiro vencimento selecionada: ${dueDateLabel}`

    const simulationInput: RenegotiationSimulationParamsInterface = {
      cpfCnpj: user.cpfCnpj,
      customerId,
      negotiationId,
      renegotiationStep: 4,
      eventDescription,
      idEntryAutomation,
      issueDate,
      dueDate,
      valueFirstInstallment: valueFirstInstallment ? +valueFirstInstallment : undefined,
    }

    simulate(simulationInput)
      .then((response) => {
        setSimulationOutput(response)
        setShowParcelings(true)

        if (simulationResultRef.current) {
          window.scrollTo({
            behavior: 'smooth',
            top: simulationResultRef.current.offsetTop,
          })
        }
      })
  }

  const handleEffectuate = (values: EffectuationInputInterface) => {
    regEventGa(
      GoogleAnalyticsEventCategoryTypeEnum.Renegociacao,
      GoogleAnalyticsEventTypeEnum.RenegociacaoEfetivar,
    )

    const { amountInstallments } = values

    const installmentInputList: RenegotiationEffectuationInstallmentInputInterface[] = []

    if (simulationOutput && simulationOutput.contracts && simulationOutput.contracts.length) {
      simulationOutput.contracts.forEach((c) => {
        if (c.installments && c.installments.length) {
          c.installments.forEach((i) => {
            installmentInputList.push(
              {
                installmentId: i.externalCode,
                valueDiscount: i.valueDiscount,
                valueDiscountInterest: i.valueDiscountInterest,
                valueDiscountInterestOnArrears: i.valueDiscountInterestOnArrears,
                valueDiscountLateFee: i.valueDiscountLateFee,
                valueDiscountMain: i.valueDiscountMain,
                valueDiscountPermanence: i.valueDiscountPermanence,
                valueDiscountsOthers: i.valueDiscountsOthers,
              },
            )
          })
        }
      })
    }

    const selectedParceling = simulationOutput?.parcelings
      .filter((p) => p.numberInstallments === +amountInstallments)[0]

    const parcelingInput: RenegotiationEffectuationParcelingInputInterface = {
      amountInstallments,
      discountDebt: selectedParceling?.discountDebt || 0,
      discountFee: selectedParceling?.discountFee || 0,
      dueDate: simulationOutput?.dueDate || '',
      issueDate: simulationOutput?.issueDate || '',
      operationFee: selectedParceling?.operationFee || 0,
      valueFirstInstallment: selectedParceling?.valueFirstInstallment || 0,
    }

    const effectiationInput: RenegotiationEffectuationInputInterface = {
      customerId: user?.id || '',
      negotiationId: state.negotiationId,
      installments: installmentInputList,
      parceling: parcelingInput,
      idEntryAutomation,
    }

    effectuate(effectiationInput)
      .then((response) => history.push('/renegociacao/geracao-boleto', { deal: response }))
      .catch(() => history.push('/renegociacao/erro'))
  }

  return (
    <PortalTemplate>
      {isLoaded && (
      <>
        { !!simulationOutput && simulationOutput.parcelings && (
        <>
          <Wizard>{wizardElements}</Wizard>

          <PageContainer width={containerPageWidth}>
            <CampaignBanner />

            <H4 marginBottom={size48}>
              Escolha o valor da entrada e as datas dos vencimentos para calcularmos o valor final com desconto:
            </H4>

            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={handleEffectuate}
              validateOnChange
              validateOnMount
              validationSchema={validationSchema}
            >
              {({
                handleChange, isValid, setFieldValue, values,
              }) => (
                <Form autoComplete="off">
                  <Row gutterWidth={16}>
                    <ColStyled md={4}>
                      <FormControl
                        name="valueFirstInstallment"
                        onChange={(name, value) => {
                          handleChange({ target: { name, value } })
                          handleChangeSimulation(setFieldValue)
                        }}
                        requiredText={`(mínimo ${formatCurrency(simulationOutput.valueFirstInstallmentMin)})`}
                        title="Valor entrada"
                        type="currency"
                        value={values.valueFirstInstallment}
                      />
                    </ColStyled>

                    <ColStyled md={4}>
                      <FormControl
                        name="issueDate"
                        onChange={(event) => {
                          handleChange(event)
                          handleChangeSimulation(setFieldValue)
                        }}
                        placeholder="Escolha a data"
                        showError={false}
                        title="Vencimento entrada*"
                        type="select"
                        value={values.issueDate}
                        valuesSelect={issueDateRange}
                      />
                    </ColStyled>

                    <ColStyled md={4}>
                      <div style={{ marginBottom: size32 }}>
                        <Button
                          disabled={!values.valueFirstInstallment
                            || !values.issueDate
                            || !simulationActive}
                          displayBlock
                          onClick={() => handleSimulate(values)}
                          size="large"
                        >
                          Simular
                        </Button>
                      </div>
                    </ColStyled>
                  </Row>

                  <div ref={simulationResultRef} />

                  {showDueDate && (
                  <Row gutterWidth={16}>
                    <ColStyled md={4}>
                      <FormControl
                        name="dueDate"
                        onChange={(event) => {
                          handleChange(event)
                          handleChangeDueDate(event, setFieldValue)
                        }}
                        placeholder="Escolha a data"
                        showError={false}
                        title="Vencimento primeira parcela*"
                        type="select"
                        value={values.dueDate}
                        valuesSelect={dueDateRange}
                      />
                    </ColStyled>

                    <ColStyled md={4}>
                      <div style={{ marginBottom: size32 }}>
                        <Button
                          disabled={!values.valueFirstInstallment
                            || !values.issueDate
                            || !values.dueDate
                            || !calculationActive}
                          displayBlock
                          size="large"
                          onClick={() => handleCalculate(values)}
                        >
                          Calcular
                        </Button>
                      </div>
                    </ColStyled>
                  </Row>
                  )}

                  <div ref={effectuationResultRef} />

                  {showParcelings ? (
                    <>
                      {!!simulationOutput.discountsTotal && (
                        <Card negative>
                          <DiscountValueCardBodyStyled>
                            <P1Bold
                              marginBottom={size0}
                              textAlign="center"
                            >
                              Total de descontos:
                            </P1Bold>
                            <H2Styled
                              color={focusBlue}
                              marginBottom={size0}
                            >
                              {formatCurrency(simulationOutput.discountsTotal)}
                            </H2Styled>
                          </DiscountValueCardBodyStyled>
                        </Card>
                      )}
                      <div style={{ marginTop: size32 }}>
                        <P1Bold marginBottom={size24}>
                          Escolha a melhor opção de parcelamento para você:
                        </P1Bold>

                        <FormControl
                          marginBottom={size0}
                          marginBottomItems={size16}
                          name="amountInstallments"
                          onChange={handleChange}
                          showError={false}
                          type="radio"
                          value={values.amountInstallments}
                          valuesRadio={parcelings}
                        />
                      </div>
                    </>
                  ) : (
                    <P4
                      color={gray}
                      marginBottom={size0}
                    >
                      *A data escolhida afeta os valores dos descontos
                    </P4>
                  )}

                  <ActionBar
                    nextButtonText="Efetivar"
                    submitActive={isValid}
                    width={containerPageWidth}
                  />
                </Form>
              )}
            </Formik>
          </PageContainer>
        </>
        )}
      </>
      )}
    </PortalTemplate>
  )
}

export default Effectuation
