import React from 'react'

import { colors, sizes } from '~/assets/styles/variables'

import { P4 } from '~/components/atoms/Paragraph'

const { darkGray } = colors
const { size0 } = sizes

interface SendAccesCodeMethodRadioButtonContentInterface {
  title: string,
  subtitle: string,
}

const SendAccesCodeMethodRadioButtonContent = ({
  title,
  subtitle,
}: SendAccesCodeMethodRadioButtonContentInterface) => (
  <>
    {title}
    <P4 color={darkGray} marginBottom={size0}>
      {subtitle}
    </P4>
  </>
)

export default SendAccesCodeMethodRadioButtonContent
