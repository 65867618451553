import { DischargeContractsInterface } from '~/models/interfaces/services/Contracts'

import initialState from './initialState'
import { DischargeContractsActionTypes } from './types'

export function dischargeContractsReducer(
  state = initialState,
  action: DischargeContractsActionTypes,
): DischargeContractsInterface {
  switch (action.type) {
    case 'SET_INITIAL_DISCHARGE_CONTRACTS':

      return {
        ...state,
        contracts: [],
      }

    case 'UPDATE_DISCHARGE_CONTRACTS':

      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}
