import React from 'react'
import styled, { css } from 'styled-components'

import {
  MdArrowBack,
  MdArrowDownward,
  MdArrowForward,
  MdArrowUpward,
  MdCheck,
  MdCheckCircle,
  MdClose,
  MdContentCopy,
  MdEmail,
  MdError,
  MdExitToApp,
  MdHome,
  MdInsertEmoticon,
  MdPix,
  MdSentimentDissatisfied,
  MdSentimentNeutral,
  MdWarning,
} from 'react-icons/md'

import {
  FaCreditCard,
  FaHandHoldingUsd,
  FaWhatsapp,
} from 'react-icons/fa'

import {
  BsEyeFill,
  BsEyeSlashFill,
} from 'react-icons/bs'

import { ReactComponent as Barcode } from '~/assets/images/icons/barcode.svg'
import { ReactComponent as Hands } from '~/assets/images/icons/hands.svg'
import { ReactComponent as Late } from '~/assets/images/icons/late.svg'
import { ReactComponent as MailError } from '~/assets/images/icons/mail-error.svg'
import { ReactComponent as MailSuccess } from '~/assets/images/icons/mail-success.svg'
import { ReactComponent as Support } from '~/assets/images/icons/support.svg'

import { BarCodeIcon } from './barCode'

import { sizes } from '~/assets/styles/variables'

const { size24 } = sizes

const defaultConfig = (props: any) => ({
  size: props.size || size24,
})

interface IconInterface {
  backgroundColor?: string
  border?: string
  borderRadius?: string
  color?: string
  margin?: string
  padding?: string
}

const cssDefault = css<IconInterface>`
  background-color: ${({ backgroundColor }) => (backgroundColor || 'transparent')};
  border-radius: ${({ borderRadius }) => (borderRadius || '0')};
  color: ${({ color }) => (color || 'inherit')};
  margin: ${({ margin }) => (margin || 'auto')};
`

// SVG icons

export const BarcodeIcon = styled(Barcode).attrs(defaultConfig)`
  ${cssDefault};
`

export const HandsIcon = styled(Hands).attrs(defaultConfig)`
  ${cssDefault};
`

export const LateIcon = styled(Late).attrs(defaultConfig)`
  ${cssDefault};
`

export const MailErrorIcon = styled(MailError).attrs(defaultConfig)`
  ${cssDefault};
`

export const MailSuccessIcon = styled(MailSuccess).attrs(defaultConfig)`
  ${cssDefault};
`

export const SupportIcon = styled(Support).attrs(defaultConfig)`
  ${cssDefault};
`

// Library icons

export const ArrowLeftIcon = styled(MdArrowBack).attrs(defaultConfig)`
  ${cssDefault};
`

export const ArrowRightIcon = styled(MdArrowForward).attrs(defaultConfig)`
  ${cssDefault};
`

export const CheckIcon = styled(MdCheck).attrs(defaultConfig)`
  ${cssDefault};
`

export const CheckCircleIcon = styled(MdCheckCircle).attrs(defaultConfig)`
  ${cssDefault};
`

export const CloseIcon = styled(MdClose).attrs(defaultConfig)`
  ${cssDefault};
`

export const CopyIcon = styled(MdContentCopy).attrs(defaultConfig)`
  ${cssDefault};
`

export const CreditCardIcon = styled(FaCreditCard).attrs(defaultConfig)`
  ${cssDefault};
`

export const EmailIcon = styled(MdEmail).attrs(defaultConfig)`
  ${cssDefault};
`

export const ErrorIcon = styled(MdError).attrs(defaultConfig)`
  ${cssDefault};
`

export const ExitIcon = styled(MdExitToApp).attrs(defaultConfig)`
  ${cssDefault};
`

export const HandHoldingIcon = styled(FaHandHoldingUsd).attrs(defaultConfig)`
  ${cssDefault};
`

export const HomeIcon = styled(MdHome).attrs(defaultConfig)`
  ${cssDefault};
`

export const InsertEmoticonIcon = styled(MdInsertEmoticon).attrs(defaultConfig)`
  ${cssDefault};
`

export const SentimentDissatisfiedIcon = styled(MdSentimentDissatisfied).attrs(defaultConfig)`
  ${cssDefault};
`

export const SentimentNeutralIcon = styled(MdSentimentNeutral).attrs(defaultConfig)`
  ${cssDefault};
`

export const WarningIcon = styled(MdWarning).attrs(defaultConfig)`
  ${cssDefault};
`

export const WhatsappIcon = styled(FaWhatsapp).attrs(defaultConfig)`
  ${cssDefault};
`

export const EyeIcon = styled(BsEyeFill).attrs(defaultConfig)`
  ${cssDefault};
`

export const CloseEyeIcon = styled(BsEyeSlashFill).attrs(defaultConfig)`
  ${cssDefault};
`

export const ArrowDownIcon = styled(MdArrowDownward).attrs(defaultConfig)`
  ${cssDefault};
`

export const ArrowUpIcon = styled(MdArrowUpward).attrs(defaultConfig)`
  ${cssDefault};
`

export const PixIcon = styled(MdPix).attrs(defaultConfig)`
  ${cssDefault};
`

const Icon = (params: any) => {
  const icon: any = {
    ArrowLeft: <ArrowLeftIcon {...params} />,
    ArrowRight: <ArrowRightIcon {...params} />,
    ArrowDown: <ArrowDownIcon {...params} />,
    ArrowUp: <ArrowUpIcon {...params} />,
    Check: <CheckIcon {...params} />,
    CheckCircle: <CheckCircleIcon {...params} />,
    Close: <CloseIcon {...params} />,
    Copy: <CopyIcon {...params} />,
    CreditCard: <CreditCardIcon {...params} />,
    Email: <EmailIcon {... params} />,
    Error: <ErrorIcon {... params} />,
    Exit: <ExitIcon {... params} />,
    HandHolding: <HandHoldingIcon {... params} />,
    Home: <HomeIcon {...params} />,
    InsertEmoticon: <InsertEmoticonIcon {...params} />,
    SentimentDissatisfied: <SentimentDissatisfiedIcon {...params} />,
    SentimentNeutral: <SentimentNeutralIcon {...params} />,
    Support: <SupportIcon {...params} />,
    Warning: <WarningIcon {...params} />,
    Whatsapp: <WhatsappIcon {...params} />,
    Eye: <EyeIcon {...params} />,
    CloseEye: <CloseEyeIcon {...params} />,
    Pix: <PixIcon {...params} />,
    BarCode: <BarCodeIcon {...params} />,
  }

  return icon[params.type] || null
}

export default Icon
