interface ColorsInterface {
  blue: string
  backgroundBlue: string
  darkGray: string
  darkOrange: string
  disabledBlue: string
  focusBlue: string
  focusOrange: string
  gray: string
  green: string
  lightBlue: string
  lightGray: string
  lightGreen: string
  lightOrange: string
  lightRed: string
  mediumOrange: string
  orange: string
  red: string
  opacity30Blue: string
  opacity40Blue: string
  white: string
  textBlack: string
}

interface FontSizesInterface {
  fontSize10: string
  fontSize11: string
  fontSize12: string
  fontSize14: string
  fontSize15: string
  fontSize16: string
  fontSize18: string
  fontSize20: string
  fontSize24: string
  fontSize30: string
  fontSize35: string
  fontSize42: string
}

interface SizesInterface {
  size0: string
  size1: string
  size2: string
  size3: string
  size4: string
  size5: string
  size6: string
  size8: string
  size10: string
  size12: string
  size14: string
  size15: string
  size16: string
  size18: string
  size20: string
  size24: string
  size25: string
  size28: string
  size32: string
  size38: string
  size48: string
  size50: string
  size56: string
  size64: string
  size72: string
  size240: string
  size300: string
  size380: string
  size500: string
  size590: string
  size5Percent: string
  size10Percent: string
  size13Percent: string
  size20Percent: string
  size25Percent: string
  size50Percent: string
  size60Percent: string
  size100Percent: string
  size100vh: string
  size100vw: string
}

interface ZIndexInterface {
  firstLayer: number
  secondLayer: number
  thirdLayer: number
  fourthLayer: number
  fifthLayer: number
}

export const colors: ColorsInterface = {
  blue: '#204C74',
  backgroundBlue: '#EFF4F9',
  darkGray: '#567796',
  darkOrange: '#D96D00',
  disabledBlue: '#708CA6',
  focusBlue: '#319DFF',
  focusOrange: '#F6B529',
  gray: '#8BA2B7',
  green: '#0D777D',
  lightBlue: '#E2EDFC',
  lightGray: '#E1E8EF',
  lightGreen: '#D1EAE7',
  lightOrange: '#FFE6CD',
  lightRed: '#FFE2E2',
  mediumOrange: '#FFAE5B',
  orange: '#F69029',
  red: '#D82E29',
  opacity30Blue: '#204C744D',
  opacity40Blue: '#204C7466',
  white: '#FFFFFF',
  textBlack: '#292929',
}

export const fontSizes: FontSizesInterface = {
  fontSize10: '1rem',
  fontSize11: '1.1rem',
  fontSize12: '1.2rem',
  fontSize14: '1.4rem',
  fontSize15: '1.5rem',
  fontSize16: '1.6rem',
  fontSize18: '1.8rem',
  fontSize20: '2rem',
  fontSize24: '2.4rem',
  fontSize30: '3rem',
  fontSize35: '3.5rem',
  fontSize42: '4.2rem',
}

export const sizes: SizesInterface = {
  size0: '0rem',
  size1: '0.1rem',
  size2: '0.2rem',
  size3: '0.3rem',
  size4: '0.4rem',
  size5: '0.5rem',
  size6: '0.6rem',
  size8: '0.8rem',
  size10: '1rem',
  size12: '1.2rem',
  size14: '1.4rem',
  size15: '1.5rem',
  size16: '1.6rem',
  size18: '1.8rem',
  size20: '2rem',
  size24: '2.4rem',
  size25: '2.5rem',
  size28: '2.8rem',
  size32: '3.2rem',
  size38: '3.8rem',
  size48: '4.8rem',
  size50: '5rem',
  size56: '5.6rem',
  size64: '6.4rem',
  size72: '7.2rem',
  size240: '24rem',
  size300: '30rem',
  size380: '38rem',
  size500: '50rem',
  size590: '59rem',
  size5Percent: '5%',
  size10Percent: '10%',
  size13Percent: '13%',
  size20Percent: '20%',
  size25Percent: '25%',
  size50Percent: '50%',
  size60Percent: '60%',
  size100Percent: '100%',
  size100vh: '100vh',
  size100vw: '100vw',
}

export const zIndex: ZIndexInterface = {
  firstLayer: 9,
  secondLayer: 99,
  thirdLayer: 999,
  fourthLayer: 9999,
  fifthLayer: 99999,
}
