import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Form, Formik } from 'formik'
import * as yup from 'yup'

import { sizes } from '~/assets/styles/variables'

import { pageWidth } from '~/config/layout/pageWidth'
import setTitle from '~/config/title'

import { H4, H5 } from '~/components/atoms/Heading'
import PageContainer from '~/components/atoms/PageContainer'
import ActionBar from '~/components/molecules/ActionBar'
import DefaultError from '~/components/molecules/DefaultError'
import FormControl from '~/components/molecules/FormControl'
import ContactMethods from '~/components/organisms/ContactMethods'
import PortalTemplate from '~/components/templates/PortalTemplate'

import AuthenticationCodeSendingTypeEnum from '~/models/enums/AuthenticationCodeSendingTypeEnum'
import GoogleAnalyticsEventCategoryTypeEnum from '~/models/enums/GoogleAnalyticsEventCategoryTypeEnum'
import GoogleAnalyticsEventTypeEnum from '~/models/enums/GoogleAnalyticsEventTypeEnum'
import { RadioButtonValuesInterface } from '~/models/interfaces/components/FormControl'
import { GenerateAuthenticationCodeInputInterface } from '~/models/interfaces/services/Auth'

import { generateAuthenticationCode, getSendMethods } from '~/services/Auth'

import store from '~/store'
import { RootState } from '~/store/reducers'

import { useGoogleAnalytics } from '~/utils/useGoogleAnalytics'

import SendAccesCodeMethodRadioButtonContent from './SendAccesCodeMethodRadioButtonContent'
import AuthenticationFlowTypeEnum from '~/models/enums/AuthenticationFlowTypeEnum'

const { size32, size64 } = sizes

interface GenerateAccessCodeStateInterface {
  cpfCnpj: string
  flow?: AuthenticationFlowTypeEnum | string
  idEntryAutomation?: number
}

const GenerateAccessCode = () => {
  setTitle('Gerar código de acesso')

  const containerPageWidth = pageWidth.smaller
  const history = useHistory()
  const location = useLocation()
  const { regEventGa } = useGoogleAnalytics()
  const generateAccessCodeState = location.state as GenerateAccessCodeStateInterface

  const [requestHandlerSelector, campaignSelector] = useSelector(
    ({ requestHandler, campaign }: RootState) => [requestHandler, campaign] as const,
  )

  const initialValues: GenerateAuthenticationCodeInputInterface = {
    cpfCnpj: generateAccessCodeState.cpfCnpj,
    flow: generateAccessCodeState.flow,
    sendingType: '',
    idEntryAutomation: generateAccessCodeState.idEntryAutomation || undefined,
  }

  const validationSchema: yup.SchemaOf<GenerateAuthenticationCodeInputInterface> = yup.object({
    cpfCnpj: yup.string().required(''),
    sendingType: yup.string().required(''),
    flow: yup.mixed<AuthenticationFlowTypeEnum>(),
    idEntryAutomation: yup.number(),
    isResendToken: yup.boolean().notRequired(),
  })

  const [hasError,
    setHasError] = useState<boolean>(false)

  const [errorMessage,
    setErrorMessage] = useState<string>('')

  const [loadedData,
    setLoadedData] = useState<boolean>(false)

  const [hasMainCellPhone,
    setHasMainCellPhone] = useState<boolean>(false)

  const [hasEmail,
    setHasEmail] = useState<boolean>(false)

  const [sendingMethods,
    setSendingMethods] = useState<RadioButtonValuesInterface[]>([])

  const [idEntryAutomationState, setIdEntryAutomationState] = useState<number>()

  useEffect(() => {
    if (loadedData && requestHandlerSelector.error) {
      const { error } = requestHandlerSelector

      if (error.response && error.response.status === 400) {
        setErrorMessage(error.response.data.userMessage)
      } else {
        setErrorMessage(DefaultError.defaultProps.children)
      }
    }
  }, [loadedData, requestHandlerSelector])

  useEffect(() => {
    getSendMethods(generateAccessCodeState.cpfCnpj, campaignSelector.id)
      .then(({ data, idEntryAutomation }) => {
        const newSendingMethods = []
        const newHasMainCellPhone = +data.substring(0, 3) < 500
        const newHasEmail = +data.substring(3) < 500

        setHasMainCellPhone(newHasMainCellPhone)
        setHasEmail(newHasEmail)
        setIdEntryAutomationState(idEntryAutomation)

        if (newHasMainCellPhone) {
          newSendingMethods.push({
            id: AuthenticationCodeSendingTypeEnum.SMS,
            label: <SendAccesCodeMethodRadioButtonContent
              title="Receber por SMS"
              subtitle="Telefone cadastrado na VIACERTA"
            />,
          })
        }

        if (newHasEmail) {
          newSendingMethods.push({
            id: AuthenticationCodeSendingTypeEnum.Email,
            label: <SendAccesCodeMethodRadioButtonContent
              title="Receber por e-mail"
              subtitle="E-mail cadastrado na VIACERTA"
            />,
          })
        }

        setSendingMethods(newSendingMethods)
      })
      .catch(() => setHasError(true))
      .finally(() => setLoadedData(true))
    store.dispatch({
      type: 'SET_INITIAL_USER_DATA_PROCESSING',
    })
  }, [campaignSelector.id, generateAccessCodeState.cpfCnpj])

  const handleSubmit = (values: GenerateAuthenticationCodeInputInterface) => {
    store.dispatch({ type: 'LOGOUT' })

    const sendingTypeDesc = values.sendingType === AuthenticationCodeSendingTypeEnum.Email
      ? GoogleAnalyticsEventTypeEnum.AutenticacaoEmail
      : GoogleAnalyticsEventTypeEnum.AutenticacaoSms

    regEventGa(
      GoogleAnalyticsEventCategoryTypeEnum.Autenticacao,
      sendingTypeDesc,
    )

    values.idEntryAutomation = idEntryAutomationState

    generateAuthenticationCode(values)
      .then(() => {
        const state = {
          cpfCnpj: values.cpfCnpj,
          flow: values.flow,
          hasMainCellPhone,
          hasEmail,
        }

        store.dispatch({
          type: 'UPDATE_USER_DATA_PROCESSING',
          payload: { idEntryAutomation: idEntryAutomationState },
        })
        history.push('/autenticacao/confirmar-codigo-acesso', state)
      })
      .catch(() => setHasError(true))
  }

  return (
    <PortalTemplate wizard={false}>
      <PageContainer textAlign="center" width={containerPageWidth}>
        { loadedData && (
        <>
          <H4>
            Escolha o meio desejado para receber o código de autenticação:
          </H4>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            validateOnMount
          >
            {({
              handleChange, isValid, values,
            }) => (
              <Form>
                {hasError ? (
                  <DefaultError>
                    {errorMessage}
                  </DefaultError>
                ) : (
                  <FormControl
                    type="radio"
                    marginBottom={size64}
                    name="sendingType"
                    onChange={handleChange}
                    showError={false}
                    textAlign="left"
                    spanAlign
                    value={`${values.sendingType}`}
                    valuesRadio={sendingMethods}
                  />
                )}
                <H5 marginBottom={size32}>
                  Dúvidas? Entre em contato:
                </H5>

                <ContactMethods background="dark" columnSize={4} />

                <ActionBar
                  width={containerPageWidth}
                  submitActive={!hasError && isValid}
                />
              </Form>
            )}
          </Formik>
        </>
        )}
      </PageContainer>
    </PortalTemplate>
  )
}

export default GenerateAccessCode
