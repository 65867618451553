import React from 'react'
import { useSelector } from 'react-redux'
import { setConfiguration } from 'react-grid-system'
import { ThemeProvider } from 'styled-components'

import { BrowserRouter as Router } from 'react-router-dom'

import GlobalStyles from '~/assets/styles/globals'

import FormModalDialog from '~/components/molecules/FormModalDialog'
import ModalDialog from '~/components/molecules/ModalDialog'
import ErrorHandler from '~/components/organisms/ErrorHandler'

import Routes from '~/routes'

import { RootState } from './store/reducers'
import { useGoogleAnalytics } from './utils/useGoogleAnalytics'
import useWindowDimensions from '~/utils/useWindowDimensions'

const App = () => {
  const { initializeGa } = useGoogleAnalytics()
  const { sm } = useWindowDimensions()
  const theme = useSelector((state: RootState) => state.theme)

  initializeGa(process.env.REACT_APP_GA_MEASUREMENT_ID || '')

  setConfiguration({ gutterWidth: sm ? 32 : 16 })

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles {...theme} />
      <Router>
        <ErrorHandler />
        <FormModalDialog />
        <ModalDialog />
        <Routes />
      </Router>
    </ThemeProvider>
  )
}

export default App
