enum ContractTypeEnum {
  Boleto = 'B',
  Carne = 'CA',
  Contrato = 'CO',
  Chque = 'CH',
  Fatura = 'F',
  Titulo = 'T',
}

export default ContractTypeEnum
