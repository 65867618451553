import { combineReducers } from 'redux'

import { authReducer } from './auth/reducers'
import { campaignReducer } from './Campaign/reducers'
import { dischargeContractsReducer } from './DischargeContracts/reducers'
import { formModalDialogReducer } from './FormModalDialog/reducers'
import { modalDialogReducer } from './ModalDialog/reducers'
import { requestHandlerReducer } from './requestHandler/reducers'
import { themeReducer } from './Theme/reducers'
import { userDataProcessingReducer } from './UserDataProcessing/reducers'

const rootReducer = combineReducers({
  auth: authReducer,
  campaign: campaignReducer,
  dischargeContracts: dischargeContractsReducer,
  formModalDialog: formModalDialogReducer,
  modalDialog: modalDialogReducer,
  requestHandler: requestHandlerReducer,
  theme: themeReducer,
  userDataProcessing: userDataProcessingReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
