import { apiWithoutToken } from '~/config/api'
import { baseChargeIntegrations } from '~/config/basePath'

import {
  AuthInterface,
  ConfirmAuthenticationCodeInputInterface,
  CustomerSendAccessCodeMethodsInterface,
  GenerateAuthenticationCodeInputInterface,
} from '~/models/interfaces/services/Auth'

import store from '~/store'

const pathUrl = `${baseChargeIntegrations}/v1/negotiation-portal/authentication`

const getSendMethods = (cpfCnpj: string, campaignId: string): Promise<CustomerSendAccessCodeMethodsInterface> => apiWithoutToken
  .get<CustomerSendAccessCodeMethodsInterface>(`${pathUrl}/send?cpfCnpj=${cpfCnpj}&campaignId=${campaignId}`)
  .then((data) => data.data)

const generateAuthenticationCode = async (input: GenerateAuthenticationCodeInputInterface): Promise<any> => apiWithoutToken
  .post(`${pathUrl}/code-generation`, {
    ...input,
    isResendToken: input.isResendToken || false,
  })
  .then(({ data }) => data)

const confirmAuthenticationCode = async (input: ConfirmAuthenticationCodeInputInterface): Promise<AuthInterface> => apiWithoutToken
  .post<AuthInterface>(`${pathUrl}/code-confirmation`, input)
  .then(({ data }) => data)

const loginExternalApp = async (uuid: string): Promise<AuthInterface> => apiWithoutToken
  .post<AuthInterface>(`${pathUrl}/login-external?uuid=${uuid}`)
  .then(({ data }) => data)

const logout = () => {
  store.dispatch({ type: 'LOGOUT' })
  return Promise.resolve()
}

export {
  getSendMethods, generateAuthenticationCode,
  confirmAuthenticationCode, logout,
  loginExternalApp,
}
