import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from 'react-grid-system'
import { useLocation } from 'react-router-dom'
import { Form, Formik } from 'formik'
import * as yup from 'yup'

import { colors, sizes } from '~/assets/styles/variables'

import Label from '~/components/atoms/Label'
import Button from '~/components/atoms/Button'
import { P4 } from '~/components/atoms/Paragraph'
import { H5 } from '~/components/atoms/Heading'
import PageContainer from '~/components/atoms/PageContainer'
import Wizard from '~/components/molecules/Wizard'
import DefaultError from '~/components/molecules/DefaultError'
import FormControl from '~/components/molecules/FormControl'
import ContactMethods from '~/components/organisms/ContactMethods'
import PortalTemplate from '~/components/templates/PortalTemplate'

import { pageWidth } from '~/config/layout/pageWidth'
import setTitle from '~/config/title'

import GoogleAnalyticsEventCategoryTypeEnum from '~/models/enums/GoogleAnalyticsEventCategoryTypeEnum'
import GoogleAnalyticsEventTypeEnum from '~/models/enums/GoogleAnalyticsEventTypeEnum'
import InstallmentTypeEnum from '~/models/enums/InstallmentTypeEnum'
import SendEmailTypeEnum from '~/models/enums/SendEmailTypeEnum'
import NegotiationPortalFlowTypeEnum from '~/models/enums/NegotiationPortalFlowTypeEnum'
import ProposalTypeEnum from '~/models/enums/ProposalTypeEnum'
import ButtonTypeEnum from '~/models/enums/ButtonTypeEnum'

import { ProposalActivationInterface } from '~/models/interfaces/services/Proposal'
import SendBoletoEmailInterface, { InstallmentsSendBoletoEmailInterface } from '~/models/interfaces/services/SendBoletoEmail'

import { RootState } from '~/store/reducers'

import { getBoletosFile, getDataBoleto } from '~/services/Deals'
import { sendBoletoEmail } from '~/services/SendBoletoEmail'

import { useGoogleAnalytics } from '~/utils/useGoogleAnalytics'
import { useShowModalSendingMail } from '~/utils/useShowModalSendingMail'

import {
  BoletoGenerateButtonWrapper,
  CopyButtonWrapper,
  EmailFormControlWrapper,
  FakeInput,
  H4Styled,
  OrWrapper,
  SendBoletoEmailRow,
  SendEmailButtonWrapper,
} from './BoletoGeneration.styles'
import getWizard from '../utils/wizard'

import { CheckCircleIcon, CopyIcon } from '~/assets/icons'

const { gray, green } = colors
const {
  size0, size32, size38, size64,
} = sizes

interface StateInterface {
  deal: ProposalActivationInterface
}

interface SendBoletoDataInterface {
  email: string
}

const validationSchema: yup.SchemaOf<SendBoletoDataInterface> = yup.object({
  email: yup.string().email('').required(''),
})

const BoletoGeneration = () => {
  setTitle('Geração de boletos - Proposta')

  const { regEventGa } = useGoogleAnalytics()
  const location = useLocation()
  const wizardElements = getWizard(2)
  const state = location.state as StateInterface

  const authSelector = useSelector(
    ({ auth }: RootState) => auth,
  )

  const { user } = authSelector

  const userDataSelector = useSelector(
    ({ userDataProcessing }: RootState) => userDataProcessing,
  )

  const { idEntryAutomation } = userDataSelector

  const { mailError, mailSuccess } = useShowModalSendingMail()

  const [initialValues,
    setInitialValues] = useState<SendBoletoDataInterface>({ email: '' })

  const [digitableLine,
    setDigitableLine] = useState<string>('')

  const [isCopying,
    setIsCopying] = useState<boolean>(false)

  const [deal,
    setDeal] = useState<ProposalActivationInterface | null>(null)

  const containerPageWidth = pageWidth.medium

  const handleSendEmail = (values: SendBoletoDataInterface) => {
    if (!user || !deal) return

    regEventGa(
      GoogleAnalyticsEventCategoryTypeEnum.Proposta,
      GoogleAnalyticsEventTypeEnum.PropostaEnvioEmail,
    )

    const installmentsSendBoletoEmail: InstallmentsSendBoletoEmailInterface[] = [{
      dealId: deal.externalCode,
      installmentType: InstallmentTypeEnum.Deal,
    }]

    const stateSendBoletoEmail: SendBoletoEmailInterface = {
      customerId: user.id,
      installments: installmentsSendBoletoEmail,
      to: values.email,
    }

    const eventDescription = `E-mail: ${values.email}`
    const sendEmailTypeEnum: SendEmailTypeEnum = deal.type === ProposalTypeEnum.Deal
      ? SendEmailTypeEnum.BoletoPagamentoAvulso : SendEmailTypeEnum.BoletoRenegociacao

    const negotiationPortalFlowTypeEnum = deal.type === ProposalTypeEnum.Deal
      ? NegotiationPortalFlowTypeEnum.Quitacao : NegotiationPortalFlowTypeEnum.Renegociacao

    sendBoletoEmail(
      sendEmailTypeEnum,
      stateSendBoletoEmail,
      user.cpfCnpj,
      eventDescription,
      negotiationPortalFlowTypeEnum,
      idEntryAutomation || undefined,
    )
      .then(mailSuccess)
      .catch(mailError)
  }

  const generateBoleto = () => {
    if (!deal || !user) return

    regEventGa(
      GoogleAnalyticsEventCategoryTypeEnum.Proposta,
      GoogleAnalyticsEventTypeEnum.PropostaImprimirPdf,
    )

    const negotiationPortalFlowTypeEnum = deal.type === ProposalTypeEnum.Deal
      ? NegotiationPortalFlowTypeEnum.Quitacao : NegotiationPortalFlowTypeEnum.Renegociacao

    getBoletosFile(
      deal.externalCode,
      user.cpfCnpj,
      negotiationPortalFlowTypeEnum,
      idEntryAutomation || undefined,
    )
      .then((data) => {
        const file = new Blob([data], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(file)
        window.open(fileURL, '_blank')
      })
  }

  const copyDigitableLine = () => {
    regEventGa(
      GoogleAnalyticsEventCategoryTypeEnum.Proposta,
      GoogleAnalyticsEventTypeEnum.PropostaCopiarLinhaDigitavel,
    )

    navigator.clipboard.writeText(digitableLine)

    setTimeout(() => {
      setIsCopying(true)
    }, 250)

    setTimeout(() => {
      setIsCopying(false)
    }, 3500)
  }

  useEffect(() => {
    if (!user
      || !state
      || !state.deal
      || deal
    ) return

    setDeal(state.deal)
  }, [deal, state, user])

  useEffect(() => {
    if (!user) return

    setInitialValues({ email: user.email })
  }, [user])

  useEffect(() => {
    if (!deal || digitableLine) return

    getDataBoleto(deal.numberInstallment)
      .then((response) => {
        setDigitableLine(response.digitableLine)
      })
  }, [deal, digitableLine])

  return (
    <PortalTemplate actionBar={false}>

      <Wizard>{wizardElements}</Wizard>

      <PageContainer
        width={containerPageWidth}
        textAlign="center"
      >

        <Row justify="center">
          <Col md={10}>
            {deal ? (
              <>
                <H4Styled>
                  <CheckCircleIcon color={green} size={size38} />
                  Boleto de
                  {' '}
                  {deal.type === ProposalTypeEnum.Deal ? 'quitação' : 'renegociação'}
                  {' '}
                  gerado com sucesso!
                </H4Styled>

                {digitableLine && (
                  <>
                    <Label textAlign="left">
                      Copiar linha digitável do boleto
                      {deal.type === ProposalTypeEnum.Renegotiation && (' da entrada')}
                    </Label>

                    <FakeInput>
                      {digitableLine}

                      <CopyButtonWrapper className={isCopying ? 'is-copying' : ''}>
                        <Button
                          onClick={copyDigitableLine}
                          styledButton={ButtonTypeEnum.Icon}
                          title="Copiar"
                          type="button"
                        >
                          <CopyIcon />
                        </Button>

                        <CheckCircleIcon
                          className="success-icon"
                          color={green}
                        />
                      </CopyButtonWrapper>
                    </FakeInput>

                    <OrWrapper>ou</OrWrapper>
                  </>
                )}

                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  onSubmit={handleSendEmail}
                  validateOnMount
                  validationSchema={validationSchema}
                >
                  {({
                    handleChange, isValid, values,
                  }) => (
                    <Form autoComplete="off">
                      <SendBoletoEmailRow>
                        <EmailFormControlWrapper>
                          <FormControl
                            marginBottom={size0}
                            name="email"
                            onChange={handleChange}
                            placeholder="Digite seu e-mail"
                            showError={false}
                            title="Enviar boleto por e-mail"
                            type="email"
                            value={values.email}
                          />
                        </EmailFormControlWrapper>

                        <SendEmailButtonWrapper>
                          <Button
                            type="submit"
                            disabled={!isValid}
                            displayBlock
                            size="large"
                          >
                            Enviar e-mail
                          </Button>
                        </SendEmailButtonWrapper>
                      </SendBoletoEmailRow>
                    </Form>
                  )}
                </Formik>

                <OrWrapper>ou</OrWrapper>

                <BoletoGenerateButtonWrapper>
                  <Button type="button" displayBlock size="large" onClick={generateBoleto}>
                    Gerar boleto em PDF
                  </Button>
                </BoletoGenerateButtonWrapper>

                <P4
                  color={gray}
                  marginBottom={size64}
                >
                  Para visualizar os boletos protegidos por senha, é necessário informar os 4 primeiros dígitos de seu CPF.
                </P4>
              </>
            ) : (
              <DefaultError marginBottom={size32}>
                Nenhuma condição selecionada para geração de boletos.
              </DefaultError>
            )}

            <H5 marginBottom={size32}>
              Dúvidas? Entre em contato:
            </H5>

            <ContactMethods />
          </Col>
        </Row>
      </PageContainer>
    </PortalTemplate>
  )
}

export default BoletoGeneration
