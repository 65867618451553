import React from 'react'

import { sizes } from '~/assets/styles/variables'

import { useUniqueId } from '~/utils/useUniqueId'

import DealCard from '../DealCard'

import { ContractDataContainer, ItemsListContainer } from './FormModalDialogPendingItemsFields.styles'
import ListItem from './ListItem'

const { size0 } = sizes

const validationSchema = null

interface FormModalDialogContractItemsFieldsInterface {
  formData: any
}

const FormModalDialogPendingItemsFields = ({
  formData,
}: FormModalDialogContractItemsFieldsInterface) => {
  const { generateUniqueStringId } = useUniqueId()

  const contract = formData.values

  return (
    <>
      <ContractDataContainer>
        <DealCard contract={contract} marginBottom={size0} />
      </ContractDataContainer>

      <ItemsListContainer>
        {contract.originsByContractNumber.map((item: any) => (
          <ListItem
            item={item}
            key={generateUniqueStringId()}
          />
        ))}
      </ItemsListContainer>
    </>
  )
}

export { FormModalDialogPendingItemsFields, validationSchema }
