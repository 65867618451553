import React from 'react'
import { Hidden } from 'react-grid-system'
import { CreditCardIcon } from '~/assets/icons'

import { colors, sizes } from '~/assets/styles/variables'
import { H5Bold } from '~/components/atoms/Heading'

import { P2, P2Bold, P3 } from '~/components/atoms/Paragraph'

import ContractTypeEnum from '~/models/enums/ContractTypeEnum'
import { ContractDischargeInterface, ContractInstallmentDischargeInterface } from '~/models/interfaces/services/Contracts'

import { useFormats } from '~/utils/useFormats'
import { usePluralization } from '~/utils/usePluralization'

import {
  ContractType, DaysOfLateColumn, ExpirationColumn, ExpirationValueRow, ValueColumn,
} from './ContractCard.styles'

const { darkGray, orange } = colors

const {
  size0, size4, size10, size24,
} = sizes

interface ContractCardInterface {
  contract: ContractDischargeInterface
  isModal?: boolean
  marginBottom?: string
}

const defaultProps = {
  isModal: false,
  marginBottom: size24,
}

const ContractCard = ({
  contract,
  isModal,
  marginBottom,
}: ContractCardInterface) => {
  const { formatCurrency, formatDay } = useFormats()
  const { pluralize } = usePluralization()

  const firstInstallment: ContractInstallmentDischargeInterface = { ...contract.installments[0] }

  return (
    <>
      <P2Bold marginBottom={size4}>
        {contract.productName}
      </P2Bold>

      {contract.contractType === ContractTypeEnum.Fatura ? (
        <ContractType>
          <CreditCardIcon color={orange} margin={`${size0} ${size10} ${size0} ${size0}`} />
          <P2 color={darkGray} marginBottom={size0}>
            {`Fatura disponível de ${formatCurrency(firstInstallment.valueTotal)}`}
          </P2>
        </ContractType>
      ) : (
        <ContractType>
          <P2 color={darkGray} marginBottom={size0}>
            {`${contract.amountInstallments} ${pluralize(
              contract.amountInstallments,
              'parcela disponível',
              'parcelas disponíveis',
            )} de ${formatCurrency(firstInstallment.valueTotal)}`}
          </P2>
        </ContractType>
      )}

      <ExpirationValueRow marginBottom={marginBottom}>
        <ExpirationColumn>
          <P3 color={darkGray} marginBottom={size0}>
            Vencimento
          </P3>
          <H5Bold marginBottom={size0}>
            {`Dia ${formatDay(new Date(firstInstallment.dueDate))}`}
          </H5Bold>
        </ExpirationColumn>

        {contract.installments && contract.installments.length && (
        <>
          { isModal ? (
            <DaysOfLateColumn delayed={contract.installmentMoreDelayed > 0}>
              <H5Bold marginBottom={size0}>
                {
                  `${contract.installmentMoreDelayed} ${pluralize(
                    contract.installmentMoreDelayed,
                    'dia',
                  )}`.replace('-', '')
                }
              </H5Bold>
              <P3 marginBottom={size0}>
                {contract.installmentMoreDelayed > 0 ? (
                  <>
                    Parcela mais atrasada
                    <Hidden xs>
                      {' '}
                      para quitar
                    </Hidden>
                  </>
                ) : (
                  <>
                    Próximo vencimento
                    <Hidden xs>
                      {' '}
                      para quitar
                    </Hidden>
                  </>
                )}
              </P3>
            </DaysOfLateColumn>
          ) : (
            <ValueColumn>
              <P3 color={darkGray} marginBottom={size0}>
                Valor original das parcelas
              </P3>
              <H5Bold marginBottom={size0}>
                {formatCurrency(firstInstallment.valueTotal)}
              </H5Bold>
            </ValueColumn>
          )}
        </>
        )}
      </ExpirationValueRow>
    </>
  )
}

ContractCard.defaultProps = defaultProps

export default ContractCard
