import styled, { css } from 'styled-components'
import { checkIsWhite } from '~/assets/styles/helpers'

import { colors, sizes } from '~/assets/styles/variables'

import { device } from '~/config/device'

const { blue, white } = colors

const {
  size12, size16, size24, size32,
} = sizes

interface CardInterface {
  height?: string
  marginBottom?: string
  negative?: boolean
}

export const Card = styled.div<CardInterface>`
  ${({ negative, theme: { palette } }) => css`
    background-color: ${() => {
    if (negative) return white
    return checkIsWhite(palette.primary.main) ? blue : palette.primary.main
  }};
    color: ${negative ? palette.text.primary : white};
  `}
  border-radius: ${size12};
  display: flex;
  flex-direction: column;
  height: ${({ height }) => height || 'auto'};
  margin-bottom: ${({ marginBottom }) => marginBottom || size32};
`

export const CardBody = styled.div`
  flex: 1 1 auto;
  padding: ${size16};

  @media ${device.md} {
    padding: ${size24};
  }
`

export const CardFooter = styled.div`
  padding: 0 ${size16} ${size16};

  @media ${device.md} {
    padding: 0 ${size24} ${size24};
  }
`
