import { createGlobalStyle } from 'styled-components'
import { lighten } from 'polished'

import { scrollbar } from '~/components/atoms/Scrollbar'

import {
  successMessage,
  successMessageProgress,
  errorMessage,
  errorMessageProgress,
  alertMessage,
  alertMessageProgress,
} from '~/components/atoms/Toast/Toast.styles'

import { device } from '~/config/device'

import { checkIsWhite } from './helpers'
import { Theme } from '~/store/Theme/types'
import { colors, fontSizes, sizes } from './variables'

import 'react-toastify/dist/ReactToastify.min.css'

const {
  blue, darkGray, gray, white,
} = colors

const { fontSize16 } = fontSizes

const {
  size5, size8, size14, size100Percent,
} = sizes

const GlobalStyle = createGlobalStyle<Theme>`

* {
  padding: 0;
  margin: 0;
  outline: none;
  box-sizing: border-box;
}

html {
  font-size: 10px;
  scroll-behavior: smooth;

  @media ${device.xxs} {
    font-size: 8.5px;
  }
}

body {
  background-color: ${({ palette }) => palette.background.default};
  color: ${({ palette }) => (checkIsWhite(palette.primary.main) ? blue : palette.primary.main)};
  cursor: default;
  font-family: 'Open Sans', sans-serif;
  font-size: ${fontSize16};
  font-style: normal;
  font-weight: 400;

  ${scrollbar};
}

#root {
  overflow: hidden;
}

form {
  width: ${size100Percent};
}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.25s color;

  &:focus,
  &:hover {
    color: ${({ palette }) => lighten(0.15, palette.primary.main)};
  }
}

.Toastify__toast--success {
  ${successMessage}
}

.Toastify__progress-bar--success {
  ${successMessageProgress}
}

.Toastify__toast--error {
  ${errorMessage}
}

.Toastify__progress-bar--error {
  ${errorMessageProgress}
}

.Toastify__toast--alert {
  ${alertMessage}
}

.Toastify__progress-bar--alert {
  ${alertMessageProgress}
}

.react-datepicker {
  color: ${darkGray} !important;
  border-color: ${gray} !important;
  box-shadow: ${size8} ${size5} ${size14} rgba(0, 0, 0, 0.2);
}

.react-datepicker__triangle, .react-datepicker__triangle::before {
  border-bottom-color: ${blue} !important;
}

.react-datepicker__header {
  background-color: ${blue} !important;

  div {
    color:${white} !important;
  }
}

.react-datepicker__navigation--previous {
  border-right-color: ${white} !important;
}

.react-datepicker__navigation--next{
  border-left-color: ${white} !important;
}

.react-datepicker__day:hover {
  background-color: ${darkGray} !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: ${size100Percent};
}

.react-datepicker__current-month {
  text-transform: capitalize;
}

.react-datepicker__day--selected, .react-datepicker__day--keyboard-selected {
  background-color: ${blue} !important;
}

.no_margin {
  margin: 0 !important;
}

.report-style-class {
  height: 800px !important;
}
`

export default GlobalStyle
