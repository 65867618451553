import React from 'react'
import { Hidden } from 'react-grid-system'

import ButtonTypeEnum from '~/models/enums/ButtonTypeEnum'
import NegotiationTypeEnum from '~/models/enums/NegotiationTypeEnum'
import { DealPendingsInterface } from '~/models/interfaces/services/Deals'

import store from '~/store'
import useWindowDimensions from '~/utils/useWindowDimensions'

import Button from '~/components/atoms/Button'
import { CopyButton } from '~/components/atoms/CopyButton'
import { Flex } from '~/components/atoms/Flex'
import { P2 } from '~/components/atoms/Paragraph'
import { FormModalDialogPendingInstallmentsFields } from '../FormModalDialogPendingInstallmentsFields'
import { FormModalDialogPendingItemsFields } from '../FormModalDialogPendingItemsFields'
import { FormModalViewPix } from '../FormModalViewPix'

import { colors, sizes } from '~/assets/styles/variables'
import { ContractActionsRow, ContractButtonsColumn } from '../Contracts.styles'
import { PixModalFooter } from './styles'

export type CardActionProps = {
  pendings: DealPendingsInterface[]
  setPendings: React.Dispatch<React.SetStateAction<DealPendingsInterface[]>>
  deal: DealPendingsInterface
}

const { darkGray, focusBlue } = colors
const { size0, size8, size16 } = sizes

export const CardActions = (props: CardActionProps) => {
  const { deal, pendings, setPendings } = props

  const { md } = useWindowDimensions()

  const handleRefreshPendingContractsState = () => {
    const freshContracts: DealPendingsInterface[] = [...pendings]
    setPendings(freshContracts)
  }

  const handleSelectPendingInstallments = (formValues: DealPendingsInterface) => {
    const selectedContract = formValues

    const selectedContracts: DealPendingsInterface[] = [...pendings]

    selectedContracts.forEach((contract) => {
      if (selectedContract.externalCode === contract.externalCode) {
        contract.installments = selectedContract.installments
      }
    })

    setPendings(selectedContracts)
  }

  const showModalDealPendingInstallments = (modalTitle: string) => {
    store.dispatch({
      type: 'SET_FORM_MODAL_DIALOG_INFO',
      payload: {
        FormFields: FormModalDialogPendingInstallmentsFields,
        initialValues: deal,
        handleCancel: handleRefreshPendingContractsState,
        handleConfirm: (formValues: any) => handleSelectPendingInstallments(formValues),
        title: modalTitle,
        validationSchema: null,
      },
    })
    store.dispatch({ type: 'SET_VISIBLE_FORM_MODAL_DIALOG' })
  }

  const showModalPendingItems = (modalTitle: string) => {
    store.dispatch({
      type: 'SET_FORM_MODAL_DIALOG_INFO',
      payload: {
        FormFields: FormModalDialogPendingItemsFields,
        initialValues: deal,
        handleCancel: null,
        handleConfirm: null,
        title: modalTitle,
        validationSchema: null,
      },
    })
    store.dispatch({ type: 'SET_VISIBLE_FORM_MODAL_DIALOG' })
  }

  const showPixModal = () => {
    const pix = deal.installments?.[0].pixs?.[0]
    store.dispatch({
      type: 'SET_FORM_MODAL_DIALOG_INFO',
      payload: {
        FormFields: FormModalViewPix,
        initialValues: pix,
        title: 'DADOS DO PIX',
        validationSchema: null,
        footer: (
          <PixModalFooter>
            <Button
              onClick={() => {
                store.dispatch({ type: 'SET_INVISIBLE_FORM_MODAL_DIALOG' })

                setTimeout(() => {
                  store.dispatch({ type: 'SET_INVISIBLE_FORM_MODAL_DIALOG_FORMIK' })
                }, 200)
              }}
              styledButton={ButtonTypeEnum.Outline}
            >
              FECHAR
            </Button>

            <CopyButton value={pix.qrCodeData}>COPIAR PIX</CopyButton>
          </PixModalFooter>
        ),
      },
    })
    store.dispatch({ type: 'SET_VISIBLE_FORM_MODAL_DIALOG' })
  }

  if (deal.type === NegotiationTypeEnum.PagamentoAvulso) {
    return (
      <Flex justifyContent="flex-end" gap={size16}>
        <Button
          size="small"
          onClick={() => showModalPendingItems('Parcelas Negociadas')}
          styledButton={ButtonTypeEnum.Outline}
        >
          <Hidden xs>Ver </Hidden>
          originais
        </Button>

        <Button onClick={() => showPixModal()} size="small">
          Visualizar Pix
        </Button>
      </Flex>
    )
  }
  if (deal.type === NegotiationTypeEnum.Renegociacao) {
    return (
      <ContractActionsRow>
        <>
          {deal.installments.filter((i) => i.selected).length ? (
            <P2 color={focusBlue} marginBottom={md ? size0 : size8}>
              Renegociação selecionada
            </P2>
          ) : (
            <P2 color={darkGray} marginBottom={md ? size0 : size8}>
              Renegociação não selecionada
            </P2>
          )}
        </>

        <ContractButtonsColumn>
          <Button
            size="small"
            onClick={() => showModalPendingItems('Parcelas Negociadas')}
            styledButton={ButtonTypeEnum.Outline}
          >
            <Hidden xs>Ver </Hidden>
            originais
          </Button>

          <Button
            disabled={!deal}
            onClick={() => showModalDealPendingInstallments('Selecionar Renegociação')}
            size="small"
          >
            Selecionar
            <Hidden xs> renegociação</Hidden>
          </Button>
        </ContractButtonsColumn>
      </ContractActionsRow>
    )
  }

  return (
    <ContractActionsRow>
      <>
        {deal.installments.filter((i) => i.selected).length ? (
          <P2 color={focusBlue} marginBottom={md ? size0 : size8}>
            Quitação selecionada
          </P2>
        ) : (
          <P2 color={darkGray} marginBottom={md ? size0 : size8}>
            Quitação não selecionada
          </P2>
        )}
      </>

      <ContractButtonsColumn>
        <Button
          size="small"
          onClick={() => showModalPendingItems('Parcelas Negociadas')}
          styledButton={ButtonTypeEnum.Outline}
        >
          <Hidden xs>Ver </Hidden>
          originais
        </Button>

        <Button
          disabled={!deal}
          onClick={() => showModalDealPendingInstallments('Selecionar quitação')}
          size="small"
        >
          Selecionar
          <Hidden xs> Quitação</Hidden>
        </Button>
      </ContractButtonsColumn>
    </ContractActionsRow>
  )
}
