import React from 'react'

import { pageWidth } from '~/config/layout/pageWidth'
import { Container, Content } from './PageContainer.styles'

interface PageContainerInterface {
  children: React.ReactNode
  textAlign?: string,
  width?: number,
}

const defaultProps = {
  textAlign: 'left',
  width: pageWidth.medium,
}

function PageContainer({ children, textAlign, width }: PageContainerInterface) {
  return (
    <Container>
      <Content textAlign={textAlign} width={width}>
        {children}
      </Content>
    </Container>
  )
}

PageContainer.defaultProps = defaultProps

export default PageContainer
