import React from 'react'

import { sizes } from '~/assets/styles/variables'

import { ContractBoletoCopyInterface, ContractItemInterface } from '~/models/interfaces/services/Contracts'

import { useUniqueId } from '~/utils/useUniqueId'

import ContractCard from '../ContractCard'

import { ContractDataContainer, ItemsListContainer } from './FormModalDialogContractItemsFields.styles'
import ListItem from './ListItem'

const { size0 } = sizes

const validationSchema = null

interface FormModalDialogContractItemsFieldsInterface {
  formData: any
}

const FormModalDialogContractItemsFields = ({
  formData,
}: FormModalDialogContractItemsFieldsInterface) => {
  const { generateUniqueStringId } = useUniqueId()

  const contract = formData.values.contract as ContractBoletoCopyInterface

  const contractItems = formData.values.contractItems as ContractItemInterface[]

  return (
    <>
      {contract && (
        <>
          <ContractDataContainer>
            <ContractCard contract={contract} marginBottom={size0} />
          </ContractDataContainer>

          <ItemsListContainer>
            {contractItems.map((item) => (
              <ListItem
                item={item}
                key={generateUniqueStringId()}
              />
            ))}
          </ItemsListContainer>
        </>
      )}
    </>
  )
}

export { FormModalDialogContractItemsFields, validationSchema }
