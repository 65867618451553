import api from '~/config/api'
import { baseChargeIntegrations } from '~/config/basePath'

import {
  BoletoDataInterface,
  DealPendingsInterface,
  ProposalsAndPendingsInterface,
} from '~/models/interfaces/services/Deals'
import NegotiationPortalFlowTypeEnum from '~/models/enums/NegotiationPortalFlowTypeEnum'

const pathUrl = `${baseChargeIntegrations}/v1/negotiation-portal/deals`

const getDataBoleto = (installmentId: string): Promise<BoletoDataInterface> =>
  api.get<BoletoDataInterface>(`${pathUrl}/portion/${installmentId}`).then(({ data }) => data)

const getBoletosFile = (
  id: string,
  cpfCnpj: string,
  negotiationPortalFlowTypeEnum: NegotiationPortalFlowTypeEnum,
  idEntryAutomation?: number
): Promise<string> => {
  const params = { cpfCnpj, negotiationPortalFlowTypeEnum, idEntryAutomation }

  return api
    .get<string>(`${pathUrl}/${id}`, { params, responseType: 'arraybuffer' })
    .then(({ data }) => data)
}

const getDataPix = (customerId: string) =>
  api
    .get<DealPendingsInterface[]>(`${pathUrl}/pendings?customerId=${customerId}`)
    .then(({ data }) => data)

const getPendings = (customerId: string): Promise<DealPendingsInterface[]> => {
  const params = {
    customerId,
  }
  return api
    .get<DealPendingsInterface[]>(`${pathUrl}/pendings`, { params })
    .then(({ data }) => data)
}

const getProposalsAndPendings = (customerId: string): Promise<ProposalsAndPendingsInterface[]> => {
  const params = {
    customerId,
  }
  return api.get(`${pathUrl}/proposals-and-pendings`, { params }).then(({ data }) => data)
}

export { getDataBoleto, getBoletosFile, getDataPix, getPendings, getProposalsAndPendings }
