import { css, CSSProperties } from 'styled-components'

export type MarginProps = {
  margin?: CSSProperties['margin']
  marginBottom?: CSSProperties['marginBottom']
  marginBlock?: CSSProperties['marginBlock']
  marginInline?: CSSProperties['marginInline']
  marginLeft?: CSSProperties['marginLeft']
  marginRight?: CSSProperties['marginRight']
  marginTop?: CSSProperties['marginTop']
}

export type PaddingProps = {
  padding?: CSSProperties['padding']
  paddingBottom?: CSSProperties['paddingBottom']
  paddingLeft?: CSSProperties['paddingLeft']
  paddingRight?: CSSProperties['paddingRight']
  paddingTop?: CSSProperties['paddingTop']
}

export type StylingProps = MarginProps & PaddingProps

export const marginProps = css<StylingProps>`
  margin: ${({ margin }) => margin};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin-block: ${({ marginBlock }) => marginBlock};
  margin-inline: ${({ marginInline }) => marginInline};
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-right: ${({ marginRight }) => marginRight};
  margin-top: ${({ marginTop }) => marginTop};
`

export const paddingProps = css<StylingProps>`
  padding: ${({ padding }) => padding};
  padding-bottom: ${({ paddingBottom }) => paddingBottom};
  padding-left: ${({ paddingLeft }) => paddingLeft};
  padding-right: ${({ paddingRight }) => paddingRight};
  padding-top: ${({ paddingTop }) => paddingTop};
`

export const stylingProps = css<StylingProps>`
  ${marginProps}
  ${paddingProps}
`
