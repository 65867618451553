import api from '~/config/api'
import { baseChargeIntegrations } from '~/config/basePath'

const pathUrl = `${baseChargeIntegrations}/v1/negotiation-portal/pix`

const getQRCode = async (pixId: string) => {
  const file = (await api.get<string>(`${pathUrl}/${pixId}`, { responseType: 'arraybuffer' })).data
  return Buffer.from(file, 'binary').toString('base64')
}

export const pixService = {
  getQRCode,
}
