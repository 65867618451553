import React from 'react'

const initialState = {
  formikIsVisible: false,
  formModalDialogInfo: {
    FormFields: React.Fragment,
    handleCancel: null,
    handleConfirm: null,
    initialValues: {},
    title: null,
    validationSchema: {},
    footer: null,
  },
  isVisible: false,
}

export default initialState
