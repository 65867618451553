import React from 'react'

import { colors, sizes } from '~/assets/styles/variables'

import { P3, P4 } from '~/components/atoms/Paragraph'

import { PendingOrigingByContractNumber } from '~/models/interfaces/services/Deals'

import {
  ContractItem, DescriptionColumn,
} from './ListItem.styles'

const { darkGray } = colors
const { size0 } = sizes

interface ListItemInterface {
  item: PendingOrigingByContractNumber
}

const ListItem = ({ item }: ListItemInterface) => (
  <ContractItem>
    <div>
      <P4 color={darkGray}>
        Produto
      </P4>
      <P3 marginBottom={size0}>
        {String(item.product.description).padStart(3, '0')}
      </P3>
    </div>
    <DescriptionColumn>
      <P4 color={darkGray}>
        Parcelas
      </P4>
      <P3 marginBottom={size0}>
        {item.installmentNumber}
      </P3>
    </DescriptionColumn>
  </ContractItem>
)

export default ListItem
