import React from 'react'

import ModalDialogTypeEnum from '~/models/enums/ModalDialogTypeEnum'

const initialState = {
  isVisible: false,
  modalDialogInfo: {
    cancelButtonText: 'Cancelar',
    Children: React.Fragment,
    confirmButtonText: 'Confirmar',
    handleCancel: null,
    handleConfirm: null,
    title: null,
    type: ModalDialogTypeEnum.Warning,
  },
}

export default initialState
