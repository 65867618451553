import styled from 'styled-components'

import { colors, sizes, zIndex } from '~/assets/styles/variables'
import { H4 } from '~/components/atoms/Heading'

import { device } from '~/config/device'

const {
  size0, size1, size3, size16, size24, size32, size48,
} = sizes

const { gray, white } = colors
const { firstLayer, secondLayer } = zIndex

export const H4Styled = styled(H4)`
  align-content: center;
  display: flexbox;
  padding-left: ${size48};
  position: relative;
  text-align: left;

  svg {
    left: ${size0};
    position: absolute;
    margin-top: -${size3};

    @media ${device.sm} {
      margin-top: ${size1};
    }
  }
`

export const FakeInput = styled.div`
  background-color: ${white};
  border: 2px solid ${gray};
  border-radius: 0.8rem;
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: ${size32};
  padding: ${size16};
  padding-right: ${size48};
  position: relative;
  text-align: left;
  width: 100%;
  word-wrap: break-word;
`

export const CopyButtonWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  height: ${size24};

  button {
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.35s;
    z-index: ${secondLayer};
  }

  .success-icon {
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.35s;
    z-index: ${firstLayer}
  }

  &.is-copying {
    .success-icon {
      opacity: 1;
    }


    button {
      opacity: 0;
    }
  }
`

export const SendBoletoEmailRow = styled.div`
  margin-bottom: ${size32};

  @media ${device.sm} {
    align-items: flex-end;
    display: flex;
  }
`

export const EmailFormControlWrapper = styled.div`
  margin-bottom: ${size16};

  @media ${device.sm} {
    flex: 1;
    margin-bottom: ${size0};
  }
`

export const SendEmailButtonWrapper = styled.div`
  @media ${device.sm} {
    margin-left: ${size16};
  }
`

export const OrWrapper = styled.div`
  margin-bottom: ${size32};
`

export const BoletoGenerateButtonWrapper = styled.div`
  margin-bottom: ${size16};
`
