import styled from 'styled-components'

import { sizes } from '~/assets/styles/variables'

import { device } from '~/config/device'

const { size0, size16 } = sizes

export const ContractsWrapper = styled.div`
  > :last-child {
    margin-bottom: ${size0};
  }
`

export const ContractActionsRow = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  @media ${device.md} {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
`

export const ContractButtonsColumn = styled.div`
  display: flex;
  justify-content: space-between;

  > button + button {
    margin-left: ${size16};
  }
`
