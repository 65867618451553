import api from '~/config/api'
import { baseChargeIntegrations } from '~/config/basePath'

import {
  NegotiationInterface,
  RenegotiationEffectuationInputInterface,
  RenegotiationEffectuationOutputInterface,
  RenegotiationSimulationParamsInterface,
  RenegotiationSimulationOutputInterface,
} from '~/models/interfaces/services/Negotiations'

const pathUrl = `${baseChargeIntegrations}/v1/negotiation-portal/negotiations`

const getNegotiationsByCustomer = (
  customerId: string,
  cpfCnpj: string,
  idEntryAutomation?: number,
): Promise<NegotiationInterface[]> => {
  const params = { customerId, cpfCnpj, idEntryAutomation }

  return api
    .get<NegotiationInterface[]>(`${pathUrl}`, { params })
    .then(({ data }) => data)
}

const simulate = (
  params: RenegotiationSimulationParamsInterface,
): Promise<RenegotiationSimulationOutputInterface> => api
  .post<RenegotiationSimulationOutputInterface>(`${pathUrl}/simulation`, null, { params }).then(({ data }) => data)

const effectuate = (input: RenegotiationEffectuationInputInterface): Promise<RenegotiationEffectuationOutputInterface> => api
  .post<RenegotiationEffectuationOutputInterface>(`${pathUrl}/effectuation`, input)
  .then(({ data }) => data)

export { getNegotiationsByCustomer, simulate, effectuate }
