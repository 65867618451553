import styled from 'styled-components'

import { colors, sizes } from '~/assets/styles/variables'

const { white } = colors
const { size0, size16, size32 } = sizes

export const ContractDataContainer = styled.div`
  background-color: ${white};
  margin-bottom: ${size32};
  padding: ${size16};
`

export const ItemsListContainer = styled.div`
  margin-bottom: ${size32};
  padding: ${size0} ${size16};
`
