import styled, { css } from 'styled-components'

import { colors, fontSizes, sizes } from '~/assets/styles/variables'

import { device } from '~/config/device'

import Span from '../Span'

const {
  blue, gray, green, lightBlue, lightGray, lightGreen,
  lightOrange, lightRed, orange, red, white, textBlack,
} = colors

const { fontSize10, fontSize12, fontSize14 } = fontSizes

const {
  size0, size4, size12, size14, size16,
} = sizes

interface TagInterface {
  marginBottom?: string
  small?: boolean
  tagType?: 'alert' | 'disabled' | 'negative' | 'neutral' | 'positive' | 'recommended' | 'primary'
}

const colorType = css<TagInterface>`
  ${({ tagType, theme }) => {
    switch (tagType) {
      case 'alert':
        return css`
          background-color: ${lightOrange};
          color: ${orange};
        `
      case 'negative':
        return css`
          background-color: ${lightRed};
          color: ${red};
        `
      case 'disabled':
        return css`
          background-color: ${lightGray};
          color: ${gray};
        `
      case 'neutral':
        return css`
          background-color: ${lightBlue};
          color: ${blue};
        `
      case 'positive':
        return css`
          background-color: ${lightGreen};
          color: ${green};
        `
      case 'primary':
        return css`
          background-color: ${theme.palette.primary.main};
          color: ${white};
        `

      case 'recommended':
      default:
        return css`
          background-color: ${white};
          color: ${textBlack};
        `
    }
  }}
`

const Tag = styled(Span)<TagInterface>`
  border-radius: ${size14};
  color: ${white};
  display: inline-block;
  font-size: ${fontSize14};
  font-weight: 600;
  line-height: 1.42;
  margin-bottom: ${({ marginBottom }) => (marginBottom || size0)};
  padding: ${size4} ${size16};
  transition: background-color 0.25s, color 0.25s;

  ${({ small }) => small && css`
    font-size: ${fontSize12};
    padding: ${size4} ${size12};

    @media ${device.xs} {
      font-size: ${fontSize10};
    }
  `}

  ${colorType}
`

export default Tag
