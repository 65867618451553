import axios from 'axios'
import { CampaignInterface } from '~/models/interfaces/services/Campaign'

const get = async (slug: string) => {
  const makeURL = (env: 'prod' | 'dev') => `https://storage.googleapis.com/bucket-de-arquivos-${env}-open/recuperacao-credito/campanhas/${slug}.json`

  if (['local', 'dev'].includes(process.env.REACT_APP_ENV || '')) {
    const { data } = await axios.get<CampaignInterface>(makeURL('dev'))
    return data
  }

  const { data } = await axios.get<CampaignInterface>(makeURL('prod'))
  return data
}

export const CampaignService = {
  get,
}
