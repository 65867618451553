import styled from 'styled-components'

import { sizes } from '~/assets/styles/variables'

import { CardBody } from '~/components/atoms/Card'

import { device } from '~/config/device'

const {
  size16, size24, size32,
} = sizes

export const CardBodyStyled = styled(CardBody)`
  padding: ${size24} ${size16};

  @media ${device.md} {
    padding: ${size32} ${size24};
  }
`
