import styled from 'styled-components'

import { colors, sizes } from '~/assets/styles/variables'

import { device } from '~/config/device'

const { white } = colors
const {
  size8, size12, size14, size16, size38, size20Percent, size60Percent,
} = sizes

export const Item = styled.div`
  background-color: ${white};
  border: 2px solid ${white};
  border-radius: ${size8};
  display: flex;
  margin-bottom: ${size16};
  justify-content: space-between;
  padding: ${size14} ${size14} ${size12};
`

export const Column = styled.div`
  padding-right: ${size8};
  width: ${size20Percent};
  overflow: hidden;

  @media ${device.sm} {
    width: ${size60Percent};
  }

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

export const ColumnContract = styled.div`
  padding-right: ${size8};
  width: ${size20Percent};
  text-align: left;

  @media ${device.sm} {
    width: ${size60Percent};
    padding-left: ${size38};
  }

  p {
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
  }
`

export const ColumnProduct = styled.div`
  padding-right: ${size8};
  width: ${size20Percent};

  @media ${device.sm} {
    width: ${size60Percent};
  }

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`
