import { Col } from 'react-grid-system'
import styled from 'styled-components'

import { sizes } from '~/assets/styles/variables'

import { CardBody } from '~/components/atoms/Card'
import { H2 } from '~/components/atoms/Heading'

const {
  size12, size32, size100Percent,
} = sizes

export const H2Styled = styled(H2)`
  text-transform: capitalize;
`

export const ColStyled = styled(Col)`
  margin-bottom: ${size32};
`

export const CardBodyStyled = styled(CardBody)`
  display: flex;
  align-items: center;

  > div {
    width: ${size100Percent};
  }
`

export const TagWrapper = styled.div`
  margin-bottom: ${size32};
  text-align: center;
`

export const CardInfoWrapper = styled.div`
  align-items: center;
  display: flex;
`

export const IconWrapper = styled.div`
  margin-right: ${size12};
`
