import { Col } from 'react-grid-system'
import styled from 'styled-components'
import { sizes } from '~/assets/styles/variables'
import { CardBody } from '~/components/atoms/Card'
import { H2 } from '~/components/atoms/Heading'

import { device } from '~/config/device'

const { size0, size16 } = sizes

export const ColStyled = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

export const ParcelingLabel = styled.span`
  b {
    font-weight: 800;
  }
`

export const DiscountValueCardBodyStyled = styled(CardBody)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${device.md} {
    flex-direction: row;
    justify-content: center;
  }

  .original-value {
    text-decoration-line: line-through;
  }
`

export const H2Styled = styled(H2)`
  font-family: inherit;

  @media ${device.md} {
    margin-top: ${size0};
    margin-left: ${size16};
  }
`
