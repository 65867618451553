enum GoogleAnalyticsEventCategoryTypeEnum {
  Autenticacao = 'Autenticacao',
  MenuCliente = 'MenuCliente',
  SegundaViaBoleto = 'SegundaViaBoleto',
  Quitacao = 'Quitacao',
  Renegociacao = 'Renegociacao',
  FormasContato = 'FormasContato',
  Proposta = 'Proposta',
}

export default GoogleAnalyticsEventCategoryTypeEnum
