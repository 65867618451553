import React from 'react'

import { useFormats } from '~/utils/useFormats'
import { Input } from './CurrencyInput.styles'

interface InputMaskInterface {
  name: string
  onChange: (name: string, value: number | string) => void
  value: number | string
}

const CurrencyInput = ({
  name,
  onChange,
  value,
}: InputMaskInterface) => {
  const { formatCurrency, formatOnlyNumbers } = useFormats()

  const unMask = (v: string) => {
    const intValue = +formatOnlyNumbers(v)
    let strValue = `${intValue}`.padStart(3, '0')
    strValue = `${strValue.substr(0, strValue.length - 2)}.${strValue.substr(strValue.length - 2)}`
    return strValue
  }

  const masker = (v: number | string) => formatCurrency(+v).replace(' ', '')

  const handleChange = (event: any) => {
    const originalValue = unMask(event.target.value)
    onChange(event.target.name, originalValue)
  }

  const handleValue = masker(value)

  return (
    <Input
      name={name}
      maxLength={16}
      onChange={handleChange}
      value={handleValue}
      inputMode="numeric"
    />
  )
}

export default CurrencyInput
