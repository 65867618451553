import styled from 'styled-components'

import { colors, sizes, zIndex } from '~/assets/styles/variables'

const { backgroundBlue, opacity40Blue } = colors

const {
  size0, size12, size16, size32, size64, size380, size100Percent,
} = sizes

const { fourthLayer } = zIndex

interface ModalDialogInterface {
  hasHandleConfirm: boolean
}

export const Overlay = styled.div`
  align-items: center;
  background-color: ${opacity40Blue};
  display: flex;
  height: ${size100Percent};
  justify-content: center;
  left: ${size0};
  opacity: 0;
  padding: ${size16};
  position: fixed;
  top: ${size0};
  transition: visibility 0s 0.25s, opacity 0.25s;
  visibility: hidden;
  width: ${size100Percent};
  z-index: ${fourthLayer};

  &.show {
    opacity: 1;
    transition: opacity 0.25s;
    visibility: visible;

    .modal-content {
      opacity: 1;
      transform: scale(1);
    }
  }
`

export const Content = styled.div`
  background-color: ${backgroundBlue};
  border-radius: ${size12};
  max-width: ${size380};
  opacity: 0.5;
  overflow: hidden;
  padding: ${size32};
  position: relative;
  text-align: center;
  transform: scale(0.5);
  transition: transform 0.25s ease-in-out, opacity 0.25s;
  width: ${size100Percent};
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${size32};
`

export const IconWrapper = styled.div`
  margin-bottom: ${size32};
`

export const Footer = styled.div`
  justify-content: ${({ hasHandleConfirm }: ModalDialogInterface) => (hasHandleConfirm ? 'space-between' : 'center')};
  bottom: ${size0};
  display: flex;
  margin-top: ${size64};
  width: ${size100Percent};
`
