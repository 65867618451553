import React from 'react'
import { useSelector } from 'react-redux'

import { EmailIcon, SupportIcon, WhatsappIcon } from '~/assets/icons'
import { sizes } from '~/assets/styles/variables'

import { P4 } from '~/components/atoms/Paragraph'
import GoogleAnalyticsEventCategoryTypeEnum from '~/models/enums/GoogleAnalyticsEventCategoryTypeEnum'
import GoogleAnalyticsEventTypeEnum from '~/models/enums/GoogleAnalyticsEventTypeEnum'
import { RootState } from '~/store/reducers'

import { useFormats } from '~/utils/useFormats'
import { useGoogleAnalytics } from '~/utils/useGoogleAnalytics'
import useWindowDimensions from '~/utils/useWindowDimensions'

import {
  ColStyled,
  IconWrapper,
  Info,
  LinkContactMethod,
  RowStyled,
  Title,
} from './ContactMethods.styles'

const { size0 } = sizes

interface ContactMethodsInterface {
  background?: 'light' | 'dark'
  columnSize?: number
  marginBottom?: string
}

const defaultProps = {
  background: 'light',
  columnSize: 12,
  marginBottom: size0,
}

const ContactMethods = (props: ContactMethodsInterface) => {
  const { background, columnSize, marginBottom } = props
  const { regEventGa } = useGoogleAnalytics()
  const { lg } = useWindowDimensions()
  const { formatTelephone } = useFormats()

  const {
    ddd, email, dddWhatsapp, phoneWhatsapp, telephone, campaignType,
  } = useSelector(
    ({ campaign }: RootState) => campaign,
  )

  const isLightBackground = background === 'light'
  const iconSize = isLightBackground ? 32 : 24
  const iconBackground = campaignType ? 'primary' : 'orange'
  const phone = ddd && telephone && `${ddd}${telephone}`
  const whatsApp = dddWhatsapp && phoneWhatsapp ? `55${dddWhatsapp}${phoneWhatsapp}` : '5555996764030'
  const contactEmail = email || 'cobranca@viacertafinanciadora.com.br'

  const handleCLick = (gaEventType: GoogleAnalyticsEventTypeEnum) => {
    regEventGa(GoogleAnalyticsEventCategoryTypeEnum.FormasContato, gaEventType)
  }

  return (
    <RowStyled marginBottom={marginBottom}>
      <ColStyled lg={columnSize} sm={12}>
        <LinkContactMethod
          background={background}
          href={`https://${lg ? 'web' : 'api'}.whatsapp.com/send?phone=${whatsApp}`}
          onClick={() => handleCLick(GoogleAnalyticsEventTypeEnum.FormasContatoWhatsapp)}
          rel="noreferrer"
          target={lg ? '_blank' : '_self'}
          hoverColor={iconBackground}
        >
          <IconWrapper className="icon" background={iconBackground} size={iconSize}>
            <WhatsappIcon />
          </IconWrapper>
          <Info>
            <Title>WhatsApp</Title>
            {isLightBackground && <P4 marginBottom={size0}>Clique aqui para falar conosco!</P4>}
          </Info>
        </LinkContactMethod>
      </ColStyled>

      <ColStyled lg={columnSize} sm={12}>
        <LinkContactMethod
          background={background}
          href={`tel:${phone || '08006480048'}`}
          onClick={() => handleCLick(GoogleAnalyticsEventTypeEnum.FormasContatoTelefone)}
          hoverColor={iconBackground}
        >
          <IconWrapper className="icon" background={iconBackground} size={iconSize}>
            <SupportIcon />
          </IconWrapper>
          <Info>
            <Title>Telefone</Title>
            {isLightBackground && (
              <P4 marginBottom={size0}>{phone ? formatTelephone(phone) : '0800 648 0048'}</P4>
            )}
          </Info>
        </LinkContactMethod>
      </ColStyled>

      <ColStyled lg={columnSize} sm={12}>
        <LinkContactMethod
          background={background}
          href={`mailto:${contactEmail}`}
          onClick={() => handleCLick(GoogleAnalyticsEventTypeEnum.FormasContatoEmail)}
          hoverColor={iconBackground}
        >
          <IconWrapper className="icon" background={iconBackground} size={iconSize}>
            <EmailIcon />
          </IconWrapper>
          <Info>
            <Title>E-mail</Title>
            {isLightBackground && (
              <P4 marginBottom={size0}>{contactEmail}</P4>
            )}
          </Info>
        </LinkContactMethod>
      </ColStyled>
    </RowStyled>
  )
}

ContactMethods.defaultProps = defaultProps

export default ContactMethods
