import styled, { css } from 'styled-components'

import { device } from '~/config/device'

import { fontSizes, sizes } from '~/assets/styles/variables'

const { fontSize14 } = fontSizes

const {
  size10, size14, size16, size20, size25, size32, size64, size500, size100Percent,
} = sizes

export const PageContainer = styled.div`
  display: grid;
  grid-template-areas: 'content' 'content' 'footer';
  grid-template-columns: minmax(0, 1fr);
  min-height: 100vh;

  @media ${device.md} {
    grid-template-areas: 'content footer';
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
`

export const PageContent = styled.div`
  grid-area: content;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: calc(73px + ${size64}) ${size16} ${size64};

  @media ${device.md} {
    padding-left: ${size32};
    padding-right: ${size32};
  }
`

export const ContentWrapper = styled.div`
  max-width: ${size500};
  width: ${size100Percent};

  & img {
    border-radius: ${size20};
    display: block;
    margin-inline: auto;
    max-width: 400px;
    object-fit: cover;
    width: ${size100Percent};
  }
`

export const Footer = styled.div`
  ${({ theme: { palette } }) => css`
    align-items: center;
    background: ${palette.gray[50]};
    color: ${palette.text.primary};
    display: flex;
    grid-area: footer;
    height: ${size100Percent};
    justify-content: center;
    padding: ${size64} ${size32};
    width: ${size100Percent};

    @media ${device.md} {
      padding: calc(73px + ${size64}) ${size32} ${size64};
    }
  `}
`

export const Questions = styled.div`
  font-size: ${fontSize14};
  font-weight: 400;
  margin-block: ${size32} ${size20};
`

export const StrongStyled = styled.strong`
  font-weight: 700;
`

export const DescriptionWrapper = styled.div`
  font-style: normal;
  font-weight: bold;

  h2 {
    font-size: ${size25};
  }
  
  p {
    font-size: ${fontSize14};
  }

  ul {
    margin-bottom: ${size20};
    padding-left: ${size10};
  }

  li {
    margin-bottom: ${size14};
    font-size: ${fontSize14};

    &::marker {
      content: '- ';
    }
  }
`
