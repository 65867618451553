import React, { useEffect, useState } from 'react'

import { sizes } from '~/assets/styles/variables'

import Button from '~/components/atoms/Button'
import Tag from '~/components/atoms/Tag'

import NegotiationTypeEnum from '~/models/enums/NegotiationTypeEnum'
import { DealPendingsInterface } from '~/models/interfaces/services/Deals'

import { usePluralization } from '~/utils/usePluralization'
import { useUniqueId } from '~/utils/useUniqueId'

import DealCard from '../DealCard'

import {
  ContractDataContainer,
  InstallmentListContainer,
  SelectAllButtonRow,
} from './FormModalDialogInstallmentsFields.styles'

import InstallmentListItem from './InstallmentListItem'

const { size0 } = sizes

const validationSchema = null

interface FormModalDialogInstallmentsFieldsInterface {
  formData: {
    values: DealPendingsInterface
  }
}

const FormModalDialogPendingInstallmentsFields = ({
  formData,
}: FormModalDialogInstallmentsFieldsInterface) => {
  const { pluralize } = usePluralization()
  const { generateUniqueStringId } = useUniqueId()

  const [contract,
    setContract] = useState(formData.values)

  const [amountSelectableInstallments,
    setAmountSelectableInstallments] = useState<number>(0)

  const [amountSelectedInstallments,
    setAmountSelectedInstallments] = useState<number>(0)

  const [allInstallmentsAreSelected,
    setAllInstallmentsAreSelected] = useState<boolean>(false)

  useEffect(() => {
    setAmountSelectedInstallments(contract.installments.filter((installment) => installment.selected).length)
    setAmountSelectableInstallments(contract.installments.filter((installment) => installment.selectable).length)
  }, [contract])

  useEffect(() => {
    setAllInstallmentsAreSelected(
      amountSelectableInstallments === amountSelectedInstallments,
    )
  }, [amountSelectableInstallments, amountSelectedInstallments])

  const handleToggleSelectAll = (): void => {
    if (contract) {
      const stateContract = { ...contract }

      if (stateContract.type === NegotiationTypeEnum.Renegociacao) {
        const installment = stateContract.installments[0]
        installment.selected = allInstallmentsAreSelected
      } else {
        stateContract.installments.forEach((installment) => {
          installment.selected = allInstallmentsAreSelected
          return installment
        })
      }
      setContract(stateContract)
    }

    (document.activeElement as any).blur()
  }

  const handleChangeCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>, id: string,
  ) => {
    const { checked } = event.target

    if (contract) {
      const isRenegotiation = contract.externalCode === id
      contract.installments.forEach((installment) => {
        if (installment.externalCode === id) {
          installment.selected = checked
        }
      })

      if (isRenegotiation) {
        contract.select = checked
        contract.installments[0].selected = checked
      }

      setContract({ ...contract })
    }
  }

  return (
    <>
      {contract && (
        <>
          <ContractDataContainer>
            <DealCard contract={contract} marginBottom={size0} />
          </ContractDataContainer>
          <SelectAllButtonRow>
            <Tag small tagType={amountSelectedInstallments ? 'recommended' : 'disabled'}>
              {
                `${amountSelectedInstallments} ${pluralize(amountSelectedInstallments, ' item selecionado', ' itens selecionados')}`
              }
            </Tag>
            <Button
              onClick={handleToggleSelectAll}
              size="small"
              type="button"
            >
              {!allInstallmentsAreSelected ? 'Limpar tudo' : 'Selecionar tudo'}
            </Button>
          </SelectAllButtonRow>

          <InstallmentListContainer>
            <InstallmentListItem
              handleCheck={handleChangeCheckbox}
              installment={contract}
              key={generateUniqueStringId()}
            />
          </InstallmentListContainer>
        </>
      )}
    </>
  )
}

export { FormModalDialogPendingInstallmentsFields, validationSchema }
