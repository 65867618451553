import styled from 'styled-components'

import { P3Bold } from '~/components/atoms/Paragraph'

import { colors, sizes } from '~/assets/styles/variables'
import { device } from '~/config/device'
import { CardBody } from '~/components/atoms/Card'

const { lightGray } = colors

const {
  size0, size8, size12, size16,
} = sizes

export const ContractsWrapper = styled.div`
  > :last-child {
    margin-bottom: ${size0};
  }
`

export const ContractActionsRow = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  @media ${device.md} {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
`

export const P3BoldStyled = styled(P3Bold)`
  text-transform: uppercase;
`

export const CardBodyStyled = styled(CardBody)`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

export const ValueColumn = styled.div`
  align-items: center;
  border: 1px solid ${lightGray};
  border-radius: ${size8};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${size12} ${size16} ${size12};
  text-align: center;
  width: 50%;

  @media ${device.sm} {
    width: 60%;
  }
`
