import api from '~/config/api'
import { baseChargeIntegrations } from '~/config/basePath'

import { ContractBoletoCopyInterface, ContractItemInterface } from '~/models/interfaces/services/Contracts'
import { PaginationInterface } from '~/models/interfaces/services/Pagination'

const pathUrl = `${baseChargeIntegrations}/v1/negotiation-portal/contracts`

const getContractsToBoletoCopy = (
  customerId: string,
  cpfCnpj: string,
  idEntryAutomation?: number,
): Promise<ContractBoletoCopyInterface[]> => {
  const params = { customerId, cpfCnpj, idEntryAutomation }

  return api
    .get<ContractBoletoCopyInterface[]>(`${pathUrl}/boleto-copy`, { params })
    .then(({ data }) => data)
}

const getContractItems = (id: string): Promise<PaginationInterface<ContractItemInterface[]>> => api
  .get<PaginationInterface<ContractItemInterface[]>>(`${pathUrl}/${id}/items?page=1&pageSize=2000`)
  .then(({ data }) => data)

const getBoletosFile = (
  id: string,
  installmentIds: string[],
  cpfCnpj: string,
  eventDescription: string,
  idEntryAutomation?: number,
): Promise<string> => {
  const params = { idEntryAutomation }

  return api
    .get<string>(
      `${pathUrl}/${id}/boleto.pdf?installmentIds=${installmentIds}&cpfCnpj=${cpfCnpj}&eventDescription=${eventDescription}`,
      { params, responseType: 'arraybuffer' },
    )
    .then(({ data }) => data)
}

export { getContractsToBoletoCopy, getContractItems, getBoletosFile }
