export const useUniqueId = () => {
  const generateUniqueStringId = () => (
    Number(String(Math.random()).slice(2))
    + Date.now()
    + Math.round(performance.now())
  ).toString(36)

  const generateUniqueNumberId = () => (
    Number(String(Math.random()).slice(2))
    + Date.now()
    + Math.round(performance.now())
  )

  return {
    generateUniqueStringId,
    generateUniqueNumberId,
  }
}
