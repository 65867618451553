import styled from 'styled-components'
import { lighten } from 'polished'

import { colors, fontSizes, sizes } from '~/assets/styles/variables'

const {
  darkGray, disabledBlue, gray, white,
} = colors

const { fontSize16 } = fontSizes

const {
  size8, size15, size16, size100Percent,
} = sizes

export const Input = styled.input`
  background-color: ${white};
  border: 2px solid ${gray};
  border-radius: ${size8};
  color: ${({ theme: { palette } }) => palette.primary.main};
  font-family: 'Open Sans', sans-serif;
  font-size: ${fontSize16};
  font-weight: 600;
  padding: ${size16};
  transition: border-color 0.25s;
  width: ${size100Percent};

  /* Chrome, Safari, Edge, Opera */
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &.select {
    padding: ${size15} ${size16};
  }

  /* Firefox */
  &[type=number] {
    -moz-appearance: textfield;
  }

  ::placeholder {
    color: ${darkGray};
  }

  :disabled {
    background: ${gray};
    color: ${disabledBlue};
  }

  :focus {
    border-color: ${({ theme: { palette } }) => lighten(0.4, palette.primary.main)};
  }
`
