import React from 'react'

import { P2 } from '~/components/atoms/Paragraph'

import ModalDialogTypeEnum from '~/models/enums/ModalDialogTypeEnum'

import store from '~/store'

export const useShowModalSendingMail = () => {
  const mailSuccess = () => {
    store.dispatch({
      type: 'SET_MODAL_DIALOG_INFO',
      payload: {
        cancelButtonText: 'Ok, fechar',
        handleCancel: null,
        handleConfirm: null,
        title: 'E-mail enviado com sucesso!',
        type: ModalDialogTypeEnum.MailSuccess,
      },
    })
    store.dispatch({ type: 'SET_VISIBLE_MODAL_DIALOG' })
  }

  const mailError = () => {
    store.dispatch({
      type: 'SET_MODAL_DIALOG_INFO',
      payload: {
        cancelButtonText: 'Ok, fechar',
        Children: () => (
          <P2>
            Por favor, revise o e-mail informado ou tente novamente mais tarde.
          </P2>
        ),
        handleCancel: null,
        handleConfirm: null,
        title: 'Ops! Algo deu errado!',
        type: ModalDialogTypeEnum.MailError,
      },
    })
    store.dispatch({ type: 'SET_VISIBLE_MODAL_DIALOG' })
  }

  return {
    mailSuccess,
    mailError,
  }
}
