import React from 'react'

import { colors, sizes } from '~/assets/styles/variables'

import Checkbox from '~/components/atoms/Checkbox'
import { P3, P4 } from '~/components/atoms/Paragraph'

import { ContractInstallmentDischargeInterface } from '~/models/interfaces/services/Contracts'

import { useFormats } from '~/utils/useFormats'

import { InstallmentItem, InstallmentNumberColumn, InstallmentValueColumn } from './InstallmentListItem.styles'

const { darkGray } = colors
const { size0 } = sizes

interface InstallmentListItemInterface {
  handleCheck: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void
  installment: ContractInstallmentDischargeInterface
}

const InstallmentListItem = (
  { handleCheck, installment }: InstallmentListItemInterface,
) => {
  const { formatCurrency, formatDateDefault } = useFormats()

  return (
    <InstallmentItem selected={installment.selected}>
      <InstallmentNumberColumn>
        <Checkbox
          id="true"
          name={installment.externalCode}
          value={`${!installment.selected ? 'false' : 'true'}`}
          onChange={(event) => handleCheck(event, installment.externalCode)}
          marginBottom={size0}
        />
        <div>
          <P4 color={darkGray}>
            Parcela
          </P4>
          <P3 marginBottom={size0}>
            {String(installment.installmentNumber).padStart(3, '0')}
          </P3>
        </div>
      </InstallmentNumberColumn>
      <div>
        <P4 color={darkGray}>
          Vencimento
        </P4>
        <P3 marginBottom={size0}>
          {formatDateDefault(new Date(installment.dueDate))}
        </P3>
      </div>
      <InstallmentValueColumn>
        <P4 color={darkGray}>
          Valor atual
        </P4>
        <P3 marginBottom={size0}>
          {formatCurrency(installment.valueCurrent)}
        </P3>
      </InstallmentValueColumn>
    </InstallmentItem>
  )
}

export default InstallmentListItem
