import styled from 'styled-components'
import Button from '../Button'

import ButtonInterface from '~/models/interfaces/components/Button'
import { colors } from '~/assets/styles/variables'

export type CopyButtonAtomProps = ButtonInterface & {
  copied: boolean
}

export const CopyButtonAtom = styled(Button)<CopyButtonAtomProps>`
  background-color: ${({ copied }) => (copied ? colors.green : undefined)};

  & span {
    display: flex;
  }
`
