import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from 'react-grid-system'
import { useHistory, useLocation } from 'react-router-dom'
import { Form, Formik } from 'formik'
import * as yup from 'yup'

import { colors, sizes } from '~/assets/styles/variables'
import { CheckCircleIcon, CopyIcon } from '~/assets/icons'

import Button from '~/components/atoms/Button'
import { Card, CardBody } from '~/components/atoms/Card'
import { H4 } from '~/components/atoms/Heading'
import Label from '~/components/atoms/Label'
import PageContainer from '~/components/atoms/PageContainer'
import { P2, P4 } from '~/components/atoms/Paragraph'
import FormControl from '~/components/molecules/FormControl'
import Wizard from '~/components/molecules/Wizard'
import PortalTemplate from '~/components/templates/PortalTemplate'

import { pageWidth } from '~/config/layout/pageWidth'
import setTitle from '~/config/title'

import ButtonTypeEnum from '~/models/enums/ButtonTypeEnum'
import GoogleAnalyticsEventCategoryTypeEnum from '~/models/enums/GoogleAnalyticsEventCategoryTypeEnum'
import GoogleAnalyticsEventTypeEnum from '~/models/enums/GoogleAnalyticsEventTypeEnum'
import InstallmentTypeEnum from '~/models/enums/InstallmentTypeEnum'
import NegotiationPortalFlowTypeEnum from '~/models/enums/NegotiationPortalFlowTypeEnum'
import SendEmailTypeEnum from '~/models/enums/SendEmailTypeEnum'
import { WizardInputInterface } from '~/models/interfaces/components/Wizard'

import SendBoletoEmailInterface,
{ InstallmentsSendBoletoEmailInterface } from '~/models/interfaces/services/SendBoletoEmail'

import { DealOutputInterface } from '~/models/interfaces/services/Customers'

import { getBoletosFile, getDataBoleto } from '~/services/Deals'
import { sendBoletoEmail } from '~/services/SendBoletoEmail'

import { RootState } from '~/store/reducers'

import { useGoogleAnalytics } from '~/utils/useGoogleAnalytics'
import { useShowModalSendingMail } from '~/utils/useShowModalSendingMail'

import {
  BoletoGenerateButtonWrapper,
  CopyButtonWrapper,
  EmailFormControlWrapper,
  FakeInput,
  H4Styled,
  OrWrapper,
  RedirectButtonsRow,
  SendBoletoEmailRow,
  SendEmailButtonWrapper,
} from './BoletoGeneration.styles'

const {
  size0, size16, size38, size64,
} = sizes

const { darkGray, gray, green } = colors

interface StateInterface {
  deal: DealOutputInterface
}

interface SendBoletoDataInterface {
  email: string
}

const validationSchema: yup.SchemaOf<SendBoletoDataInterface> = yup.object({
  email: yup.string().email('').required(''),
})

const BoletoGeneration = () => {
  setTitle('Geração de boletos - Renegociação')

  const wizardElements: WizardInputInterface[] = [
    {
      title: 'Negociações disponíveis',
    },
    {
      title: 'Revisão de contratos',
    },
    {
      title: 'Valor da entrada e datas de vencimento',
    },
    {
      title: 'Geração de boleto',
      active: true,
    },
  ]

  const { regEventGa } = useGoogleAnalytics()
  const history = useHistory()

  const containerPageWidth = pageWidth.medium

  const location = useLocation()
  const state = location.state as StateInterface

  const authSelector = useSelector(
    ({ auth }: RootState) => auth,
  )

  const { user } = authSelector

  const userDataSelector = useSelector(
    ({ userDataProcessing }: RootState) => userDataProcessing,
  )

  const { idEntryAutomation } = userDataSelector

  const { mailError, mailSuccess } = useShowModalSendingMail()

  const [deal,
    setDeal] = useState<DealOutputInterface | null>(null)

  const [digitableLine,
    setDigitableLine] = useState<string>('')

  const [isCopying,
    setIsCopying] = useState<boolean>(false)

  const [initialValues,
    setInitialValues] = useState<SendBoletoDataInterface>({ email: '' })

  useEffect(() => {
    if (!user) return

    setInitialValues({ email: user.email })
  }, [user])

  useEffect(() => {
    if (!user
      || !state
      || !state.deal
      || deal
    ) return

    setDeal(state.deal)
  }, [deal, state, user])

  useEffect(() => {
    if (!deal || digitableLine) return

    getDataBoleto(deal.installmentExternalCode)
      .then((response) => {
        setDigitableLine(response.digitableLine)
      })
  }, [deal, digitableLine])

  const handleSendEmail = (values: SendBoletoDataInterface) => {
    if (!user
      || !deal) return

    regEventGa(
      GoogleAnalyticsEventCategoryTypeEnum.Renegociacao,
      GoogleAnalyticsEventTypeEnum.RenegociacaoEnvioEmail,
    )

    const installmentsSendBoletoEmail: InstallmentsSendBoletoEmailInterface[] = [{
      dealId: deal.externalCode,
      installmentType: InstallmentTypeEnum.Deal,
    }]

    const stateSendBoletoEmail: SendBoletoEmailInterface = {
      customerId: user.id,
      installments: installmentsSendBoletoEmail,
      to: values.email,
    }

    const eventDescription = `E-mail: ${values.email}`

    sendBoletoEmail(
      SendEmailTypeEnum.BoletoRenegociacao,
      stateSendBoletoEmail,
      user.cpfCnpj,
      eventDescription,
      NegotiationPortalFlowTypeEnum.Renegociacao,
      idEntryAutomation || undefined,
    )
      .then(mailSuccess)
      .catch(mailError)
  }

  const generateBoleto = () => {
    if (!deal
      || !user) return

    regEventGa(
      GoogleAnalyticsEventCategoryTypeEnum.Renegociacao,
      GoogleAnalyticsEventTypeEnum.RenegociacaoImprimirPdf,
    )

    const negotiationPortalFlowTypeEnum = NegotiationPortalFlowTypeEnum.Renegociacao

    getBoletosFile(
      deal.externalCode,
      user.cpfCnpj,
      negotiationPortalFlowTypeEnum,
      idEntryAutomation || undefined,
    )
      .then((data) => {
        const file = new Blob([data], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(file)
        window.open(fileURL, '_blank')
      })
  }

  const copyDigitableLine = () => {
    regEventGa(
      GoogleAnalyticsEventCategoryTypeEnum.Renegociacao,
      GoogleAnalyticsEventTypeEnum.RenegociacaoCopiarLinhaDigitavel,
    )

    navigator.clipboard.writeText(digitableLine)

    setTimeout(() => {
      setIsCopying(true)
    }, 250)

    setTimeout(() => {
      setIsCopying(false)
    }, 3500)
  }

  const handleRedirect = (page: string) => {
    if (page === '/renegociacao') {
      regEventGa(
        GoogleAnalyticsEventCategoryTypeEnum.Renegociacao,
        GoogleAnalyticsEventTypeEnum.RenegociacaoFazerNovaNegociacao,
      )
    }

    if (page === '/menu-cliente') {
      regEventGa(
        GoogleAnalyticsEventCategoryTypeEnum.Renegociacao,
        GoogleAnalyticsEventTypeEnum.RenegociacaoEncerrarNegociacao,
      )
    }

    history.push(page)
  }

  return (
    <PortalTemplate actionBar={false}>
      <Wizard>{wizardElements}</Wizard>

      <PageContainer width={containerPageWidth} textAlign="center">
        <Row justify="center">
          <Col md={10}>
            { !!deal && !!digitableLine && (
              <>
                <H4Styled>
                  <CheckCircleIcon color={green} size={size38} />
                  Renegociação realizada com sucesso!
                </H4Styled>

                <Label textAlign="left">
                  Copiar linha digitável do boleto da entrada
                </Label>

                <FakeInput>
                  {digitableLine}

                  <CopyButtonWrapper className={isCopying ? 'is-copying' : ''}>
                    <Button
                      onClick={copyDigitableLine}
                      styledButton={ButtonTypeEnum.Icon}
                      title="Copiar"
                      type="button"
                    >
                      <CopyIcon />
                    </Button>

                    <CheckCircleIcon
                      className="success-icon"
                      color={green}
                    />
                  </CopyButtonWrapper>
                </FakeInput>

                <OrWrapper>ou</OrWrapper>

                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  onSubmit={handleSendEmail}
                  validateOnMount
                  validationSchema={validationSchema}
                >
                  {({
                    handleChange, isValid, values,
                  }) => (
                    <Form autoComplete="off">
                      <SendBoletoEmailRow>
                        <EmailFormControlWrapper>
                          <FormControl
                            marginBottom={size0}
                            name="email"
                            onChange={handleChange}
                            placeholder="Digite seu e-mail"
                            showError={false}
                            title="Enviar boletos por e-mail"
                            type="email"
                            value={values.email}
                          />
                        </EmailFormControlWrapper>

                        <SendEmailButtonWrapper>
                          <Button
                            type="submit"
                            disabled={!isValid}
                            displayBlock
                            size="large"
                          >
                            Enviar e-mail
                          </Button>
                        </SendEmailButtonWrapper>
                      </SendBoletoEmailRow>
                    </Form>
                  )}
                </Formik>

                <OrWrapper>ou</OrWrapper>

                <BoletoGenerateButtonWrapper>
                  <Button type="button" displayBlock size="large" onClick={generateBoleto}>
                    Gerar boleto em PDF
                  </Button>
                </BoletoGenerateButtonWrapper>

                <P4
                  color={gray}
                  marginBottom={size64}
                >
                  Para visualizar o boleto protegido por senha, é necessário informar os 4 primeiros dígitos de seu CPF.
                </P4>

                <Card marginBottom={size0} negative>
                  <CardBody>
                    <H4 marginBottom={size16}>
                      Fazer nova negociação?
                    </H4>

                    <P2 color={darkGray}>
                      Você ainda pode aproveitar os descontos especiais para renegociar outros contratos!
                    </P2>

                    <RedirectButtonsRow>
                      <Button
                        onClick={() => handleRedirect('/menu-cliente')}
                        size="small"
                        styledButton={ButtonTypeEnum.Outline}
                        type="button"
                      >
                        Não, encerrar negociação
                      </Button>

                      <Button
                        onClick={() => handleRedirect('/renegociacao')}
                        size="small"
                        type="button"
                      >
                        Sim, fazer nova negociação
                      </Button>
                    </RedirectButtonsRow>
                  </CardBody>
                </Card>
              </>
            )}
          </Col>
        </Row>
      </PageContainer>
    </PortalTemplate>
  )
}

export default BoletoGeneration
