import styled from 'styled-components'

import { sizes } from '~/assets/styles/variables'

import { H4 } from '~/components/atoms/Heading'

import { device } from '~/config/device'

const {
  size0, size1, size3, size48,
} = sizes

export const H4Styled = styled(H4)`
  align-content: center;
  display: flexbox;
  padding-left: ${size48};
  position: relative;
  text-align: left;

  svg {
    left: ${size0};
    position: absolute;
    margin-top: -${size3};

    @media ${device.sm} {
      margin-top: ${size1};
    }
  }
`
