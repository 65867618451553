import React, { useCallback, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Form, Formik } from 'formik'
import * as yup from 'yup'

import { isValidCnpj } from '@brazilian-utils/is-valid-cnpj'
import { isValidCpf } from '@brazilian-utils/is-valid-cpf'

import Button from '~/components/atoms/Button'
import { H3 } from '~/components/atoms/Heading'

import FormControl from '~/components/molecules/FormControl'

import { useFormats } from '~/utils/useFormats'

import setTitle from '~/config/title'

import { sizes } from '~/assets/styles/variables'
import { AuthLoginInputInterface } from '~/models/interfaces/services/Auth'
import AuthenticationFlowTypeEnum from '~/models/enums/AuthenticationFlowTypeEnum'

const { size32 } = sizes

const Identification = () => {
  setTitle('Identificação')

  const { formatCPF, formatCNPJ } = useFormats()
  const history = useHistory()
  const location = useLocation()

  const initialValues = {
    cpfCnpj: '',
  }

  const replaceMask = (value: string) => value.replace(/\D/g, '')

  const validateCpfCnpj = (cpfCnpj: string) => (
    cpfCnpj.length > 11 ? isValidCnpj(cpfCnpj) : isValidCpf(cpfCnpj)
  )

  const validationSchema: yup.SchemaOf<AuthLoginInputInterface> = yup.object({
    cpfCnpj: yup.string()
      .required('É necessário informar um CPF ou CNPJ.')
      .test('cpfCnpj', 'É necessário informar um CPF ou CNPJ válido.', (value) => validateCpfCnpj(value || '')),
    flow: yup.mixed<AuthenticationFlowTypeEnum>(),
  })

  const handleSubmit = useCallback((values: AuthLoginInputInterface) => {
    const state = { cpfCnpj: values.cpfCnpj, flow: values.flow }
    history.push('/autenticacao', state)
  }, [history])

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)

    if (urlParams.get('id') && urlParams.get('flow') === AuthenticationFlowTypeEnum.Proposal) {
      handleSubmit({
        cpfCnpj: urlParams.get('id') || '',
        flow: AuthenticationFlowTypeEnum.Proposal,
      })
    }
  }, [handleSubmit, location])

  return (
    <>
      <H3 marginBottom={size32}>
        Atualize seu boleto com desconto ou negocie seus contratos!
      </H3>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        isValidating
        onSubmit={handleSubmit}
        validateOnMount
        validationSchema={validationSchema}
      >
        {({ handleChange, values, setFieldTouched }) => (
          <Form autoComplete="off">
            <FormControl
              autoFocus
              inputMode="numeric"
              maxLength={18}
              name="cpfCnpj"
              placeholder="Somente os números"
              title="Digite seu CPF ou CNPJ"
              type="text"
              value={values.cpfCnpj.length < 12 ? formatCPF(values.cpfCnpj) : formatCNPJ(values.cpfCnpj)}
              onChange={(event) => {
                handleChange('cpfCnpj')(replaceMask(event.target.value))
              }}
              onBlur={() => setFieldTouched('cpfCnpj', true)}
            />

            <Button
              disabled={!validateCpfCnpj(values.cpfCnpj)}
              displayBlock
              size="large"
              type="submit"
            >
              Entrar
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default Identification
