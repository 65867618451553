import { useCallback } from 'react'
import ReactGA from 'react-ga4'

export const useGoogleAnalytics = () => {
  const initializeGa = useCallback((measurementId: string) => {
    ReactGA.initialize(measurementId)
  }, [])

  const pageView = useCallback(() => {
    ReactGA.send('pageview')
  }, [])

  const regEventGa = useCallback((category: string, action: string, label: string | undefined = undefined) => {
    ReactGA.gtag(
      'event',
      action,
      {
        event_label: label,
        event_category: category,
        non_interaction: false,
        page_location: window.location.pathname + window.location.search,
      },
    )
  }, [])

  return {
    initializeGa,
    pageView,
    regEventGa,
  }
}
