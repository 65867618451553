import styled from 'styled-components'

import { colors, sizes } from '~/assets/styles/variables'
import { breakpoint, device } from '~/config/device'

const { size16 } = sizes

export const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  transition: 0.5s;

  @media (max-width: ${breakpoint.md}px) {
    flex-direction: column;
    align-items: center;
  }
`

export const QrCodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 21.25px 0 0 0;
  border-radius: 12px;
  transition: 0.5s;

  .copyQrCodeData {
    line-break: anywhere;
  }

  @media ${device.sm} {
    width: 407px;

    .copyQrCodeData {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`

export const InstructionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  max-width: 359px;
  background-color: ${colors.white};
  border-radius: 12px;
`

export const Ol = styled.ol`
  margin: 0;
  padding: 0px 0px 0px 22px;

  li {
    color: ${colors.textBlack};
    margin: 16px 0 0 0;
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: -2rem 0 16.5px 0;

  svg {
    min-width: 33px;
  }

  p {
    font-size: 29.03px;
  }

  @media (max-width: ${breakpoint.md}px) {
    text-align: left;

    p {
      font-size: 24px;
    }
  }
`

export const RedirectButtonsRow = styled.div`
  width: 100%;
  display: flex;
  gap: ${size16};
  justify-content: space-between;

  @media ${device.xs} {
    flex-direction: column;
  }
`
