import React, { useEffect, useState } from 'react'

import { sizes } from '~/assets/styles/variables'

import Button from '~/components/atoms/Button'
import Tag from '~/components/atoms/Tag'

import { ContractDischargeInterface } from '~/models/interfaces/services/Contracts'

import { usePluralization } from '~/utils/usePluralization'
import { useUniqueId } from '~/utils/useUniqueId'

import ContractCard from '../ContractCard'

import {
  ContractDataContainer,
  InstallmentListContainer,
  SelectAllButtonRow,
} from './FormModalDialogInstallmentsFields.styles'

import InstallmentListItem from './InstallmentListItem'

const { size0 } = sizes

const validationSchema = null

interface FormModalDialogInstallmentsFieldsInterface {
  formData: any
}

const FormModalDialogInstallmentsFields = ({
  formData,
}: FormModalDialogInstallmentsFieldsInterface) => {
  const { pluralize } = usePluralization()
  const { generateUniqueStringId } = useUniqueId()

  const [contract,
    setContract] = useState<ContractDischargeInterface>(formData.values)

  const [amountInstallments,
    setAmountInstallments] = useState<number>(0)

  const [amountSelectedInstallments,
    setAmountSelectedInstallments] = useState<number>(0)

  const [allInstallmentsAreSelected,
    setAllInstallmentsAreSelected] = useState<boolean>(false)

  useEffect(() => {
    setAmountInstallments(contract.installments.length)
    setAmountSelectedInstallments(contract.installments.filter((i) => i.selected).length)
  }, [contract])

  useEffect(() => {
    setAllInstallmentsAreSelected(
      amountInstallments === amountSelectedInstallments,
    )
  }, [amountInstallments, amountSelectedInstallments])

  const handleToggleSelectAll = () => {
    if (contract) {
      const stateContract = { ...contract }

      stateContract.installments.forEach((installment) => {
        installment.selected = !allInstallmentsAreSelected
        return installment
      })

      setContract(stateContract)
    }

    (document.activeElement as any).blur()
  }

  const handleChangeCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string,
  ) => {
    const { checked } = e.target
    if (contract) {
      contract.installments.forEach((installment) => {
        if (installment.externalCode === id) {
          installment.selected = checked
        }
      })

      setContract({ ...contract })
    }
  }

  return (
    <>
      {contract && (
        <>
          <ContractDataContainer>
            <ContractCard contract={contract} isModal marginBottom={size0} />
          </ContractDataContainer>
          <SelectAllButtonRow>
            <Tag small tagType={amountSelectedInstallments ? 'recommended' : 'disabled'}>
              {
                `${amountSelectedInstallments} ${pluralize(amountSelectedInstallments, ' item selecionado', ' itens selecionados')}`
              }
            </Tag>
            <Button
              onClick={handleToggleSelectAll}
              size="small"
              type="button"
            >
              {allInstallmentsAreSelected ? 'Limpar tudo' : 'Selecionar tudo'}
            </Button>
          </SelectAllButtonRow>

          <InstallmentListContainer>
            {contract.installments.map((installment) => (
              <InstallmentListItem
                handleCheck={handleChangeCheckbox}
                installment={installment}
                key={generateUniqueStringId()}
              />
            ))}
          </InstallmentListContainer>
        </>
      )}
    </>
  )
}

export { FormModalDialogInstallmentsFields, validationSchema }
