import styled from 'styled-components'

import {
  colors, fontSizes, sizes, zIndex,
} from '~/assets/styles/variables'

import { scrollbar } from '~/components/atoms/Scrollbar'

import { device } from '~/config/device'

const {
  backgroundBlue, focusBlue, opacity30Blue, opacity40Blue, white,
} = colors

const { fontSize14 } = fontSizes

const {
  size0, size4, size12, size16, size24, size32, size56, size72, size590,
  size50Percent, size100Percent, size100vh,
} = sizes

const { firstLayer, secondLayer, thirdLayer } = zIndex

interface FooterInterface {
  hasHandleConfirm: boolean
}

export const Overlay = styled.div`
  align-items: center;
  background-color: ${opacity40Blue};
  display: flex;
  height: ${size100Percent};
  justify-content: center;
  left: ${size0};
  opacity: 0;
  position: fixed;
  top: ${size0};
  transition: visibility 0s 0.25s, opacity 0.25s;
  visibility: hidden;
  width: ${size100Percent};
  z-index: ${thirdLayer};

  @media ${device.sm} {
    padding: ${size0} ${size24};
  }

  &.show {
    opacity: 1;
    transition: opacity 0.25s;
    visibility: visible;

    .modal-content {
      opacity: 1;
      transform: scale(1);
    }
  }
`

export const Content = styled.div`
  background-color: ${backgroundBlue};
  border-radius: ${size0};
  height: 100vh;
  opacity: 0.5;
  overflow: hidden;
  padding-bottom: 80px;
  position: relative;
  transform: scale(0.5);
  transition: transform 0.25s ease-in-out, opacity 0.25s;
  width: ${size100Percent};

  @media ${device.sm} {
    border-radius: ${size12};
    height: auto;
    width: ${size590};
  }
`

export const Header = styled.div`
  background-color: ${white};
  box-shadow: ${size0} ${size4} ${size16} ${opacity30Blue};
  font-family: Raleway, sans-serif;
  font-size: ${fontSize14};
  font-weight: 800;
  line-height: 20px;
  overflow: hidden;
  padding: ${size16} ${size56} ${size16} ${size16};
  position: relative;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
  z-index: ${secondLayer};

  @media ${device.sm} {
    padding: ${size16} ${size56};
    text-align: center;
  }
`

export const CloseButton = styled.button`
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  padding: ${size0};
  position: absolute;
  right: ${size16};
  top: 50%;
  transform: translateY(${`-${size50Percent}`});
  transition: color 0.25s;

  &:focus,
  &:hover {
    color: ${focusBlue}
  }

  svg {
    display: block;
  }
`

export const Body = styled.div`
  max-height: calc(${size100vh} - 52px - 80px);
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  z-index: ${firstLayer};

  ${scrollbar};

  @media ${device.sm} {
    max-height: calc(${size100vh} - 52px - 80px - ${size72});
  }
`

export const Footer = styled.div`
  justify-content: ${({ hasHandleConfirm }: FooterInterface) => (hasHandleConfirm ? 'space-between' : 'center')};
  background-color: ${white};
  bottom: ${size0};
  box-shadow: ${size0} -${size4} ${size32} ${opacity30Blue};
  display: flex;
  padding: ${size16};
  position: absolute;
  width: ${size100Percent};
  z-index: ${secondLayer};
`
