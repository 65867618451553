import React from 'react'
import { ErrorMessage } from 'formik'

import FormInput from '~/components/atoms/FormInput'
import Hint from '~/components/atoms/Hint'
import Label from '~/components/atoms/Label'
import RadioButton from '~/components/atoms/RadioButton'

import { FormControlInterface } from '~/models/interfaces/components/FormControl'

import { FormGroup, RequiredSign, RequiredText } from './FormControl.styles'
import CurrencyInput from '~/components/atoms/CurrencyInput'

const defaultProps = {
  inputMode: 'text',
  showError: true,
}

const FormControl = ({
  title,
  type,
  inputMode,
  placeholder,
  value,
  required,
  requiredText,
  name,
  onChange,
  onBlur,
  onKeyDown,
  onKeyUp,
  onPaste,
  valuesRadio = [],
  valuesSelect = [],
  isDisabled,
  testId,
  marginBottom,
  marginBottomItems,
  maxLength,
  textAlign,
  max,
  min,
  autoFocus,
  showError,
  spanAlign,
}: FormControlInterface) => {
  const inputDefault = (
    <FormInput
      type={type}
      placeholder={placeholder}
      value={value}
      disabled={isDisabled}
      name={name}
      inputmode={inputMode}
      onChange={onChange}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onPaste={onPaste}
      data-testid={testId || 'form-input'}
      maxLength={maxLength}
      textAlign={textAlign || 'left'}
      max={max}
      min={min}
      autoFocus={autoFocus}
    />
  )

  const getInput = (inputType: string) => {
    const input: any = {
      number: (
        <FormInput
          type="number"
          placeholder={placeholder}
          value={value}
          disabled={isDisabled}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onPaste={onPaste}
          data-testid={testId || 'form-input'}
          maxLength={maxLength}
          textAlign={textAlign || 'left'}
          max={max}
          min={min}
          autoFocus={autoFocus}
        />
      ),
      currency: (
        <CurrencyInput
          name={name}
          onChange={onChange}
          value={value as number}
        />
      ),
      radio: (
        <>
          {valuesRadio.map((valueRadio, index) => (
            <RadioButton
              disabled={isDisabled}
              id={valueRadio.id}
              name={name}
              key={valueRadio.id}
              label={valueRadio.label}
              last={valuesRadio.length === (index + 1)}
              marginBottomItems={marginBottomItems}
              onChange={onChange}
              textAlign={textAlign || 'center'}
              title={valueRadio.title}
              value={value as string}
              spanAlign={spanAlign}
            />
          ))}
        </>
      ),
      select: (
        <FormInput
          as="select"
          autoFocus={autoFocus}
          className="select"
          disabled={isDisabled}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
        >
          <option value="">{placeholder}</option>
          {valuesSelect.map((valueSelect) => (
            <option key={valueSelect.value} value={valueSelect.value}>{valueSelect.label}</option>
          ))}
        </FormInput>
      ),
      default: inputDefault,
    }

    return input[inputType] || input.default
  }

  return (
    <FormGroup marginBottom={marginBottom}>
      {
        title && (
          <Label>
            {title}
            {required && <RequiredSign>*</RequiredSign>}
            {requiredText && <RequiredText>{requiredText}</RequiredText>}
          </Label>
        )
      }
      {getInput(type)}
      {showError && <ErrorMessage name={name} component={Hint} />}
    </FormGroup>
  )
}

FormControl.defaultProps = defaultProps

export default FormControl
