import styled, { CSSProperties } from 'styled-components'
import { stylingProps, StylingProps } from '~/assets/styles/stylingProps'

type FlexProps = {
  alignItems?: CSSProperties['alignItems']
  justifyContent?: CSSProperties['justifyContent']
  flexDirection?: CSSProperties['flexDirection']
  flexWrap?: CSSProperties['flexWrap']
  gap?: CSSProperties['gap']
  flex?: CSSProperties['flex']
} & StylingProps

export const Flex = styled.div<FlexProps>`
  ${stylingProps}
  align-items: ${({ alignItems }) => alignItems};
  display: flex;
  gap: ${({ gap }) => gap};
  flex: ${({ flex }) => flex};
  flex-direction: ${({ flexDirection }) => flexDirection};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  justify-content: ${({ justifyContent }) => justifyContent};
`
