import React from 'react'
import { CreditCardIcon } from '~/assets/icons'

import { colors, sizes } from '~/assets/styles/variables'
import { H5Bold } from '~/components/atoms/Heading'

import { P2, P2Bold, P3 } from '~/components/atoms/Paragraph'

import ContractTypeEnum from '~/models/enums/ContractTypeEnum'
import { ContractBoletoCopyInterface } from '~/models/interfaces/services/Contracts'

import { useFormats } from '~/utils/useFormats'

import {
  ContractType, ExpirationColumn, ExpirationValueRow, ValueColumn,
} from './ContractCard.styles'

const { darkGray, orange } = colors
const {
  size0, size4, size10, size24,
} = sizes

interface ContractCardInterface {
  contract: ContractBoletoCopyInterface
  marginBottom?: string
}

const defaultProps = {
  marginBottom: size24,
}

const ContractCard = ({
  contract,
  marginBottom,
}: ContractCardInterface) => {
  const { formatCurrency, formatShortDateDefault, formatDay } = useFormats()

  return (
    <>
      <P2Bold marginBottom={size4}>
        {contract.product}
      </P2Bold>

      {contract.type === ContractTypeEnum.Fatura ? (
        <>
          <ContractType>
            <CreditCardIcon color={orange} margin={`${size0} ${size10} ${size0} ${size0}`} />
            <P2 color={darkGray} marginBottom={size0}>
              Cartão de crédito
            </P2>
          </ContractType>

          <ExpirationValueRow marginBottom={marginBottom}>
            <ExpirationColumn>
              <P3 color={darkGray} marginBottom={size0}>
                Vencimento
              </P3>
              <H5Bold marginBottom={size0}>
                {formatShortDateDefault(new Date(contract.dueDate))}
              </H5Bold>
            </ExpirationColumn>

            {contract.installments && contract.installments.length && (
            <ValueColumn>
              <P3 color={darkGray} marginBottom={size0}>
                Valor da fatura
              </P3>
              <H5Bold marginBottom={size0}>
                {formatCurrency(contract.installments[0].installmentValue)}
              </H5Bold>
            </ValueColumn>
            )}
          </ExpirationValueRow>
        </>
      ) : (
        <>
          <ContractType>
            <P2 color={darkGray} marginBottom={size0}>
              {`Empréstimo de ${contract.numberInstallments}X`}
            </P2>
          </ContractType>

          <ExpirationValueRow marginBottom={marginBottom}>
            <ExpirationColumn>
              <P3 color={darkGray} marginBottom={size0}>
                Vencimento
              </P3>
              <H5Bold marginBottom={size0}>
                {`Dia ${formatDay(new Date(contract.dueDate))}`}
              </H5Bold>
            </ExpirationColumn>

            {contract.installments && contract.installments.length && (
            <ValueColumn>
              <P3 color={darkGray} marginBottom={size0}>
                Valor original das parcelas
              </P3>
              <H5Bold marginBottom={size0}>
                {formatCurrency(contract.installments[0].installmentValue)}
              </H5Bold>
            </ValueColumn>
            )}
          </ExpirationValueRow>
        </>
      )}
    </>
  )
}

ContractCard.defaultProps = defaultProps

export default ContractCard
