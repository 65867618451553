import React from 'react'

import { Card } from '~/components/atoms/Card'
import { H3, H5 } from '~/components/atoms/Heading'
import Span from '~/components/atoms/Span'

import setTitle from '~/config/title'

import { colors, sizes } from '~/assets/styles/variables'
import { CardBodyStyled } from './Maintenance.styles'

const { orange } = colors
const { size0, size32 } = sizes

const Maintenance = () => {
  setTitle('Manutenção')

  return (
    <>
      <H3 marginBottom={size32}>
        Estamos em
        {' '}
        <Span color={orange}>MANUTENÇÃO!</Span>
      </H3>
      <Card marginBottom={size0} negative>
        <CardBodyStyled>
          <H5 marginBottom={size0}>
            <Span color={orange}>Prezado cliente, </Span>
            {' '}
            para melhorar sua experiência com o Portal de Negociação,
            estamos realizando
            {' '}
            <Span color={orange}>manutenções</Span>
            {' '}
            no sistema, com a estimativa de normalizar o acesso em
            <Span color={orange}> 30/01/2023, segunda-feira.</Span>
            {' '}
            Agradecemos a compreensão.
          </H5>
        </CardBodyStyled>
      </Card>
    </>
  )
}

export default Maintenance
