import styled from 'styled-components'
import { fontSizes, sizes } from '~/assets/styles/variables'

const { fontSize15 } = fontSizes
const { size6 } = sizes

interface LabelInterface {
  color?: string
  textAlign?: string
}

const Label = styled.label<LabelInterface>`
  display: block;
  color: ${({ color }) => (color || undefined)};
  font-size: ${fontSize15};
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: ${size6};
  text-align: ${({ textAlign }) => (textAlign || 'inherit')};
`

export default Label
