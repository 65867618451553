import styled from 'styled-components'

import { colors, sizes } from '~/assets/styles/variables'

import { device } from '~/config/device'

const { white } = colors
const {
  size8, size12, size14, size16, size20Percent, size25Percent, size50Percent, size60Percent,
} = sizes

export const ContractItem = styled.div`
  background-color: ${white};
  border: 2px solid ${white};
  border-radius: ${size8};
  display: flex;
  margin-bottom: ${size16};
  justify-content: flex-start;
  padding: ${size14} ${size14} ${size12};
`

export const DateColumn = styled.div`
  width: ${size25Percent};

  @media ${device.sm} {
    width: ${size20Percent};
  }
`

export const DescriptionColumn = styled.div`
  padding-right: ${size8};
  width: ${size50Percent};

  @media ${device.sm} {
    width: ${size60Percent};
  }

  p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
`

export const ValueColumn = styled.div`
  text-align: right;
  width: ${size25Percent};

  @media ${device.sm} {
    width: ${size20Percent};
  }
`
