import styled, { css } from 'styled-components'

import { colors, sizes } from '~/assets/styles/variables'
import { device } from '~/config/device'

const { lightGray, lightRed, red } = colors

const {
  size8, size16, size20, size24, size25,
} = sizes

export const ContractType = styled.div`
  display: flex;
  margin-bottom: ${size24};
`

export const ExpirationValueRow = styled.div`
  display: flex;
`

const cssColumn = css`
  align-items: center;
  border: 1px solid ${lightGray};
  border-radius: ${size8};
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: ${size8};
  padding: ${size25} ${size20};
`

export const DaysOfLateColumn = styled.div`
  ${cssColumn}

  flex: 1;
  margin-right: ${size16};
`

export const ValueColumn = styled.div`
  ${cssColumn}

  background-color: ${lightRed};
  border-color: ${red};
  width: 50%;

  @media ${device.sm} {
    width: 55%;
  }
`
