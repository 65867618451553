import React from 'react'

import {
  Button as ButtonDefault, ButtonOutline, ButtonHighlight, ButtonIcon, ButtonWhite,
} from './Button.styles'

import ButtonTypeEnum from '~/models/enums/ButtonTypeEnum'
import ButtonInterface from '~/models/interfaces/components/Button'

const Button = ({
  type, children, onClick, styledButton, testId, title, size, ...props
}: ButtonInterface) => {
  let buttonRender

  switch (styledButton) {
    case ButtonTypeEnum.Outline:
      buttonRender = (
        <ButtonOutline
          type={type || 'button'}
          title={title}
          onClick={onClick}
          {...props}
          data-testid={testId || 'outline-button'}
          size={size}
        >
          {children}
        </ButtonOutline>
      )
      break

    case ButtonTypeEnum.Highlight:
      buttonRender = (
        <ButtonHighlight
          type={type || 'button'}
          title={title}
          onClick={onClick}
          {...props}
          data-testid={testId || 'highlight-button'}
          size={size}
        >
          {children}
        </ButtonHighlight>
      )
      break

    case ButtonTypeEnum.White:
      buttonRender = (
        <ButtonWhite
          type={type || 'button'}
          title={title}
          onClick={onClick}
          {...props}
          data-testid={testId || 'white-button'}
          size={size}
        >
          {children}
        </ButtonWhite>
      )
      break

    case ButtonTypeEnum.Icon:
      buttonRender = (
        <ButtonIcon
          type={type || 'button'}
          title={title}
          onClick={onClick}
          {...props}
          data-testid={testId || 'icon-button'}
          size={size}
        >
          {children}
        </ButtonIcon>
      )
      break

    default:
      buttonRender = (
        <ButtonDefault
          type={type || 'button'}
          title={title}
          onClick={onClick}
          {...props}
          data-testid={testId || 'default-button'}
          size={size}
        >
          {children}
        </ButtonDefault>
      )
      break
  }

  return (
    <>
      { buttonRender }
    </>
  )
}

export default Button
