import React from 'react'

import Icon from '~/assets/icons'
import { CopyButtonAtom, CopyButtonAtomProps } from './styles'

export type CopyButtonProps = {
  value?: string
} & Omit<CopyButtonAtomProps, 'copied'>

export const CopyButton = React.forwardRef<HTMLButtonElement, CopyButtonProps>((props) => {
  const {
    children, onClick, value, ...rest
  } = props

  const [copied, setCopied] = React.useState(false)

  const icon = <Icon type={copied ? 'Check' : 'Copy'} size={16} />

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    navigator.clipboard.writeText(value || (typeof children === 'string' ? children : ''))
    setCopied(true)
    setTimeout(() => setCopied(false), 3_000)
    if (onClick) onClick(event)
  }

  return (
    <CopyButtonAtom
      copied={copied}
      onClick={handleClick}
      {...rest}
    >
      {!copied ? children : 'COPIADO'}

      <span>{icon}</span>
    </CopyButtonAtom>
  )
})

CopyButton.displayName = 'CopyButton'
