import { WizardInputInterface } from '~/models/interfaces/components/Wizard'

const getWizard = (stage = 1) => {
  const wizardElements: WizardInputInterface[] = [
    {
      stage: 1,
      title: 'Seleção de condições',
      get active() {
        return this.stage === stage
      },
    },
    {
      stage: 2,
      title: 'Geração de boletos',
      get active() {
        return this.stage === stage
      },
    },
  ]

  return wizardElements
}

export default getWizard
