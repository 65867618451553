import styled, { CSSProperties } from 'styled-components'

import { fontSizes, sizes } from '~/assets/styles/variables'

import { device } from '~/config/device'

const {
  fontSize18, fontSize24, fontSize30, fontSize35, fontSize42,
} = fontSizes

const { size0, size16, size64 } = sizes

interface HeadingInterface {
  color?: string
  fontWeight?: CSSProperties['fontWeight']
  marginBottom?: string
  marginTop?: string
}

export const H2 = styled.h2`
  color: ${({ color }: HeadingInterface) => (color || 'inherit')};
  font-family: Raleway, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: ${fontSize42};
  line-height: 1.35;
  margin-bottom: ${({ marginBottom }: HeadingInterface) => (marginBottom || size16)};
  margin-top: ${({ marginTop }: HeadingInterface) => (marginTop || size0)};

  @media ${device.xs} {
    font-size: ${fontSize35};
  }
`

export const H3 = styled.h3`
  color: ${({ color }: HeadingInterface) => (color || 'inherit')};
  font-family: Raleway, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: ${fontSize35};
  line-height: 1.35;
  margin-bottom: ${({ marginBottom }: HeadingInterface) => (marginBottom || size64)};
  margin-top: ${({ marginTop }: HeadingInterface) => (marginTop || size0)};
`

export const H4 = styled.h4<HeadingInterface>`
  color: ${({ color }) => (color || 'inherit')};
  font-style: normal;
  font-weight: ${({ fontWeight }) => (fontWeight || 700)};
  font-size: ${fontSize30};
  line-height: 1.35;
  margin-bottom: ${({ marginBottom }) => (marginBottom || size64)};
  margin-top: ${({ marginTop }) => (marginTop || size0)};

  @media ${device.xs} {
    font-size: ${fontSize24};
  }
`

export const H5 = styled.h5<HeadingInterface>`
  color: ${({ color }) => (color || 'inherit')};
  font-style: normal;
  font-weight: ${({ fontWeight }) => (fontWeight || 'inherit')};
  font-size: ${fontSize24};
  line-height: 1.35;
  margin-bottom: ${({ marginBottom }) => (marginBottom || size64)};
  margin-top: ${({ marginTop }) => (marginTop || size0)};

  @media ${device.xs} {
    font-size: ${fontSize18};
  }
`

export const H5Bold = styled(H5)`
  font-weight: 700;
`
