import axios from 'axios'
import { ApiResponseErrorInterface } from '~/models/interfaces/infraestructure/api'
import AuthEnum from '~/models/enums/AuthEnum'
import store from '~/store'

// const baseURL = 'https://acerta-sistema-prod.appspot.com/'
const baseURL = 'https://backend-prod.acertapromotora.com.br/'

const api = axios.create({
  baseURL,
})

export const apiWithoutInterceptor = axios.create({
  baseURL,
})

export const apiSessionInterceptor = axios.create({
  baseURL,
})

export const apiWithoutToken = axios.create({
  baseURL,
})

api.interceptors.request.use(async (config: any) => {
  store.dispatch({ type: 'NEW_REQUEST_HANDLER' })

  const token = localStorage.getItem(AuthEnum.TOKEN)

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})

api.interceptors.response.use(async (response: any) => {
  store.dispatch({ type: 'REMOVE_REQUEST_HANDLER' })

  return response
}, (error: ApiResponseErrorInterface) => {
  store.dispatch({ type: 'SET_ERROR_REQUEST_HANDLER', payload: error })
})

apiWithoutInterceptor.interceptors.request.use(async (config: any) => {
  const token = localStorage.getItem(AuthEnum.TOKEN)

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})

apiWithoutInterceptor.interceptors.response.use(async (response: any) => response)

apiSessionInterceptor.interceptors.request.use(async (config: any) => {
  const token = localStorage.getItem(AuthEnum.TOKEN)

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})

apiWithoutToken.interceptors.request.use(async (config: any) => {
  store.dispatch({ type: 'NEW_REQUEST_HANDLER' })

  return config
})

apiWithoutToken.interceptors.response.use(async (response: any) => {
  store.dispatch({ type: 'REMOVE_REQUEST_HANDLER' })

  return response
}, (error: ApiResponseErrorInterface) => {
  store.dispatch({ type: 'SET_ERROR_REQUEST_HANDLER', payload: error })
})

export default api
