import styled, { css } from 'styled-components'

import { colors, sizes } from '~/assets/styles/variables'

import { device } from '~/config/device'

const { focusBlue, lightBlue, white } = colors

const {
  size8, size10, size14, size16,
} = sizes

interface InstallmentListItemInterface {
  disabled?: boolean
  selected?: boolean
}

export const InstallmentItem = styled.label`
  align-items: center;
  background-color: ${white};
  border: 2px solid;
  border-color: ${({ selected }: InstallmentListItemInterface) => (selected ? focusBlue : white)};
  border-radius: ${size8};
  cursor: ${({ disabled }: InstallmentListItemInterface) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  justify-content: space-between;
  margin-bottom: ${size16};
  padding: ${size14};
  transition: border-color 0.25s;

  ${({ selected }: InstallmentListItemInterface) => !selected && css`
    &:hover {
      border-color: ${lightBlue};
    }
  `}
`

export const InstallmentNumberColumn = styled.div`
  align-items: center;
  display: flex;
`

export const InstallmentStatusColumn = styled.div`
  line-height: ${size10};
  text-align: right;
  width: 90px;

  @media ${device.xxs} {
    width: 80px;
  }

  @media ${device.sm} {
    width: 190px;
  }
`
