import { useEffect, useRef } from 'react'
import { useFormikContext } from 'formik'

export const AutoSubmit = () => {
  const { submitForm } = useFormikContext()

  const alreadySubmitted = useRef(false)

  useEffect(() => {
    if (alreadySubmitted.current) return
    submitForm()
  }, [submitForm])

  return null
}
