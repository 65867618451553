import React from 'react'

import { P1 } from '~/components/atoms/Paragraph'

import { colors, sizes } from '~/assets/styles/variables'

const { red } = colors
const { size64 } = sizes

interface DefaultErrorInterface {
  children?: React.ReactNode
  marginBottom?: string
}

const defaultProps = {
  children: 'Infelizmente, algo deu errado. Entre em contato com a VIACERTA.',
  marginBottom: size64,
}

const DefaultError = ({ children, marginBottom }: DefaultErrorInterface) => (
  <P1 color={red} marginBottom={marginBottom}>{children}</P1>
)

DefaultError.defaultProps = defaultProps

export default DefaultError
