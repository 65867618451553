import initialState from './initialState'
import { FormModalDialogActionTypes } from './types'

export const formModalDialogReducer = (
  state = initialState,
  action: FormModalDialogActionTypes,
) => {
  switch (action.type) {
    case 'SET_VISIBLE_FORM_MODAL_DIALOG':

      return {
        ...state,
        isVisible: true,
        formikIsVisible: true,
      }

    case 'SET_INVISIBLE_FORM_MODAL_DIALOG':

      return {
        ...state,
        formModalDialogInfo: {
          ...state.formModalDialogInfo,
          footer: null,
        },
        isVisible: false,
      }

    case 'SET_INVISIBLE_FORM_MODAL_DIALOG_FORMIK':

      return {
        ...state,
        formikIsVisible: false,
      }

    case 'SET_FORM_MODAL_DIALOG_INFO':

      return {
        ...state,
        formModalDialogInfo: {
          ...state.formModalDialogInfo,
          ...action.payload,
          title: action.payload.title || '',
        },
      }

    case 'RESET_FORM_MODAL_DIALOG_INFO':

      return {
        ...state,
        formModalDialogInfo: initialState.formModalDialogInfo,
      }

    default:
      return state
  }
}
