import { UserDataProcessingInterface } from '~/models/interfaces/services/Customers'

import initialState from './initialState'
import { UserDataProcessingActionTypes } from './types'

export function userDataProcessingReducer(
  state = initialState,
  action: UserDataProcessingActionTypes,
): UserDataProcessingInterface {
  switch (action.type) {
    case 'SET_INITIAL_USER_DATA_PROCESSING':

      return {
        ...state,
        idEntryAutomation: undefined,
      }

    case 'UPDATE_USER_DATA_PROCESSING':

      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}
