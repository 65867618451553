import styled, { css } from 'styled-components'
import { colors, sizes } from '~/assets/styles/variables'

const {
  white, blue, lightGreen, red, orange,
} = colors

const { size16 } = sizes

export const successMessage = css`
  background: ${white};
  color: ${blue};
`

export const successMessageProgress = css`
  background:${lightGreen};
`

export const errorMessage = css`
  background: ${white};
  color: ${blue};
`

export const errorMessageProgress = css`
  background:${red};
`

export const alertMessage = css`
  background: ${white};
  color: ${blue};
`

export const alertMessageProgress = css`
  background:${orange};
`

export const ContainerMessage = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: ${size16};
  }
`
