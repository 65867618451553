import React from 'react'

import Header from '~/components/organisms/Header'

import { ContainerWrapper } from './PortalTemplate.styles'

interface PortalTemplateInterface {
  actionBar?: boolean,
  wizard?: boolean,
  children: React.ReactNode
  hasDevelopedByViAcerta?: boolean
}

const defaultProps = {
  actionBar: true,
  wizard: true,
  hasDevelopedByViAcerta: false,
}

function PortalTemplate(props: PortalTemplateInterface) {
  const {
    actionBar, wizard, children, hasDevelopedByViAcerta,
  } = props
  return (
    <>
      <Header hasDevelopedByViAcerta={hasDevelopedByViAcerta} hasBackgroundColor />
      <ContainerWrapper actionBar={actionBar} wizard={wizard}>
        {children}
      </ContainerWrapper>
    </>
  )
}

PortalTemplate.defaultProps = defaultProps

export default PortalTemplate
