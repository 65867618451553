import React from 'react'
import { useSelector } from 'react-redux'
import { MailErrorIcon, MailSuccessIcon, WarningIcon } from '~/assets/icons'

import { colors, sizes } from '~/assets/styles/variables'

import Button from '~/components/atoms/Button'
import { H5Bold } from '~/components/atoms/Heading'

import ButtonTypeEnum from '~/models/enums/ButtonTypeEnum'
import ModalDialogTypeEnum from '~/models/enums/ModalDialogTypeEnum'

import store from '~/store'
import { RootState } from '~/store/reducers'

import useWindowDimensions from '~/utils/useWindowDimensions'

import {
  Content, Footer, Header, IconWrapper, Overlay,
} from './ModalDialog.styles'

const { orange } = colors
const { size16, size56 } = sizes

const ModalDialog = () => {
  const modalDialogSelector = useSelector(
    ({ modalDialog }: RootState) => modalDialog,
  )

  const { xs } = useWindowDimensions()

  const {
    modalDialogInfo,
    isVisible,
  } = modalDialogSelector

  const {
    cancelButtonText,
    confirmButtonText,
    Children,
    handleCancel,
    handleConfirm,
    title,
    type,
  } = modalDialogInfo

  const onClose = () => {
    store.dispatch({ type: 'SET_INVISIBLE_MODAL_DIALOG' })

    setTimeout(() => {
      store.dispatch({ type: 'RESET_MODAL_DIALOG_INFO' })
    }, 200)
  }

  const confirmDialog = () => {
    handleConfirm()
    onClose()
  }

  const cancelDialog = () => {
    handleCancel()
    onClose()
  }

  return (
    <Overlay className={isVisible ? 'show' : ''} onClick={onClose}>
      <Content
        className="modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <Header>
          <IconWrapper>
            {type === ModalDialogTypeEnum.Warning && (
            <WarningIcon color={orange} size={size56} />
            )}

            {type === ModalDialogTypeEnum.MailSuccess && (
            <MailSuccessIcon />
            )}

            {type === ModalDialogTypeEnum.MailError && (
            <MailErrorIcon />
            )}
          </IconWrapper>

          <H5Bold marginBottom={size16}>
            {title}
          </H5Bold>
        </Header>

        {Children && <Children />}
        <Footer hasHandleConfirm={!!handleConfirm}>
          <Button
            onClick={handleCancel ? cancelDialog : onClose}
            size={xs && !!handleConfirm ? 'small' : 'medium'}
            styledButton={ButtonTypeEnum.Outline}
            type="button"
          >
            {cancelButtonText}
          </Button>
          {!!handleConfirm && (
          <Button
            onClick={confirmDialog}
            size={xs ? 'small' : 'medium'}
            type="button"
          >
            {confirmButtonText}
          </Button>
          )}
        </Footer>
      </Content>
    </Overlay>
  )
}

export default ModalDialog
