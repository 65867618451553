import styled from 'styled-components'

import { colors, fontSizes, sizes } from '~/assets/styles/variables'

import { Card, CardBody } from '~/components/atoms/Card'
import { H2 } from '~/components/atoms/Heading'

import { device } from '~/config/device'

const {
  size0, size5, size6, size8, size16, size24, size32, size38,
} = sizes

export const TotalSelectedCardBodyStyled = styled(CardBody)`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

export const ChangeSelectedItemsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
`

export const DiscountValueCardStyled = styled(Card)`
  margin-top: ${size32};
`

export const DiscountValueCardBodyStyled = styled(CardBody)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${device.md} {
    flex-direction: row;
    justify-content: center;
  }

  .original-value {
    text-decoration-line: line-through;
  }
`

export const H2Styled = styled(H2)`
  font-family: inherit;
  margin-bottom: ${size6};

  @media ${device.md} {
    margin: ${size0} ${size38};
  }
`

export const DiscountCalculateRow = styled.div`
  margin-bottom: ${size5};

  @media ${device.sm} {
    align-items: flex-end;
    display: flex;
  }
`

export const DueDateFormControlWrapper = styled.div`
  margin-bottom: ${size5};

  @media ${device.sm} {
    flex: 1;
    margin-bottom: ${size0};
  }
`

export const CalculateButtonWrapper = styled.div`
  @media ${device.sm} {
    margin-left: ${size16};
  }
`

export const PaymentMethodContainer = styled.section`
  margin-block: ${size24};

  & > div {
    display: flex;
    flex-direction: column;
    gap: ${size16} ${size32};

    @media ${device.sm} {
      flex-direction: row;
    }

    & > label {
      align-items: center;
      display: flex;
      flex: 1;

      & svg {
        margin: unset;
      }
      & p {
        font-size: ${fontSizes.fontSize18};
      }
    }
  }
`

export const LabelContainer = styled.div`
  color: ${colors.blue};
  display: flex;
  align-items: center;
  gap: ${size8};

  & p:last-of-type {
    color: ${colors.gray};
    font-size: ${fontSizes.fontSize12};
    font-weight: 600;
  }
`
