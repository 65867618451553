import React from 'react'
import { useSelector } from 'react-redux'
import { Form, Formik } from 'formik'

import { CloseIcon } from '~/assets/icons'
import Button from '~/components/atoms/Button'

import ButtonTypeEnum from '~/models/enums/ButtonTypeEnum'

import store from '~/store'
import { RootState } from '~/store/reducers'

import {
  Body, CloseButton, Content, Footer, Header, Overlay,
} from './FormModalDialog.styles'

const FormModalDialog = () => {
  const formModalDialogSelector = useSelector(
    ({ formModalDialog }: RootState) => formModalDialog,
  )

  const {
    isVisible,
    formikIsVisible,
    formModalDialogInfo,
  } = formModalDialogSelector

  const {
    title,
    validationSchema,
    initialValues,
    handleCancel,
    handleConfirm,
    FormFields,
    footer,
  } = formModalDialogInfo

  const onClose = (cancel = true) => {
    store.dispatch({ type: 'SET_INVISIBLE_FORM_MODAL_DIALOG' })

    setTimeout(() => {
      store.dispatch({ type: 'SET_INVISIBLE_FORM_MODAL_DIALOG_FORMIK' })
    }, 200)

    if (handleCancel && cancel) {
      handleCancel()
    }
  }

  const confirmDialog = (values: any) => {
    const retConfirm = handleConfirm(values)
    const typeofRetConfirm = typeof retConfirm

    if ((typeofRetConfirm === 'boolean' && retConfirm) || typeofRetConfirm !== 'boolean') {
      onClose(false)
    }
  }

  return (
    <Overlay className={isVisible ? 'show' : ''} onClick={() => onClose()}>
      <Content
        className="modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <Header>
          {title}
          <CloseButton onClick={() => onClose()} title="Fechar">
            <CloseIcon />
          </CloseButton>
        </Header>

        {formikIsVisible && initialValues && (
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={confirmDialog}
            validationSchema={validationSchema}
            validateOnMount
          >
            {(formData) => (
              <Form>
                <Body>
                  {FormFields && <FormFields formData={formData} />}
                </Body>
                {!footer ? (
                  <Footer hasHandleConfirm={!!handleConfirm}>
                    <Button
                      onClick={() => onClose()}
                      styledButton={ButtonTypeEnum.Outline}
                      type="button"
                    >
                      {handleConfirm ? 'Cancelar' : 'Fechar'}
                    </Button>
                    {handleConfirm && (
                      <Button disabled={!formData.isValid} type="submit">
                        Confirmar
                      </Button>
                    )}
                  </Footer>
                ) : (
                  footer
                )}
              </Form>
            )}
          </Formik>
        )}
      </Content>
    </Overlay>
  )
}

export default FormModalDialog
