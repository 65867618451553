import styled from 'styled-components'

import { sizes } from '~/assets/styles/variables'

import { device } from '~/config/device'

const { size0, size16, size32 } = sizes

export const SendBoletoEmailRow = styled.div`
  margin-bottom: ${size32};

  @media ${device.sm} {
    align-items: flex-end;
    display: flex;
  }
`

export const EmailFormControlWrapper = styled.div`
  margin-bottom: ${size16};

  @media ${device.sm} {
    flex: 1;
    margin-bottom: ${size0};
  }
`

export const SendEmailButtonWrapper = styled.div`
  @media ${device.sm} {
    margin-left: ${size16};
  }
`

export const OrWrapper = styled.div`
  margin-bottom: ${size32};
`

export const BoletoGenerateButtonWrapper = styled.div`
  button {
    display: block;
    margin-bottom: ${size16};
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`
