import { useEffect, useState } from 'react'

import { breakpoint } from '~/config/device'

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  const xxs = width <= breakpoint.xxs
  const xs = width <= breakpoint.xs
  const sm = width >= breakpoint.sm
  const md = width >= breakpoint.md
  const lg = width >= breakpoint.lg
  const xl = width >= breakpoint.xl
  const xxl = width >= breakpoint.xxl

  return {
    width,
    height,
    xxs,
    xs,
    sm,
    md,
    lg,
    xl,
    xxl,
  }
}

const useWindowDimensions = () => {
  const [windowDimensions,
    setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}

export default useWindowDimensions
