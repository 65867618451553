import { useCallback } from 'react'

export const useName = () => {
  const firstName = useCallback((fullname: string): string => (
    fullname.split(' ').shift() || ''
  ), [])

  return {
    firstName,
  }
}
