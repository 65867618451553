import React from 'react'
import { useHistory } from 'react-router-dom'

import { pageWidth } from '~/config/layout/pageWidth'
import Button from '~/components/atoms/Button'
import ButtonTypeEnum from '~/models/enums/ButtonTypeEnum'

import { Container, Footer, ButtonSubmit } from './ActionBar.styles'

import { CheckIcon } from '~/assets/icons'

interface ActionBarInterface {
  lastStep?: boolean
  nextButtonText?: string
  returnAction?: () => void
  submitActive?: boolean
  width?: number
}

const defaultProps = {
  lastStep: false,
  nextButtonText: 'Continuar',
  returnAction: null,
  submitActive: true,
  width: pageWidth.medium,
}

function ActionBar({
  nextButtonText,
  lastStep,
  returnAction,
  submitActive,
  width,
}: ActionBarInterface) {
  const history = useHistory()

  const handleGoBack = () => {
    if (returnAction) {
      returnAction()
    } else {
      history.goBack()
    }
  }

  return (
    <Footer>
      <Container width={width}>
        <Button
          iconSide="start"
          styledButton={ButtonTypeEnum.Outline}
          onClick={handleGoBack}
          size="small"
        >
          Voltar
        </Button>
        <ButtonSubmit type="submit" disabled={!submitActive}>
          {lastStep ? (
            <>
              Finalizar
              <CheckIcon />
            </>
          ) : (
            <>{nextButtonText}</>
          )}
        </ButtonSubmit>
      </Container>
    </Footer>
  )
}

ActionBar.defaultProps = defaultProps

export default ActionBar
