import React from 'react'

import { colors, sizes } from '~/assets/styles/variables'

import Checkbox from '~/components/atoms/Checkbox'
import { P3, P4 } from '~/components/atoms/Paragraph'
import Tag from '~/components/atoms/Tag'

import { DealPendingsInterface } from '~/models/interfaces/services/Deals'
import NegotiationTypeEnum from '~/models/enums/NegotiationTypeEnum'

import { useFormats } from '~/utils/useFormats'

import { InstallmentItem, InstallmentNumberColumn, InstallmentStatusColumn } from './InstallmentListItem.styles'

const { darkGray } = colors
const { size0 } = sizes

interface InstallmentListItemInterface {
  handleCheck: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void
  installment: DealPendingsInterface
}

const InstallmentListItem = (
  { handleCheck, installment }: InstallmentListItemInterface,
) => {
  const { formatCurrency, formatDateDefault } = useFormats()

  const {
    installments, daysDelay, externalCode, type,
  } = installment

  return (
    <InstallmentItem selected={installments[0].selected}>
      {type === NegotiationTypeEnum.Renegociacao ? (
        <>
          <InstallmentNumberColumn>
            <Checkbox
              id="true"
              name={externalCode}
              value={`${!installments[0].selected ? 'false' : 'true'}`}
              onChange={(event) => handleCheck(event, externalCode)}
              marginBottom={size0}
            />
            <div>
              <P4 color={darkGray}>
                Valor entrada
              </P4>
              <P3 marginBottom={size0}>
                {formatCurrency(+installments[0].amount)}
              </P3>
            </div>
          </InstallmentNumberColumn>
          <div>
            <P4 color={darkGray}>
              Valor das parcelas
            </P4>
            <P3 marginBottom={size0}>
              {formatCurrency(+installments[1].amount)}
            </P3>
          </div>
        </>
      ) : (
        <>
          <InstallmentNumberColumn>
            <Checkbox
              id="true"
              name={externalCode}
              value={`${!installments[0].selected ? 'false' : 'true'}`}
              onChange={(event) => handleCheck(event, externalCode)}
              marginBottom={size0}
            />
            <div>
              <P4 color={darkGray}>
                Valor a pagar
              </P4>
              <P3 marginBottom={size0}>
                {formatCurrency(+installments[0].amount)}
              </P3>
            </div>
          </InstallmentNumberColumn>
          <div>
            <P4 color={darkGray}>
              Vencimento
            </P4>
            <P3 marginBottom={size0}>
              {formatDateDefault(new Date(installment.dueDate))}
            </P3>
          </div>
        </>
      )}

      <InstallmentStatusColumn>
        {daysDelay > 0 && (
          <Tag small tagType="negative">
            Em atraso
          </Tag>
        )}
        {daysDelay <= 0 && (
        <Tag small tagType="neutral">
          A vencer
        </Tag>
        )}
      </InstallmentStatusColumn>
    </InstallmentItem>
  )
}

export default InstallmentListItem
