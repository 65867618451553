import React from 'react'

import { colors, sizes } from '~/assets/styles/variables'

import { P3, P4 } from '~/components/atoms/Paragraph'

import { ProposalOriginInterface } from '~/models/interfaces/services/Proposal'

import {
  Item, Column, ColumnProduct, ColumnContract,
} from './ListItem.styles'

import { usePluralization } from '~/utils/usePluralization'

const { darkGray } = colors
const { size0 } = sizes

interface ListItemInterface {
  item: ProposalOriginInterface
}

const ListItem = ({ item }: ListItemInterface) => {
  const { pluralize } = usePluralization()

  return (
    <Item>
      <ColumnProduct>
        <P4 color={darkGray}>
          Produto
        </P4>
        <P3 marginBottom={size0}>
          {String(item.productDescription).padStart(3, '0')}
        </P3>
      </ColumnProduct>
      <ColumnContract>
        <P4 color={darkGray}>
          Contrato
        </P4>
        <P3 marginBottom={size0}>
          {item.contractNumber}
        </P3>
      </ColumnContract>
      <Column>
        <P4 color={darkGray}>
          {pluralize(
            item.installmentNumber.length,
            'Parcela',
            'Parcelas',
          )}
        </P4>
        <P3 marginBottom={size0} title={item.installmentNumber}>
          {item.installmentNumber}
        </P3>
      </Column>
    </Item>
  )
}

export default ListItem
