import React from 'react'
import { useSelector } from 'react-redux'
import { CampaignType } from '~/models/enums/Campaign'
import { RootState } from '~/store/reducers'
import { BannerImage, BannerImageProps } from '..'
import { breakpoint } from '~/config/device'

type Props = BannerImageProps

export const CampaignBanner = (props: Props) => {
  const { campaignType, bannerFlow, bannerFlowMobile } = useSelector(({ campaign }: RootState) => campaign)

  if (![CampaignType.Both, CampaignType.Charge].includes(campaignType as CampaignType)) return null

  return (
    <picture>
      <source srcSet={bannerFlowMobile} media={`(max-width: ${breakpoint.sm}px)`} />
      <BannerImage
        src={bannerFlow}
        alt="Banner da campanha"
        marginBottom="1rem"
        {...props}
      />
    </picture>
  )
}
