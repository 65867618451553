import React from 'react'

import { colors, sizes } from '~/assets/styles/variables'

import { P2Bold } from '~/components/atoms/Paragraph'

import { P2Subtitle, SubtitleWrapper } from './NegotiationRadioButtonContent.styles'

const { darkGray } = colors
const { size0, size4 } = sizes

interface NegotiationRadioButtonItemInterface {
  title: string
}

const NegotiationRadioButtonItem = ({ title }: NegotiationRadioButtonItemInterface) => (
  <>
    <P2Bold marginBottom={size4}>
      {title.toUpperCase()}
    </P2Bold>
    <SubtitleWrapper>
      <P2Subtitle color={darkGray} marginBottom={size0}>
        Condições especiais para negociar sua dívida na
        {' '}
        {title}
        !
      </P2Subtitle>
    </SubtitleWrapper>
  </>
)

export default NegotiationRadioButtonItem
