import {
  colors, fontSizes, sizes, zIndex,
} from '~/assets/styles/variables'

const { white, textBlack, blue } = colors

export const theme = {
  palette: {
    primary: {
      main: blue,
      contrastText: white,
    },
    text: {
      primary: textBlack,
    },
    background: {
      default: white,
    },
    gray: {
      50: '#F5F6F7',
    },
  },
  fontSizes,
  sizes,
  zIndex,
}
