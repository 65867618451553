import styled from 'styled-components'

interface SpanInterface {
  color?: string
}

const Span = styled.span`
  color: ${({ color }: SpanInterface) => (color || 'inherit')};
`

export default Span
