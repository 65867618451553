import React from 'react'

import Spinner from '~/components/atoms/Spinner'

import { Container } from './PageLoader.styles'

const PageLoader = () => (
  <Container>
    <Spinner width="12" size="160" />
  </Container>
)

export default PageLoader
