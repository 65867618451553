import styled from 'styled-components'

export const Wrapper = styled.div`
  max-width: 90px;
  
  & > span {
    color: ${({ theme }) => theme.palette.text.primary};
    display: block;
    font-size: ${({ theme }) => theme.fontSizes.fontSize10};
    font-weight: normal;
    margin-bottom: ${({ theme }) => theme.sizes.size5};
  }
`
