import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Form, Formik } from 'formik'

import { Col, Row } from 'react-grid-system'
import { colors, sizes } from '~/assets/styles/variables'

import { CampaignBanner } from '~/components/atoms/Banner/common'
import { Card, CardBody } from '~/components/atoms/Card'
import { H4, H5Bold } from '~/components/atoms/Heading'
import PageContainer from '~/components/atoms/PageContainer'
import ActionBar from '~/components/molecules/ActionBar'
import Wizard from '~/components/molecules/Wizard'
import PortalTemplate from '~/components/templates/PortalTemplate'

import { pageWidth } from '~/config/layout/pageWidth'
import setTitle from '~/config/title'

import { WizardInputInterface } from '~/models/interfaces/components/Wizard'

import {
  RenegotiationSimulationContractOutputInterface,
  RenegotiationSimulationOutputInterface,
  RenegotiationSimulationParamsInterface,
} from '~/models/interfaces/services/Negotiations'

import { simulate } from '~/services/Negotiations'

import { RootState } from '~/store/reducers'

import { useUniqueId } from '~/utils/useUniqueId'
import { useFormats } from '~/utils/useFormats'

import ContractCard from './ContractCard'
import {
  CardBodyStyled, ContractsWrapper, P3BoldStyled, ValueColumn,
} from './Contracts.styles'
import { P2 } from '~/components/atoms/Paragraph'
import Button from '~/components/atoms/Button'

const { darkGray } = colors
const { size0 } = sizes

interface StateInterface {
  negotiationId: string
}

const Contracts = () => {
  setTitle('Revisão de contratos - Renegociação')

  const wizardElements: WizardInputInterface[] = [
    {
      title: 'Negociações disponíveis',
    },
    {
      title: 'Revisão de contratos',
      active: true,
    },
    {
      title: 'Valor da entrada e datas de vencimento',
    },
    {
      title: 'Geração de boleto',
    },
  ]

  const history = useHistory()
  const location = useLocation()
  const { generateUniqueStringId } = useUniqueId()
  const { formatCurrency } = useFormats()

  const authSelector = useSelector(
    ({ auth }: RootState) => auth,
  )

  const { user } = authSelector

  const userDataSelector = useSelector(
    ({ userDataProcessing }: RootState) => userDataProcessing,
  )

  const { idEntryAutomation } = userDataSelector

  const state = location.state as StateInterface

  const [isLoaded,
    setIsLoaded] = useState<boolean>(false)

  const [simulationOutput,
    setSimulationOutput] = useState<RenegotiationSimulationOutputInterface | null>(null)

  const containerPageWidth = pageWidth.medium

  useEffect(() => {
    if (!user
      || !state
      || !state.negotiationId
      || simulationOutput) return

    const simulationInput: RenegotiationSimulationParamsInterface = {
      cpfCnpj: user.cpfCnpj,
      customerId: user.id,
      negotiationId: state.negotiationId,
      renegotiationStep: 1,
      eventDescription: '',
      idEntryAutomation,
    }

    simulate(simulationInput)
      .then((response) => setSimulationOutput(response))
      .finally(() => setIsLoaded(true))
  }, [idEntryAutomation, simulationOutput, state, user])

  const handleSubmit = () => {
    history.push('/renegociacao/efetivacao', {
      negotiationId: state.negotiationId,
      installmentIds: simulationOutput?.installmentIds,
    })
  }

  const handleRedirect = (page: string) => history.push(page)

  return (
    <PortalTemplate wizard={!!simulationOutput?.contracts.length}>
      {isLoaded && (
        <>
          { simulationOutput?.contracts.length ? (
            <>
              <Wizard>{wizardElements}</Wizard>

              <PageContainer width={containerPageWidth}>
                <CampaignBanner />

                <H4>
                  Estes serão os contratos renegociados:
                </H4>

                <Formik
                  enableReinitialize
                  initialValues={{}}
                  onSubmit={handleSubmit}
                  validateOnMount
                >
                  {() => (
                    <Form>
                      <ContractsWrapper>
                        {simulationOutput.contracts.map((
                          contract: RenegotiationSimulationContractOutputInterface,
                        ) => (
                          <Card key={generateUniqueStringId()} negative>
                            <CardBody>
                              <ContractCard contract={contract} />
                            </CardBody>
                          </Card>
                        ))}
                        <Card key={generateUniqueStringId()} negative>
                          <CardBodyStyled>
                            <P3BoldStyled color={darkGray} marginBottom={size0}>
                              Total:
                            </P3BoldStyled>
                            <ValueColumn>
                              <H5Bold marginBottom={size0}>
                                {formatCurrency(simulationOutput.valueTotal)}
                              </H5Bold>
                            </ValueColumn>
                          </CardBodyStyled>
                        </Card>
                      </ContractsWrapper>
                      <ActionBar width={containerPageWidth} />
                    </Form>
                  )}
                </Formik>
              </PageContainer>
            </>
          ) : (
            <PageContainer textAlign="center" width={pageWidth.small}>
              <H4>
                No momento não é possível negociar nenhum dos seus contratos.
              </H4>

              <Row justify="center">
                <Col xs={11} sm={7}>
                  <Card marginBottom={size0} negative>
                    <CardBody>
                      <P2 color={darkGray}>
                        Mesmo assim, você ainda pode quitar valores à vista com desconto.
                      </P2>

                      <Button
                        displayBlock
                        onClick={() => handleRedirect('/quitacao')}
                        type="button"
                      >
                        Quitar valores à vista
                      </Button>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </PageContainer>
          )}
        </>
      )}
    </PortalTemplate>
  )
}

export default Contracts
