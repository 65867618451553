import styled, { css } from 'styled-components'

import { colors, fontSizes, sizes } from '~/assets/styles/variables'

const {
  blue, darkGray, gray, white,
} = colors

const { fontSize14 } = fontSizes

const {
  size0, size2, size3, size5, size8, size16, size18,
} = sizes

interface CheckboxInterface {
  checked?: boolean
  disabled?: boolean
  marginBottom?: string
}

export const Checkmark = styled.span`
  background-color: ${white};
  border: 2px solid ${gray};
  border-radius: ${size2};
  height: ${size18};
  left: ${size0};
  position: absolute;
  top: ${size0};
  transition: background-color 0.25s, border-color 0.25s;
  width: ${size18};

  :after {
    border: solid ${white};
    border-width: 0 ${size3} ${size3} 0;
    content: '';
    display: none;
    height: ${size8};
    left: ${size5};
    position: absolute;
    top: ${size2};
    transform: rotate(40deg);
    width: ${size3};
  }
`

export const Checkbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: ${size0};
  width: ${size0};

  :checked ~ ${Checkmark} {
    background: ${({ disabled }: CheckboxInterface) => (disabled ? darkGray : blue)};
    border: 1px solid ${({ disabled }: CheckboxInterface) => (disabled ? darkGray : blue)};
  }

  :checked ~ ${Checkmark}::after {
    display: block;
  }
`

export const CheckboxLabel = styled.label`
  color: ${({ theme: { palette } }) => palette.text.primary};
  cursor: pointer;
  display: block;
  font-size: ${fontSize14};
  margin-bottom: ${({ marginBottom }: CheckboxInterface) => marginBottom};
  min-height: ${size18};
  padding-left: calc(${size18} + ${size16});
  position: relative;

  ${({ disabled }: CheckboxInterface) => disabled
    && css`
      color: ${darkGray};
      cursor: not-allowed;
    `}

  ${({ disabled }: CheckboxInterface) => !disabled
    && css`
      :hover ${Checkbox} ~ ${Checkmark} {
        background-color: ${blue};
      }
    `}
`
