import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Hidden, Row } from 'react-grid-system'
import { useHistory } from 'react-router-dom'
import { Form, Formik } from 'formik'

import { colors, sizes } from '~/assets/styles/variables'
import Button from '~/components/atoms/Button'

import { CampaignBanner } from '~/components/atoms/Banner/common'
import { Card, CardBody } from '~/components/atoms/Card'
import { H4 } from '~/components/atoms/Heading'
import PageContainer from '~/components/atoms/PageContainer'
import { P2 } from '~/components/atoms/Paragraph'
import Span from '~/components/atoms/Span'
import ActionBar from '~/components/molecules/ActionBar'
import Wizard from '~/components/molecules/Wizard'
import PortalTemplate from '~/components/templates/PortalTemplate'

import { pageWidth } from '~/config/layout/pageWidth'
import setTitle from '~/config/title'
import ContractTypeEnum from '~/models/enums/ContractTypeEnum'

import ModalDialogTypeEnum from '~/models/enums/ModalDialogTypeEnum'
import { WizardInputInterface } from '~/models/interfaces/components/Wizard'
import { ContractDischargeInterface } from '~/models/interfaces/services/Contracts'

import { getContractsToDischarge } from '~/services/Customers'

import store from '~/store'
import { RootState } from '~/store/reducers'

import { usePluralization } from '~/utils/usePluralization'
import { useUniqueId } from '~/utils/useUniqueId'
import useWindowDimensions from '~/utils/useWindowDimensions'

import ContractCard from './ContractCard'
import { ContractActionsRow, ContractButtonsColumn, ContractsWrapper } from './Contracts.styles'
import { FormModalDialogInstallmentsFields } from './FormModalDialogInstallmentsFields'

const { darkGray, focusBlue, orange } = colors
const { size0, size8 } = sizes

const Contracts = () => {
  setTitle('Seleção de contratos - Quitação')

  const wizardElements: WizardInputInterface[] = [
    {
      title: 'Seleção de contratos',
      active: true,
    },
    {
      title: 'Efetivação',
    },
    {
      title: 'Geração de boletos',
    },
  ]

  const history = useHistory()
  const { pluralize } = usePluralization()
  const { md } = useWindowDimensions()
  const { generateUniqueStringId } = useUniqueId()

  const [authSelector, userDataSelector, dischargeContractsSelector] = useSelector(
    ({
      auth, userDataProcessing, dischargeContracts,
    }: RootState) => [auth, userDataProcessing, dischargeContracts] as const,
  )

  const { user } = authSelector

  const { idEntryAutomation } = userDataSelector

  const { contracts } = dischargeContractsSelector

  const [isLoaded,
    setIsLoaded] = useState<boolean>(false)

  const containerPageWidth = pageWidth.medium

  const [block,
    setBlock] = useState<boolean>(true)

  useEffect(() => {
    if (!user) return

    if (!contracts.length) {
      getContractsToDischarge(user.id, user.cpfCnpj, idEntryAutomation)
        .then((response) => {
          store.dispatch({
            type: 'UPDATE_DISCHARGE_CONTRACTS',
            payload: { contracts: response },
          })
        })
        .finally(() => setIsLoaded(true))
    } else {
      setIsLoaded(true)
    }
  }, [contracts.length, idEntryAutomation, user])

  useEffect(() => {
    setBlock(true)

    contracts.forEach((contract) => {
      if (contract.installments.filter((i) => i.selected).length) {
        setBlock(false)
      }
    })
  }, [contracts])

  const handleRefreshContractsState = () => {
    const freshContracts: ContractDischargeInterface[] = [...contracts]

    store.dispatch({
      type: 'UPDATE_DISCHARGE_CONTRACTS',
      payload: { contracts: freshContracts },
    })
  }

  const handleSelectInstallments = (formValues: any): boolean => {
    const selectedContract = formValues as ContractDischargeInterface
    let hasUnselectedInstallment = false
    let hasInvalidSelectedInstallment = false

    selectedContract.installments.forEach((i) => {
      if (!i.selected) {
        hasUnselectedInstallment = true
      } else if (hasUnselectedInstallment) {
        hasInvalidSelectedInstallment = true
      }
    })

    if (hasInvalidSelectedInstallment) {
      store.dispatch({
        type: 'SET_MODAL_DIALOG_INFO',
        payload: {
          cancelButtonText: 'Ok, fechar',
          Children: () => (
            <P2>
              Você precisa selecionar todas as parcelas do
              {' '}
              <Span color={orange}>
                intervalo
              </Span>
              {' '}
              desejado!
            </P2>
          ),
          handleCancel: null,
          handleConfirm: null,
          title: 'Ops! Você esqueceu alguma parcela!',
          type: ModalDialogTypeEnum.Warning,
        },
      })
      store.dispatch({ type: 'SET_VISIBLE_MODAL_DIALOG' })

      return false
    }

    const selectedContracts: ContractDischargeInterface[] = [...contracts]

    selectedContracts.forEach((contract) => {
      if (selectedContract.contractNumber === contract.contractNumber) {
        contract.installments = selectedContract.installments
      }
    })

    store.dispatch({
      type: 'UPDATE_DISCHARGE_CONTRACTS',
      payload: { contracts: selectedContracts },
    })

    return true
  }

  const showModalContractInstallments = (
    contract: ContractDischargeInterface,
    modalTitle: string,
  ) => {
    store.dispatch({
      type: 'SET_FORM_MODAL_DIALOG_INFO',
      payload: {
        FormFields: FormModalDialogInstallmentsFields,
        initialValues: contract,
        handleCancel: handleRefreshContractsState,
        handleConfirm: (formValues: any) => handleSelectInstallments(formValues),
        title: modalTitle,
        validationSchema: null,
      },
    })
    store.dispatch({ type: 'SET_VISIBLE_FORM_MODAL_DIALOG' })
  }

  const handleSubmit = () => {
    history.push('/quitacao/efetivacao', { contracts })
  }

  const handleRedirect = (page: string) => history.push(page)

  return (
    <PortalTemplate wizard={!!contracts.length}>
      {isLoaded && (
      <>
        { contracts.length ? (
          <>
            <Wizard>{wizardElements}</Wizard>

            <PageContainer width={containerPageWidth}>
              <CampaignBanner />

              <H4>
                Selecione abaixo os contratos e parcelas disponíveis, que você deseja quitar e antecipar:
              </H4>

              <Formik
                enableReinitialize
                initialValues={{}}
                onSubmit={handleSubmit}
                validateOnMount
              >
                {() => (
                  <Form>
                    <ContractsWrapper>
                      {contracts.map((contract: ContractDischargeInterface) => {
                        const contractStateCopy = { ...contract }
                        contractStateCopy.installments = []

                        contract.installments.forEach((i) => {
                          contractStateCopy.installments.push({ ...i })
                        })

                        return (
                          <Card key={generateUniqueStringId()} negative>
                            <CardBody>

                              <ContractCard contract={contract} />

                              {contract.contractType === ContractTypeEnum.Fatura ? (
                                <ContractActionsRow>

                                  {contract.installments.filter((i) => i.selected).length ? (
                                    <P2 color={focusBlue} marginBottom={md ? size0 : size8}>
                                      Fatura selecionada
                                    </P2>
                                  ) : (
                                    <P2 color={darkGray} marginBottom={md ? size0 : size8}>
                                      Fatura não selecionada
                                    </P2>
                                  )}

                                  <ContractButtonsColumn>
                                    <Button
                                      displayBlock={!md}
                                      onClick={() => showModalContractInstallments(contractStateCopy, 'Selecionar fatura')}
                                      size="small"
                                    >
                                      Selecionar
                                      <Hidden xs>
                                        {' '}
                                        fatura
                                      </Hidden>
                                    </Button>
                                  </ContractButtonsColumn>
                                </ContractActionsRow>
                              ) : (
                                <ContractActionsRow>
                                  {contract.installments.filter((i) => i.selected).length ? (
                                    <P2 color={orange} marginBottom={md ? size0 : size8}>
                                      {
                                        `${contract.installments.filter((i) => i.selected).length} ${pluralize(
                                          contract.installments.filter((i) => i.selected).length,
                                          ' parcela selecionada',
                                          ' parcelas selecionadas',
                                        )}`
                                      }
                                    </P2>
                                  ) : (
                                    <P2 color={darkGray} marginBottom={md ? size0 : size8}>
                                      Nenhuma parcela selecionada
                                    </P2>
                                  )}

                                  <ContractButtonsColumn>
                                    <Button
                                      displayBlock={!md}
                                      onClick={() => showModalContractInstallments(contractStateCopy, 'Selecionar parcelas')}
                                      size="small"
                                    >
                                      Selecionar parcelas
                                    </Button>
                                  </ContractButtonsColumn>
                                </ContractActionsRow>
                              )}
                            </CardBody>
                          </Card>
                        )
                      })}
                    </ContractsWrapper>
                    <ActionBar
                      returnAction={() => handleRedirect('/menu-cliente')}
                      submitActive={!block}
                      width={containerPageWidth}
                    />
                  </Form>
                )}
              </Formik>
            </PageContainer>
          </>
        ) : (
          <PageContainer textAlign="center" width={pageWidth.small}>
            <H4>
              No momento não é possível quitar nenhum dos seus contratos.
            </H4>

            <Row justify="center">
              <Col xs={11} sm={7}>
                <Card marginBottom={size0} negative>
                  <CardBody>
                    <P2 color={darkGray}>
                      Você pode entrar em contato conosco para quitar suas dívidas.
                    </P2>

                    <Button
                      displayBlock
                      onClick={() => handleRedirect('/menu-cliente')}
                      type="button"
                    >
                      Retornar ao menu
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </PageContainer>
        )}
      </>
      )}
    </PortalTemplate>
  )
}

export default Contracts
