import api from '~/config/api'
import { baseChargeIntegrations } from '~/config/basePath'
import NegotiationPortalFlowTypeEnum from '~/models/enums/NegotiationPortalFlowTypeEnum'
import SendEmailTypeEnum from '~/models/enums/SendEmailTypeEnum'

import SendBoletoEmailInterface from '~/models/interfaces/services/SendBoletoEmail'

const pathUrl = `${baseChargeIntegrations}/v1/negotiation-portal/boletos`

const sendBoletoEmail = (
  sendEmailTypeEnum: SendEmailTypeEnum,
  input: SendBoletoEmailInterface,
  cpfCnpj: string,
  eventDescription: string,
  negotiationPortalFlowTypeEnum: NegotiationPortalFlowTypeEnum,
  idEntryAutomation?: number,
): Promise<string> => {
  const params = {
    sendEmailTypeEnum,
    cpfCnpj,
    eventDescription,
    idEntryAutomation,
    negotiationPortalFlowTypeEnum,
  }

  return api
    .post<string>(
      `${pathUrl}/utils/send-boleto-by-email`, input, { params },
    ).then(({ data }) => data)
}

export { sendBoletoEmail }
