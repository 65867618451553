import styled from 'styled-components'
import { colors, sizes } from '~/assets/styles/variables'

export const PixModalFooter = styled.footer`
  background-color: ${colors.white};
  display: flex;
  justify-content: space-between;
  margin-bottom: -80px;
  padding: ${sizes.size16};
`
