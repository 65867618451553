import React from 'react'

import { colors, sizes } from '~/assets/styles/variables'

import { P3, P4 } from '~/components/atoms/Paragraph'

import { ContractItemInterface } from '~/models/interfaces/services/Contracts'

import { useFormats } from '~/utils/useFormats'

import {
  ContractItem, DateColumn, DescriptionColumn, ValueColumn,
} from './ListItem.styles'

const { darkGray } = colors
const { size0 } = sizes

interface ListItemInterface {
  item: ContractItemInterface
}

const ListItem = ({ item }: ListItemInterface) => {
  const { formatCurrency, formatDateDefault } = useFormats()

  return (
    <ContractItem>
      <DateColumn>
        <P4 color={darkGray}>
          Data
        </P4>
        <P3 marginBottom={size0}>
          {formatDateDefault(new Date(item.recordDate))}
        </P3>
      </DateColumn>
      <DescriptionColumn>
        <P4 color={darkGray}>
          Descrição
        </P4>
        <P3 marginBottom={size0}>
          {item.itemDescription}
        </P3>
      </DescriptionColumn>
      <ValueColumn>
        <P4 color={darkGray}>
          Valor
        </P4>
        <P3 marginBottom={size0}>
          {formatCurrency(item.itemValue)}
        </P3>
      </ValueColumn>
    </ContractItem>
  )
}

export default ListItem
