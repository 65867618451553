import { CampaignInterface } from '~/models/interfaces/services/Campaign'

export const initialState: CampaignInterface = {
  id: '',
  logo: '',
  campaignType: '',
  bannerCustomerMenu: '',
  bannerCustomerMenuMobile: '',
  bannerStartingPage: '',
  bannerStartingPageMobile: '',
  bannerFlow: '',
  bannerFlowMobile: '',
  primaryColor: '',
  ddd: '',
  telephone: '',
  dddWhatsapp: '',
  phoneWhatsapp: '',
  email: '',
}
