import React from 'react'

import { sizes } from '~/assets/styles/variables'
import { ProposalNegotiationPortalInterface } from '~/models/interfaces/services/Proposal'

import ProposalCard from '../ProposalCard'

import { Container, Items } from './FormModalOriginals.styles'
import ListItem from './ListItem'

const { size0 } = sizes

const validationSchema = null

interface FormModalDialogContractItemsFieldsInterface {
  formData: {
    values: ProposalNegotiationPortalInterface
  }
}

const FormModalOriginals = ({
  formData,
}: FormModalDialogContractItemsFieldsInterface) => {
  const proposal = formData.values

  return (
    <>
      <Container>
        <ProposalCard proposal={proposal} marginBottom={size0} />
      </Container>

      <Items>
        {proposal.origin.map((item) => (
          <ListItem
            item={item}
            key={`${item.contractNumber}`}
          />
        ))}
      </Items>
    </>
  )
}

export { FormModalOriginals, validationSchema }
