import styled from 'styled-components'

import { P2 } from '~/components/atoms/Paragraph'

export const SubtitleWrapper = styled.div`
  display: flex;
`

export const P2Subtitle = styled(P2)`
  flex: 1;
`
