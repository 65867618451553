import React from 'react'
import { useSelector } from 'react-redux'
import { Hidden } from 'react-grid-system'

import GoogleAnalyticsEventCategoryTypeEnum from '~/models/enums/GoogleAnalyticsEventCategoryTypeEnum'
import GoogleAnalyticsEventTypeEnum from '~/models/enums/GoogleAnalyticsEventTypeEnum'

import store from '~/store'
import { RootState } from '~/store/reducers'

import { useGoogleAnalytics } from '~/utils/useGoogleAnalytics'

import { Logo } from '~/components/atoms/Logo'
import { DevelopedByViAcerta } from '~/components/atoms/DevelopedByViAcerta'
import { Flex } from '~/components/atoms/Flex'

import { sizes } from '~/assets/styles/variables'
import {
  Figure,
  HeaderWrapper,
  LogoutButton,
  Title,
} from './Header.styles'

interface HeaderInterface {
  hasBackgroundColor?: boolean
  hasDevelopedByViAcerta?: boolean
}

const defaultProps = {
  hasBackgroundColor: false,
  hasDevelopedByViAcerta: false,
}

const { size28 } = sizes

const Header = (props: HeaderInterface) => {
  const { hasBackgroundColor, hasDevelopedByViAcerta } = props
  const { regEventGa } = useGoogleAnalytics()

  const [authSelector, campaignSelector] = useSelector(
    ({ auth, campaign }: RootState) => [auth, campaign] as const,
  )

  const { loggedIn } = authSelector

  function handleLogout() {
    regEventGa(
      GoogleAnalyticsEventCategoryTypeEnum.Autenticacao,
      GoogleAnalyticsEventTypeEnum.AutenticacaoSair,
      'Botão Header',
    )

    store.dispatch({ type: 'LOGOUT' })
  }

  return (
    <HeaderWrapper hasBackgroundColor={hasBackgroundColor}>
      <Flex gap={size28} flex={1}>
        <Figure>
          <Logo src={campaignSelector.logo} />
        </Figure>
        <Hidden xs>
          <Title>Portal de Negociação</Title>
        </Hidden>
      </Flex>
      <Flex gap={size28}>
        {hasDevelopedByViAcerta && (
          <DevelopedByViAcerta />
        )}
        {loggedIn && (
          <LogoutButton onClick={() => handleLogout()} title="Sair do Portal" type="button">
            Sair
          </LogoutButton>
        )}
      </Flex>
    </HeaderWrapper>
  )
}

Header.defaultProps = defaultProps

export default Header
