import React, { useEffect, useState } from 'react'

import { sizes } from '~/assets/styles/variables'
import useWindowDimensions from '~/utils/useWindowDimensions'

import Tag from '~/components/atoms/Tag'
import Button from '~/components/atoms/Button'
import FormControl from '~/components/molecules/FormControl'

import { RadioButtonValuesInterface } from '~/models/interfaces/components/FormControl'
import { ProposalNegotiationPortalInterface } from '~/models/interfaces/services/Proposal'

import {
  ProposalDataContainer,
  ClearSelectButtonRow,
  ConditionsListContainer,
} from './FormModalDialogConditionsFields.styles'
import ProposalCard from '../ProposalCard'
import ConditionListItem from './ConditionListItem'

const { size0, size16 } = sizes

interface FormModalDialogConditionsFieldsInterface {
  formData: any
}

interface FormModalValuesInterface {
  conditionId: string | null
  proposal: ProposalNegotiationPortalInterface
  isConditionSelectedOtherProposal: boolean
}

const FormModalDialogConditionsFields = ({
  formData,
}: FormModalDialogConditionsFieldsInterface) => {
  const { setFieldValue, values } = formData
  const { conditionId, proposal }: FormModalValuesInterface = values
  const { md } = useWindowDimensions()

  const [
    conditions,
    setConditions,
  ] = useState<RadioButtonValuesInterface[]>([])

  const handleSelected = (id: string | null) => {
    setFieldValue('conditionId', id)
    setFieldValue('proposalId', proposal.id)
  }

  const isFindConditionSelected = () => conditions.some((c) => c.id === conditionId)
  const getMsgConditionNotSelected = () => (!md ? 'Nenhuma selecionada' : 'Nenhuma condição selecionada')

  useEffect(() => {
    if (!proposal) return

    const availableNegotiations: RadioButtonValuesInterface[] = proposal.conditions.map((condition) => ({
      id: condition.id,
      label: <ConditionListItem
        key={condition.id}
        condition={condition}
        type={proposal.type}
      />,
    }))

    setConditions(availableNegotiations)
  }, [proposal])

  return (
    <>
      {proposal && (
        <>
          <ProposalDataContainer>
            <ProposalCard proposal={proposal} marginBottom={size0} />
          </ProposalDataContainer>
          <ConditionsListContainer>
            <ClearSelectButtonRow>
              <Tag small tagType={isFindConditionSelected() ? 'recommended' : 'disabled'}>
                {isFindConditionSelected() ? 'Condição selecionada' : getMsgConditionNotSelected()}
              </Tag>
              <Button
                disabled={!isFindConditionSelected()}
                onClick={() => handleSelected(null)}
                size="small"
                type="button"
              >
                Limpar seleção
              </Button>
            </ClearSelectButtonRow>
            <FormControl
              type="radio"
              marginBottom={size0}
              marginBottomItems={size16}
              name="conditionId"
              showError={false}
              textAlign="left"
              spanAlign
              onChange={(e) => handleSelected(e.target.value)}
              value={`${conditionId}`}
              valuesRadio={conditions}
            />
          </ConditionsListContainer>
        </>
      )}
    </>
  )
}

export { FormModalDialogConditionsFields }
