import { RequestHandlerInterface } from '~/models/interfaces/infraestructure/api'
import { RequestHandlerActionTypes } from './types'
import initialState from './initialState'

export function requestHandlerReducer(
  state = initialState,
  action: RequestHandlerActionTypes,
): RequestHandlerInterface {
  switch (action.type) {
    case 'NEW_REQUEST_HANDLER':
      return {
        amountRequest: +state.amountRequest + 1,
      }

    case 'REMOVE_REQUEST_HANDLER':
      return {
        amountRequest: +state.amountRequest - 1,
      }

    case 'SET_ERROR_REQUEST_HANDLER':
      return { ...initialState, error: action.payload }

    default:
      return state
  }
}
