import styled from 'styled-components'

import { colors, sizes } from '~/assets/styles/variables'

const { orange } = colors
const { size4, size8, size32 } = sizes

interface FormGroupInterface {
  marginBottom?: string
}

interface LabelInterface {
  margin?: string
}

export const FormGroup = styled.div`
  border: 0;
  margin-bottom: ${({ marginBottom }: FormGroupInterface) => (marginBottom || size32)};
  text-align: left;
`

export const RequiredSign = styled.span`
  color: ${orange};
  margin-left: ${size4};
`

export const RequiredText = styled.span`
  margin-left: ${size4};
  color: ${orange};
`

export const InlineFields = styled.div`
  display:flex;

  label {
    margin-top: ${size8};
    margin-right: ${({ margin }: LabelInterface) => (margin || '10px')};
  }
`
