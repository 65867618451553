import styled, { css } from 'styled-components'

import { colors, sizes } from '~/assets/styles/variables'

const { lightGray } = colors

const {
  size5, size8, size16, size20, size24, size25,
} = sizes

interface ContractCardInterface {
  marginBottom?: string
}

export const ContractType = styled.div`
  display: flex;
  margin-bottom: ${size24};
`

export const ExpirationValueRow = styled.div`
  display: flex;
  margin-bottom: ${({ marginBottom }: ContractCardInterface) => marginBottom};
`

const cssColumn = css`
  align-items: center;
  border: 1px solid ${lightGray};
  border-radius: ${size8};
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: ${size5};
  padding: ${size25} ${size20};
`

export const ExpirationColumn = styled.div`
  ${cssColumn}

  margin-right: ${size16};
  width: 34.5%;
`

export const ValueColumn = styled.div`
  ${cssColumn}

  flex: 1;
`
