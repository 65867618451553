import styled, { css } from 'styled-components'
import { stylingProps, StylingProps } from '~/assets/styles/stylingProps'

export type BannerImageProps = StylingProps

export const BannerImage = styled.img<BannerImageProps>`
  ${({ theme: { sizes } }) => css`
    ${stylingProps}
    border-radius: ${sizes.size20};
    object-fit: cover;
    width: 100%;
  `}
`
