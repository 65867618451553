/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { toast } from 'react-toastify'
import { colors } from '~/assets/styles/variables'
import {
  InsertEmoticonIcon, SentimentDissatisfiedIcon, CloseIcon, SentimentNeutralIcon,
} from '~/assets/icons'
import { ContainerMessage } from './Toast.styles'

interface MessageInterface {
  msg:string
}

const { lightGreen, red, orange } = colors

const CloseButton = ({ closeToast }: any) => <CloseIcon onClick={closeToast} />

const SuccessMessage:React.FC<MessageInterface> = ({ msg }: MessageInterface) => (
  <ContainerMessage>
    <div>
      <InsertEmoticonIcon color={lightGreen} size={35} />
    </div>
    {msg}
  </ContainerMessage>
)

const AlertMessage:React.FC<MessageInterface> = ({ msg }: MessageInterface) => (
  <ContainerMessage>
    <div>
      <SentimentNeutralIcon color={orange} size={35} />
    </div>
    {msg}
  </ContainerMessage>
)

const ErrorMessage:React.FC<MessageInterface> = ({ msg }: MessageInterface) => (
  <ContainerMessage>
    <div>
      <SentimentDissatisfiedIcon color={red} size={35} />
    </div>
    {msg}
  </ContainerMessage>
)

export default {
  success(msg:string) {
    return (
      toast.success(<SuccessMessage msg={msg} />, {
        closeButton: <CloseButton />,
        className: 'Toastify__toast--success',
        progressClassName: 'Toastify__progress-bar--success',
      })
    )
  },
  error(msg:string) {
    return toast.error(<ErrorMessage msg={msg} />, {
      closeButton: <CloseButton />,
      className: 'Toastify__toast--error',
      progressClassName: 'Toastify__progress-bar--error',
    })
  },
  alert(msg:string) {
    return toast.error(<AlertMessage msg={msg} />, {
      closeButton: <CloseButton />,
      className: 'Toastify__toast--alert',
      progressClassName: 'Toastify__progress-bar--alert',
    })
  },
}
