import { useLayoutEffect, useRef } from 'react'

import { CampaignService } from '~/services/Campaign'
import store from '~/store'
import { useQuery } from './useQuery'

const campaignKey = '@portalViAcerta/campaign'

const getCampaign = async (campaignSlug: string) => {
  const campaign = await CampaignService.get(campaignSlug)
  store.dispatch({ type: '@campaign/SET_CAMPAIGN', payload: campaign })
  store.dispatch({ type: '@theme/SET_PRIMARY_COLOR', payload: campaign.primaryColor })
}

export const useCampaign = () => {
  const query = useQuery()
  const alreadyFetched = useRef(false)

  useLayoutEffect(() => {
    const fetchCampaign = async () => {
      alreadyFetched.current = true
      const campaignSlug = query.get('campanha')

      if (campaignSlug) {
        if (campaignSlug === 'padrao') {
          sessionStorage.removeItem(campaignKey)
          store.dispatch({ type: '@campaign/CLEAR_CAMPAIGN' })
          return
        }

        sessionStorage.setItem(campaignKey, campaignSlug)
        getCampaign(campaignSlug)
        return
      }

      const cachedCampaign = sessionStorage.getItem(campaignKey)
      if (cachedCampaign) getCampaign(cachedCampaign)
    }

    if (!alreadyFetched.current) fetchCampaign()
  }, [query])
}
