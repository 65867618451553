import styled, { keyframes } from 'styled-components'
import * as Accordion from '@radix-ui/react-accordion'

import ButtonTypeEnum from '~/models/enums/ButtonTypeEnum'
import ButtonAtom from '~/components/atoms/Button'

import { fontSizes, sizes } from '~/assets/styles/variables'

import { device } from '~/config/device'

const { fontSize12 } = fontSizes
const {
  size10, size16, size20, size240,
} = sizes

const openAnimation = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-accordion-content-height)' },
})

const closeAnimation = keyframes({
  from: { height: 'var(--radix-accordion-content-height)' },
  to: { height: 0 },
})

export const AccordionContent = styled(Accordion.Content)`
  overflow: hidden;

  &[data-state='open'] {
    animation: ${openAnimation} 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }
  &[data-state='closed'] {
    animation: ${closeAnimation} 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }
`

export const ContractActionsRow = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  @media ${device.md} {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
`

export const ContractButtonsColumn = styled.div`
  display: flex;
  justify-content: space-between;

  > button + button {
    margin-left: ${size16};
  }
`

export const AccordionHeader = styled(Accordion.Header)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-block: ${size20} ${size10};

  h4 {
    margin-bottom: 0;
  }

  button {
    margin-bottom: 0;
  }

  @media ${device.xs} {
    h4 {
      max-width: ${size240};
    }
  }
`

export const AccordionButton = styled(ButtonAtom)`
  font-size: ${fontSize12};
  height: fit-content;
  padding: 0rem;
  width: 80px;

  &[data-state='open']::after {
    content: 'Ocultar';
  }
  &[data-state='closed']::after {
    content: 'Exibir';
  }
`
AccordionButton.defaultProps = {
  styledButton: ButtonTypeEnum.Outline,
  size: 'small',
}
