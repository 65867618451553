import React from 'react'

import { P1ExtraBold } from '~/components/atoms/Paragraph'

interface TimerInterface {
  seconds: number
}

const paddingLeft = (input:number) => `${input}`.padStart(2, '0')

const Timer = ({ seconds }: TimerInterface) => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor(seconds / 60) % 60
  const secs = seconds % 60

  return (
    <P1ExtraBold>
      {hours > 0 && (`${paddingLeft(hours)}h`)}
      {minutes > 0 && (`${paddingLeft(minutes)}m`)}
      {`${paddingLeft(secs)}s`}
    </P1ExtraBold>
  )
}

export default Timer
