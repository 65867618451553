import AuthEnum from '~/models/enums/AuthEnum'
import { AuthInterface, UserInterface } from '~/models/interfaces/services/Auth'

const token: string | null = localStorage.getItem(AuthEnum.TOKEN)

const user: UserInterface | null = (
  localStorage.getItem(AuthEnum.USER)
    ? JSON.parse(localStorage.getItem(AuthEnum.USER) || '')
    : null
) || null

const initialState: AuthInterface = {
  loggedIn: !!(user && token),
  token,
  user,
  idEntryAutomation: undefined,
}

export default initialState
