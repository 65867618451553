import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { darken } from 'polished'

import { checkIsWhite } from '~/assets/styles/helpers'
import { colors, fontSizes, sizes } from '~/assets/styles/variables'
import ButtonInterface from '~/models/interfaces/components/Button'

const {
  blue,
  darkGray,
  disabledBlue,
  focusBlue,
  focusOrange,
  lightGray,
  orange,
  white,
  textBlack,
} = colors

const { fontSize14, fontSize16 } = fontSizes

const {
  size6, size8, size10, size12, size15, size16, size20, size32, size100Percent,
} = sizes

const defaultButton = css`
  ${({ theme: { palette } }) => {
    const primaryMainColor = checkIsWhite(palette.primary.main) ? blue : palette.primary.main
    const darkenPrimaryMainColor = darken(0.12, primaryMainColor)

    return css`
      background-color: ${primaryMainColor};
      border-color: ${primaryMainColor};

      &:focus-visible,
      &:hover {
        background-color: ${darkenPrimaryMainColor};
        border-color: ${darkenPrimaryMainColor};
      }
    `
  }};

  align-items: center;
  border-radius: ${size8};
  border: 2px solid;
  color: ${white};
  cursor: pointer;
  display: flex;
  font-family: Raleway, sans-serif;
  font-size: ${fontSize16};
  font-style: normal;
  font-weight: 800;
  justify-content: center;
  line-height: 1.5;
  padding: ${size10} ${size20};
  text-decoration: none !important;
  text-transform: uppercase;
  transition: background-color 0.25s, border-color 0.25s, color 0.25s;
  vertical-align: baseline;
  width: ${({ displayBlock }: ButtonInterface) => (displayBlock ? size100Percent : 'auto')};

  ${({ disabled }: ButtonInterface) => disabled && css`
      background-color: ${lightGray} !important;
      border-color: ${lightGray} !important;
      color: ${disabledBlue};
      cursor: default;
  `}

  ${({ size }: ButtonInterface) => size === 'small' && css`
      font-size: ${fontSize14};
      line-height: 1.4;
      padding: ${size6} ${size16};
  `}

  ${({ size }: ButtonInterface) => size === 'large' && css`
      padding: ${size15} ${size16};
  `}

  svg {
    color: inherit;
    margin: ${({ iconSide }: ButtonInterface) => (iconSide === 'start' ? `0 ${size10} 0 0` : `0 0 0 ${size10}`)};
  }
`

export const Button = styled.button`
  ${defaultButton};
`

export const ButtonOutline = styled.button<ButtonInterface>`
  ${defaultButton};

  background: transparent !important;
  
  ${({ disabled, theme: { palette } }) => {
    const primaryMainColor = checkIsWhite(palette.primary.main) ? blue : palette.primary.main
    const darkenPrimaryMainColor = darken(0.12, primaryMainColor)

    return css`
      border-color: ${disabled ? `${disabledBlue} !important` : primaryMainColor};
      color: ${disabled ? `${disabledBlue} !important` : primaryMainColor};
      
      &:focus,
      &:hover {
        border-color: ${darkenPrimaryMainColor};
        color: ${darkenPrimaryMainColor};
      }
    `
  }}
`

export const ButtonHighlight = styled.button`
  ${defaultButton};

  background-color: ${orange};
  border-color: ${orange};

  &:focus, &:hover {
    background-color: ${focusOrange};
    border-color: ${focusOrange};
  }
`

export const ButtonWhite = styled.button`
  ${defaultButton};

  background-color: ${white};
  border-color: ${white};
  color: ${textBlack};

  &:focus,
  &:hover {
    background-color: ${lightGray};
    border-color: ${lightGray};
  }
`

export const ButtonIcon = styled.button`
  background-color: transparent;
  border: none;
  color: ${darkGray};
  cursor: pointer;
  display: inline-block;
  transition: color 0.25s;

  &:focus, &:hover {
    color: ${focusBlue};
  }

  svg {
    display: block;

    ${({ size }: ButtonInterface) => size === 'small' && css`
        height: ${size12};
        width: ${size12};
    `}

    ${({ size }: ButtonInterface) => size === 'large' && css`
        height: ${size32};
        width: ${size32};
    `}
  }
`

export const LinkButton = Button.withComponent(Link)

export const LinkButtonOutline = ButtonOutline.withComponent(Link)

export const LinkButtonHighlight = ButtonHighlight.withComponent(Link)
