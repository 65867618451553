import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import PageLoader from '~/components/organisms/PageLoader'
import Toast from '~/components/atoms/Toast'

import AuthEnum from '~/models/enums/AuthEnum'

import store from '~/store'
import { RootState } from '~/store/reducers'

const ErrorHandler = () => {
  const history = useHistory()

  const requestHandlerSelector = useSelector(
    ({ requestHandler }: RootState) => requestHandler,
  )

  if (requestHandlerSelector.error) {
    const { error } = requestHandlerSelector

    if (!error.response) {
      Toast.error('Ocorreu um erro inesperado.')
    } else if (!error.response.config.hasBeenCalled) {
      error.response.config.hasBeenCalled = true

      if (error.response && error.response.status === 401) {
        const token = localStorage.getItem(AuthEnum.TOKEN)

        if (token) {
          Toast.error('Sua sessão expirou!')
        }

        if (error.response.data.userMessage) {
          Toast.error(error.response.data.userMessage)
        }

        store.dispatch({ type: 'LOGOUT' })
        store.dispatch({ type: 'SET_INVISIBLE_FORM_MODAL_DIALOG' })
        store.dispatch({ type: 'SET_INVISIBLE_MODAL_DIALOG' })

        history.push('/')
      } else if (error.response.data.userMessage) {
        Toast.error(error.response.data.userMessage)
      } else {
        Toast.error('Ocorreu um erro inesperado.')
      }
    }
  }

  return (
    <>
      {+requestHandlerSelector.amountRequest > 0 && <PageLoader /> }
    </>
  )
}

export default ErrorHandler
