import React from 'react'
import { useSelector } from 'react-redux'

import { H5 } from '~/components/atoms/Heading'
import { P3 } from '~/components/atoms/Paragraph'
import ContactMethods from '~/components/organisms/ContactMethods'
import Header from '~/components/organisms/Header'

import {
  ContentWrapper,
  DescriptionWrapper,
  Footer,
  PageContainer,
  PageContent,
  Questions,
} from './IdentificationTemplate.styles'

import { sizes } from '~/assets/styles/variables'

import setTitle from '~/config/title'
import { RootState } from '~/store/reducers'
import { CampaignType } from '~/models/enums/Campaign'
import { breakpoint } from '~/config/device'

const { size24 } = sizes

const IdentificationTemplate = ({ children }: any) => {
  setTitle('Login')
  const { campaignType, bannerStartingPage, bannerStartingPageMobile } = useSelector(
    (state: RootState) => state.campaign,
  )

  return (
    <>
      <Header />

      <PageContainer>
        <PageContent>
          <ContentWrapper>{children}</ContentWrapper>
        </PageContent>

        <Footer>
          <ContentWrapper>
            {[CampaignType.Charge, CampaignType.Both].includes(campaignType as CampaignType) ? (
              <picture>
                <source srcSet={bannerStartingPageMobile} media={`(max-width: ${breakpoint.sm}px)`} />
                <img src={bannerStartingPage} alt="Banner da campanha" />
              </picture>
            ) : (
              <DescriptionWrapper>
                <H5 as="h2" marginBottom={size24}>
                  No Portal de Negociação VIACERTA, você tem a oportunidade de:
                </H5>
                <ul>
                  <li>Renegociar empréstimos com ótimas condições de parcelamento;</li>
                  <li>Melhorar seu score de crédito ao quitar parcelas e faturas atrasadas;</li>
                  <li>Atualizar um boleto para antecipação do pagamento.</li>
                </ul>
                <P3>Claro, tudo isso com descontos especiais!</P3>
              </DescriptionWrapper>
            )}

            <Questions>Dúvidas? Entre em contato:</Questions>

            <ContactMethods background="dark" columnSize={4} />
          </ContentWrapper>
        </Footer>
      </PageContainer>
    </>
  )
}

export default IdentificationTemplate
