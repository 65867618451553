/* eslint-disable max-len */
import api from '~/config/api'
import { baseChargeIntegrations } from '~/config/basePath'

import { ContractDischargeInterface, HasContractsInterface } from '~/models/interfaces/services/Contracts'
import { DealOutputInterface, DealSimulationCalculationOutputInterface } from '~/models/interfaces/services/Customers'

const pathUrl = `${baseChargeIntegrations}/v1/negotiation-portal/customers`

const hasContracts = (customerId: string, idEntryAutomation?: number): Promise<HasContractsInterface> => {
  const params = {
    idEntryAutomation,
  }
  return api
    .get<HasContractsInterface>(`${pathUrl}/${customerId}/has-contracts`, { params })
    .then(({ data }) => data)
}

const getContractsToDischarge = (id: string, cpfCnpj: string, idEntryAutomation?: number):
Promise<ContractDischargeInterface[]> => {
  const params = {
    cpfCnpj,
    idEntryAutomation,
  }
  return api
    .get<ContractDischargeInterface[]>(`${pathUrl}/${id}/dischard-simulations`, { params })
    .then(({ data }) => data)
}

const getDischargeSimulationByCustomerAndInstallment = (
  id: string,
  installmentExternalCodes: string[],
  cpfCnpj: string,
  eventDescription: string,
  idEntryAutomation?: number,
): Promise<DealSimulationCalculationOutputInterface> => {
  const params = { idEntryAutomation }
  return api
    .get<DealSimulationCalculationOutputInterface>(
      `${pathUrl}/${id}/installments-simulations`
      + `?installmentExternalCodes=${installmentExternalCodes}`
      + `&cpfCnpj=${cpfCnpj}`
      + `&eventDescription=${eventDescription}`, { params },
    )
    .then(({ data }) => data)
}

const getDischargeSimulationByCustomerAndInstallmentCalculation = (
  dueDate: string,
  id: string,
  installmentExternalCodes: string[],
  issueDate: string,
  cpfCnpj: string,
  eventDescription: string,
  idEntryAutomation?: number,
): Promise<DealSimulationCalculationOutputInterface> => {
  const params = { idEntryAutomation }

  return api
    .get<DealSimulationCalculationOutputInterface>(
      `${pathUrl}/${id}/installments-simulations/calculation`
      + `?dueDate=${dueDate}`
      + `&installmentExternalCodes=${installmentExternalCodes}`
      + `&issueDate=${issueDate}`
      + `&cpfCnpj=${cpfCnpj}`
      + `&eventDescription=${eventDescription}`, { params },
    )
    .then(({ data }) => data)
}

const effectuationDischargeByCustomerAndInstallment = (editData: {
  dueDate: string
  id: string
  installmentExternalCodes: string[]
  issueDate: string
  idEntryAutomation?: number
  paymentTypeId?: string
}): Promise<DealOutputInterface> => {
  const {
    dueDate, id, installmentExternalCodes, issueDate, idEntryAutomation, paymentTypeId,
  } = editData
  const params = {
    dueDate,
    issueDate,
    idEntryAutomation,
    paymentTypeId,
  }

  return api
    .put<DealOutputInterface>(`${pathUrl}/${id}/installments-effectuation`
  + `?installmentExternalCodes=${installmentExternalCodes}`, null, { params })
    .then(({ data }) => data)
}

export {
  hasContracts,
  getContractsToDischarge,
  getDischargeSimulationByCustomerAndInstallment,
  getDischargeSimulationByCustomerAndInstallmentCalculation,
  effectuationDischargeByCustomerAndInstallment,
}
