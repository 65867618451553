import React from 'react'

import EffectuationError from '~/components/organisms/EffectuationError'

import { WizardInputInterface } from '~/models/interfaces/components/Wizard'

const Error = () => {
  const wizardElements: WizardInputInterface[] = [
    {
      title: 'Negociações disponíveis',
    },
    {
      title: 'Revisão de contratos',
    },
    {
      title: 'Valor da entrada e datas de vencimento',
    },
    {
      title: 'Geração de boleto',
      active: true,
    },
  ]

  return (
    <EffectuationError
      title="Erro - Quitação"
      wizardElements={wizardElements}
    />
  )
}

export default Error
