import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Hidden, Row } from 'react-grid-system'
import { useHistory } from 'react-router-dom'
import { Form, Formik } from 'formik'
import * as yup from 'yup'

import { colors, sizes } from '~/assets/styles/variables'

import { CampaignBanner } from '~/components/atoms/Banner/common'
import Span from '~/components/atoms/Span'
import Button from '~/components/atoms/Button'
import { H4 } from '~/components/atoms/Heading'
import { P2 } from '~/components/atoms/Paragraph'
import { Card, CardBody } from '~/components/atoms/Card'
import PageContainer from '~/components/atoms/PageContainer'
import ActionBar from '~/components/molecules/ActionBar'
import Wizard from '~/components/molecules/Wizard'
import PortalTemplate from '~/components/templates/PortalTemplate'

import { pageWidth } from '~/config/layout/pageWidth'
import setTitle from '~/config/title'

import * as ProposalService from '~/services/Proposal'

import { ProposalInputInterface, ProposalNegotiationPortalInterface } from '~/models/interfaces/services/Proposal'

import ProposalTypeEnum from '~/models/enums/ProposalTypeEnum'
import ModalDialogTypeEnum from '~/models/enums/ModalDialogTypeEnum'

import store from '~/store'
import { RootState } from '~/store/reducers'

import { useUniqueId } from '~/utils/useUniqueId'
import useWindowDimensions from '~/utils/useWindowDimensions'

import getWizard from '../utils/wizard'
import ProposalCard from './ProposalCard'
import { FormModalDialogConditionsFields } from './FormModalDialogConditionsFields'
import { ContractActionsRow, ContractButtonsColumn, ContractsWrapper } from './Proposal.styles'
import ButtonTypeEnum from '~/models/enums/ButtonTypeEnum'
import { FormModalOriginals } from './FormModalOriginals/FormModalOriginals'

const { darkGray, focusBlue, orange } = colors
const { size0, size8 } = sizes

const Proposal = () => {
  setTitle('Propostas')

  const history = useHistory()
  const { md } = useWindowDimensions()
  const { generateUniqueStringId } = useUniqueId()
  const wizardElements = getWizard()

  const authSelector = useSelector(
    ({ auth }: RootState) => auth,
  )

  const { user } = authSelector

  const [userDataSelector] = useSelector(
    ({ userDataProcessing }: RootState) => [userDataProcessing] as const,
  )

  const { idEntryAutomation } = userDataSelector

  const containerPageWidth = pageWidth.medium
  const initialValues: ProposalInputInterface = { conditionId: null, proposalId: null }

  const [
    proposals,
    setProposals,
  ] = useState<ProposalNegotiationPortalInterface[]>([])
  const [
    isLoading,
    setIsLoading,
  ] = useState(false)

  const validationSchema: yup.SchemaOf<ProposalInputInterface> = yup.object({
    proposalId: yup.string().required(''),
    conditionId: yup.string().required(''),
  })

  const handleSubmit = (values: ProposalInputInterface) => {
    ProposalService.activate(values.proposalId as string, values.conditionId as string, idEntryAutomation)
      .then((response) => {
        history.push('/propostas/geracao-boletos', { deal: response })
      })
  }

  const showModalConditionConfirmed = (formValues: any, setFieldValue: any) => {
    const handleConditionConfirmed = () => {
      setFieldValue('conditionId', formValues.conditionId)
      setFieldValue('proposalId', formValues.proposalId)
      store.dispatch({ type: 'SET_INVISIBLE_FORM_MODAL_DIALOG' })
    }

    if (formValues.isConditionSelectedOtherProposal && !formValues.conditionId) return true

    if (formValues.isConditionSelectedOtherProposal) {
      store.dispatch({
        type: 'SET_MODAL_DIALOG_INFO',
        payload: {
          cancelButtonText: 'Não, manter',
          confirmButtonText: 'Sim, alterar',
          Children: () => (
            <P2>
              Você já
              {' '}
              <Span color={orange}>
                selecionou
              </Span>
              {' '}
              outra condição, deseja realmente alterar?
            </P2>
          ),
          handleCancel: null,
          handleConfirm: handleConditionConfirmed,
          title: 'Deseja realmente alterar a condição selecionada?',
          type: ModalDialogTypeEnum.Warning,
        },
      })
      store.dispatch({ type: 'SET_VISIBLE_MODAL_DIALOG' })
      return false
    }

    handleConditionConfirmed()
    return true
  }

  const showModalOriginals = (
    proposal: ProposalNegotiationPortalInterface,
    modalTitle: string,
  ) => {
    store.dispatch({
      type: 'SET_FORM_MODAL_DIALOG_INFO',
      payload: {
        FormFields: FormModalOriginals,
        initialValues: proposal,
        handleCancel: null,
        handleConfirm: null,
        title: modalTitle,
        validationSchema: null,
      },
    })
    store.dispatch({ type: 'SET_VISIBLE_FORM_MODAL_DIALOG' })
  }

  const showModalConditions = (
    proposal: ProposalNegotiationPortalInterface,
    values: ProposalInputInterface,
    setFieldValue: any,
  ) => {
    let isConditionSelectedOtherProposal = false
    if (values.conditionId) isConditionSelectedOtherProposal = !proposal.conditions.some((c) => c.id === values.conditionId)

    store.dispatch({
      type: 'SET_FORM_MODAL_DIALOG_INFO',
      payload: {
        FormFields: FormModalDialogConditionsFields,
        initialValues: {
          proposal,
          proposalId: isConditionSelectedOtherProposal ? null : values.proposalId,
          conditionId: isConditionSelectedOtherProposal ? null : values.conditionId,
          isConditionSelectedOtherProposal,
        },
        handleCancel: null,
        handleConfirm: (formValues: any) => showModalConditionConfirmed(formValues, setFieldValue),
        title: 'Selecionar Condição',
        validationSchema: null,
      },
    })
    store.dispatch({ type: 'SET_VISIBLE_FORM_MODAL_DIALOG' })
  }

  const handleRedirect = (page: string) => history.push(page, idEntryAutomation)

  useEffect(() => {
    if (!user) return

    ProposalService.getProposalByCustomer(user.id, idEntryAutomation)
      .then((response) => setProposals(response))
      .then(() => setIsLoading(true))
  }, [idEntryAutomation, user])

  return (
    <PortalTemplate wizard={!!proposals.length}>

      {isLoading && (
        proposals.length ? (
          <>
            <Wizard>{wizardElements}</Wizard>

            <PageContainer width={containerPageWidth}>
              <Row>
                <Col xs={12}>
                  <CampaignBanner />
                </Col>
                <Col lg={10}>
                  <H4>
                    Selecione abaixo a melhor condição entre as propostas disponíveis para você:
                  </H4>
                </Col>
              </Row>

              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validateOnMount
                validateOnChange
                validationSchema={validationSchema}
              >
                {({
                  isValid, values, setFieldValue,
                }) => (
                  <Form>
                    <ContractsWrapper>
                      {proposals.map((proposal) => (
                        <Card key={generateUniqueStringId()} negative>
                          <CardBody>
                            <ProposalCard proposal={proposal} />
                            {proposal.type === ProposalTypeEnum.Renegotiation ? (
                              <ContractActionsRow>
                                <>
                                  {proposal.conditions.filter((c) => c.id === values.conditionId).length ? (
                                    <P2 color={focusBlue} marginBottom={md ? size0 : size8}>
                                      Condição selecionada
                                    </P2>
                                  ) : (
                                    <P2 color={darkGray} marginBottom={md ? size0 : size8}>
                                      Nenhuma condição selecionada
                                    </P2>
                                  )}
                                </>
                                <ContractButtonsColumn>
                                  <Button
                                    size="small"
                                    onClick={() => showModalOriginals(proposal, 'Dívidas da proposta')}
                                    styledButton={ButtonTypeEnum.Outline}
                                  >
                                    <Hidden xs>
                                      Ver
                                      {' '}
                                    </Hidden>
                                    originais
                                  </Button>

                                  <Button
                                    displayBlock={!md}
                                    onClick={() => showModalConditions(proposal, values, setFieldValue)}
                                    size="small"
                                  >
                                    Ver condições
                                  </Button>
                                </ContractButtonsColumn>
                              </ContractActionsRow>
                            ) : (
                              <ContractActionsRow>
                                <>
                                  {proposal.conditions.filter((c) => c.id === values.conditionId).length ? (
                                    <P2 color={focusBlue} marginBottom={md ? size0 : size8}>
                                      Condição selecionada
                                    </P2>
                                  ) : (
                                    <P2 color={darkGray} marginBottom={md ? size0 : size8}>
                                      Nenhuma condição selecionada
                                    </P2>
                                  )}
                                </>
                                <ContractButtonsColumn>
                                  <Button
                                    size="small"
                                    onClick={() => showModalOriginals(proposal, 'Dívidas da proposta')}
                                    styledButton={ButtonTypeEnum.Outline}
                                  >
                                    <Hidden xs>
                                      Ver
                                      {' '}
                                    </Hidden>
                                    originais
                                  </Button>

                                  <Button
                                    displayBlock={!md}
                                    onClick={() => showModalConditions(proposal, values, setFieldValue)}
                                    size="small"
                                  >
                                    Ver condições
                                  </Button>
                                </ContractButtonsColumn>
                              </ContractActionsRow>
                            )}
                          </CardBody>
                        </Card>
                      ))}
                    </ContractsWrapper>

                    <p>{isValid}</p>
                    <ActionBar
                      returnAction={() => handleRedirect('/menu-cliente')}
                      submitActive={isValid}
                      nextButtonText="Ativar proposta"
                      width={containerPageWidth}
                    />
                  </Form>
                )}
              </Formik>
            </PageContainer>
          </>
        ) : (
          <PageContainer textAlign="center" width={pageWidth.small}>
            <H4>
              No momento, não há propostas prontas disponíveis para você.
            </H4>

            <Row justify="center">
              <Col xs={11} sm={7}>
                <Card marginBottom={size0} negative>
                  <CardBody>
                    <P2 color={darkGray}>
                      Acesso ao menu para escolher as opções de auto negociação ou entrar em contato conosco.
                    </P2>

                    <Button
                      displayBlock
                      onClick={() => handleRedirect('/menu-cliente')}
                      type="button"
                    >
                      Acessar o menu
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </PageContainer>
        )
      )}
    </PortalTemplate>
  )
}

export default Proposal
