import React from 'react'
import { Logo } from '../Logo'
import { Wrapper } from './styles'

export const DevelopedByViAcerta = () => (
  <Wrapper>
    <span>Um parceiro</span>
    <Logo />
  </Wrapper>
)
