import React from 'react'

import { sizes } from '~/assets/styles/variables'

import { CheckMark, Container, Title } from './RadioButton.styles'

const { size32 } = sizes

interface RadioButtonInterface {
  disabled?: boolean
  id: string
  label: string | React.ReactNode
  last?: boolean
  marginBottomItems?: string
  name: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  textAlign: string
  title?: string
  value: string
  spanAlign?: boolean
}

const defaultProps = {
  disabled: false,
  last: false,
  marginBottomItems: size32,
  title: '',
  spanAlign: false,
}

const RadioButton = ({
  title,
  label,
  last,
  marginBottomItems,
  name,
  id,
  value,
  disabled,
  onChange,
  textAlign,
  spanAlign,
  ...props
}: RadioButtonInterface) => (
  <>
    {title && <Title>{title}</Title>}
    <Container
      checked={id === value}
      disabled={disabled}
      last={last}
      marginBottom={marginBottomItems}
      textAlign={textAlign}
    >
      <input
        type="radio"
        id={id}
        name={name}
        checked={id === value}
        value={id}
        disabled={disabled}
        onChange={onChange}
        {...props}
      />
      {label}
      <CheckMark disabled={disabled} spanAlign={spanAlign} />
    </Container>
  </>
)

RadioButton.defaultProps = defaultProps

export default RadioButton
