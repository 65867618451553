enum GoogleAnalyticsEventTypeEnum {
  AutenticacaoEmail = 'AutenticacaoEmail',
  AutenticacaoSms = 'AutenticacaoSms',
  AutenticacaoEmailReenvio = 'AutenticacaoEmailReenvio',
  AutenticacaoSmsReenvio = 'AutenticacaoSmsReenvio',
  AutenticacaoSair = 'Logout',

  MenuClienteVoltar = 'MenuClienteVoltar',

  SegundaViaBoletoImprimirPdf = 'SegundaViaBoletoImprimirPdf',
  SegundaViaBoletoEnvioEmail = 'SegundaViaBoletoEnvioEmail',

  QuitacaoRevisar = 'QuitacaoRevisar',
  QuitacaoCalcularDesconto = 'QuitacaoCalcularDesconto',
  QuitacaoAlterarItensSelecionados = 'QuitacaoAlterarItensSelecionados',
  QuitacaoEfetivar = 'QuitacaoEfetivar',
  QuitacaoCopiarLinhaDigitavel = 'QuitacaoCopiarLinhaDigitavel',
  QuitacaoImprimirPdf = 'QuitacaoImprimirPdf',
  QuitacaoEnvioEmail = 'QuitacaoEnvioEmail',
  QuitacaoFazerNovaQuitacao = 'QuitacaoFazerNovaQuitacao',
  QuitacaoEncerrarQuitacao = 'SegundaViaBoletoEnvioEmail',

  RenegociacaoSimular = 'RenegociacaoSimular',
  RenegociacaoCalcular = 'RenegociacaoCalcular',
  RenegociacaoEfetivar = 'RenegociacaoEfetivar',
  RenegociacaoCopiarLinhaDigitavel = 'RenegociacaoCopiarLinhaDigitavel',
  RenegociacaoImprimirPdf = 'RenegociacaoImprimirPdf',
  RenegociacaoEnvioEmail = 'RenegociacaoEnvioEmail',
  RenegociacaoFazerNovaNegociacao = 'RenegociacaoFazerNovaNegociacao',
  RenegociacaoEncerrarNegociacao = 'RenegociacaoEncerrarNegociacao',

  FormasContatoWhatsapp = 'FormasContatoWhatsapp',
  FormasContatoTelefone = 'FormasContatoTelefone',
  FormasContatoEmail = 'FormasContatoEmail',

  PropostaCopiarLinhaDigitavel = 'PropostaCopiarLinhaDigitavel',
  PropostaImprimirPdf = 'PropostaImprimirPdf',
  PropostaEnvioEmail = 'PropostaEnvioEmail',
}

export default GoogleAnalyticsEventTypeEnum
