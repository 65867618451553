/* eslint-disable quotes */
/* eslint-disable react/jsx-curly-brace-presence */
import { useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import {
  InstructionsWrapper,
  Ol,
  FlexBox,
  QrCodeWrapper,
  TitleWrapper,
  RedirectButtonsRow,
} from './styles'

import { pixService } from '~/services/Pix'
import { RootState } from '~/store/reducers'
import { getDataPix } from '~/services/Deals'
import { Flex } from '~/components/atoms/Flex'
import { CheckCircleIcon } from '~/assets/icons'
import { colors } from '~/assets/styles/variables'
import { pageWidth } from '~/config/layout/pageWidth'
import { P1, P2, P3 } from '~/components/atoms/Paragraph'
import { CopyButton } from '~/components/atoms/CopyButton'
import { WizardInputInterface } from '~/models/interfaces/components/Wizard'
import { DealOutputInterface } from '~/models/interfaces/services/Customers'
import {
  DealPendingsInterface,
  PendingInstallmentInterface,
} from '~/models/interfaces/services/Deals'
import store from '~/store'
import Button from '~/components/atoms/Button'
import Wizard from '~/components/molecules/Wizard'
import PageLoader from '~/components/organisms/PageLoader'
import ButtonTypeEnum from '~/models/enums/ButtonTypeEnum'
import PageContainer from '~/components/atoms/PageContainer'
import PortalTemplate from '~/components/templates/PortalTemplate'

interface StateInterface {
  deal: DealOutputInterface
}

const PixGeneration = () => {
  const history = useHistory()

  const { state }: { state: StateInterface } = useLocation()

  const authSelector = useSelector(({ auth }: RootState) => auth)

  const { user } = authSelector

  const customerId = JSON.parse(localStorage.getItem('__viacertaNegotiationPortalUser__') || '').id

  const [loading, setLoading] = useState(true)
  const [qrCode, setQrCode] = useState<string>()
  const [deal, setDeal] = useState<DealOutputInterface>()
  const [dataPix, setDataPix] = useState<PendingInstallmentInterface['pixs'][0]>()

  const containerPageWidth = pageWidth.medium

  const wizardElements: WizardInputInterface[] = [
    {
      title: 'Seleção de contratos',
    },
    {
      title: 'Efetivação',
    },
    {
      title: 'Geração de pix',
      active: true,
    },
  ]

  useEffect(() => {
    if (!user || !state || !state.deal || deal) return

    store.dispatch({
      type: 'SET_INITIAL_DISCHARGE_CONTRACTS',
    })

    setDeal(state.deal)
  }, [deal, state, user])

  useEffect(() => {
    if (!deal) return

    setTimeout(() => {
      setLoading(false)

      getDataPix(customerId).then((data) => {
        setDataPix(
          data.filter((elem: DealPendingsInterface) => elem.externalCode === deal.externalCode)[0]
            .installments[0].pixs[0]
        )
      })
    }, 2000)
  }, [deal, customerId])

  useEffect(() => {
    const handleGetQrCode = async () => {
      if (dataPix) setQrCode(await pixService.getQRCode(dataPix.id))
    }

    handleGetQrCode()
  }, [dataPix])

  return (
    <PortalTemplate>
      {loading && <PageLoader />}

      {dataPix && qrCode && (
        <>
          <Wizard>{wizardElements}</Wizard>

          <PageContainer width={containerPageWidth}>
            <TitleWrapper>
              <CheckCircleIcon color={colors.green} size={33} style={{ margin: '0 16px 0 0' }} />

              <P1 margin="0" color={colors.blue} style={{ lineHeight: '40px', fontWeight: 700 }}>
                Negociação gerada com sucesso!
              </P1>
            </TitleWrapper>

            <FlexBox>
              <QrCodeWrapper>
                <P2 margin="0 0 16px 0" color={colors.textBlack} style={{ lineHeight: '22.4px' }}>
                  {`Efetue o pagamento até o dia ${new Date(
                    dataPix?.dueDate as string
                  ).toLocaleDateString('pt-BR')}`}
                </P2>

                <img
                  src={`data:image/jpeg;base64,${qrCode}`}
                  alt="QR Code"
                  title="QR Code"
                  width={200}
                />

                <P1
                  margin="8px 0 16px 0"
                  color={colors.textBlack}
                  style={{ fontSize: '24px', lineHeight: '33.6px' }}
                >
                  {'Valor: '}
                  <strong>
                    {dataPix?.value.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </strong>
                </P1>

                <Flex
                  margin="0 0 16px 0"
                  style={{
                    width: '100%',
                    padding: '16px',
                    backgroundColor: colors.white,
                    borderRadius: '8px',
                  }}
                >
                  <P3
                    className="copyQrCodeData"
                    margin={0}
                    color={colors.blue}
                    style={{
                      lineHeight: '21px',
                    }}
                  >
                    <strong>{dataPix?.qrCodeData}</strong>
                  </P3>
                </Flex>

                <CopyButton value={dataPix?.qrCodeData} style={{ width: '100%' }}>
                  Copiar código pix
                </CopyButton>
              </QrCodeWrapper>

              <InstructionsWrapper>
                <P2 margin="0" color={colors.textBlack} style={{ lineHeight: '22.4px' }}>
                  <strong>Instruções para fazer o pagamento por Pix:</strong>
                </P2>

                <Ol>
                  <li>
                    <P2 margin="0" style={{ lineHeight: '22.4px' }}>
                      Abra o aplicativo do seu banco no celular.
                    </P2>
                  </li>
                  <li>
                    <P2 margin="0" style={{ lineHeight: '22.4px' }}>
                      Procure a opção de pagamentos ou transferências.
                    </P2>
                  </li>
                  <li>
                    <P2 margin="0" style={{ lineHeight: '22.4px' }}>
                      {`Escolha a forma de pagamento Pix e selecione "QR Code".`}
                    </P2>
                  </li>
                  <li>
                    <P2 margin="0" style={{ lineHeight: '22.4px' }}>
                      {`Agora, aponte a câmera do seu celular para o QR Code, ou clique em "Copiar código" e cole-o no aplicativo do banco.`}
                    </P2>
                  </li>
                  <li>
                    <P2 margin="0" style={{ lineHeight: '22.4px' }}>
                      Verifique se o valor está correto e confirme o pagamento.
                    </P2>
                  </li>
                </Ol>
              </InstructionsWrapper>
            </FlexBox>

            <Flex
              margin="16px 0 0 0"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              style={{
                borderRadius: '12px',
                backgroundColor: colors.white,
                padding: '24px',
                textAlign: 'center',
              }}
            >
              <P1
                margin="0"
                color={colors.textBlack}
                style={{ fontSize: '29.03px', lineHeight: '40px', fontWeight: 700 }}
              >
                Fazer nova quitação?
              </P1>

              <P2
                margin="16px 0 56px 0"
                color={colors.darkGray}
                style={{ fontSize: '16.8px', lineHeight: '24px' }}
              >
                Você ainda tem um ou mais contratos que podem ser quitados ou adiantados.
              </P2>

              <Flex justifyContent="center" style={{ width: '100%' }}>
                <RedirectButtonsRow>
                  <Button
                    type="button"
                    size="small"
                    styledButton={ButtonTypeEnum.Outline}
                    onClick={() => history.push('/menu-cliente')}
                  >
                    Não, encerrar quitação
                  </Button>

                  <Button type="button" size="small" onClick={() => history.push('/quitacao')}>
                    Sim, fazer nova Quitação
                  </Button>
                </RedirectButtonsRow>
              </Flex>
            </Flex>
          </PageContainer>
        </>
      )}
    </PortalTemplate>
  )
}

export default PixGeneration
