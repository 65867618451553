import styled, { css } from 'styled-components'
import { stylingProps, StylingProps } from '~/assets/styles/stylingProps'

import { fontSizes, sizes } from '~/assets/styles/variables'

import { device } from '~/config/device'

const {
  fontSize10, fontSize11, fontSize12, fontSize14, fontSize16, fontSize20,
} = fontSizes

const { size4, size32 } = sizes

interface ParagraphInterface extends StylingProps {
  color?: string
  textAlign?: string
}

const base = css<ParagraphInterface>`
  color: ${({ color }) => (color || undefined)};
  font-style: normal;
  line-height: 1.4;
  margin-bottom: ${size32};
  text-align: inherit;
  
  ${stylingProps}
`

export const P1 = styled.p`
  ${base}

  font-size: ${fontSize20};
`

export const P1Bold = styled(P1)`
  font-weight: 700;
`

export const P1ExtraBold = styled(P1)`
  font-weight: 800;
`

export const P2 = styled.p`
  ${base}

  font-size: ${fontSize16};

  @media ${device.xs} {
    font-size: ${fontSize14};
  }
`

export const P2Bold = styled(P2)`
  font-weight: 700;
`

export const P2ExtraBold = styled(P2)`
  font-weight: 800;
`

export const P3 = styled.p`
  ${base}

  font-size: ${fontSize14};

  @media ${device.xs} {
    font-size: ${fontSize11};
  }
`

export const P3Bold = styled(P3)`
  font-weight: 700;
`

export const P4 = styled.p`
  ${base}

  font-size: ${fontSize12};
  margin-bottom: ${(props: ParagraphInterface) => (props.marginBottom || size4)};

  @media ${device.xs} {
    font-size: ${fontSize10};
  }
`

export const P4ExtraBold = styled(P4)`
  font-weight: 800;
`
