import React, { useEffect } from 'react'

import { PendingInstallmentInterface } from '~/models/interfaces/services/Deals'

import { useFormats } from '~/utils/useFormats'
import { pixService } from '~/services/Pix'

import { P1, P2 } from '~/components/atoms/Paragraph'
import { Container, PixHashParagraph } from './styles'
import { sizes } from '~/assets/styles/variables'

type FormModalViewPixProps = {
  formData: {
    values: PendingInstallmentInterface['pixs'][number]
  }
}

export const FormModalViewPix = (props: FormModalViewPixProps) => {
  const { formData } = props

  const pix = formData.values

  const { formatCurrency, formatDateDefault } = useFormats()

  const [qrCode, setQrCode] = React.useState<string>('')

  useEffect(() => {
    const fetchQrCode = async () => {
      const response = await pixService.getQRCode(pix.id)
      setQrCode(response)
    }

    fetchQrCode()
  }, [pix.id])

  return (
    <Container>
      <P2 marginBottom={16}>
        Efetue o pagamento até o dia
        {' '}
        {formatDateDefault(new Date(pix.dueDate))}
      </P2>
      {qrCode && (
        <img
          src={`data:image/png;base64,${qrCode}`}
          alt="QRCode contendo a chave do pix copia e cola"
          width="181"
          height="181"
        />
      )}
      <P1 marginBlock={sizes.size16}>
        Valor:
        {' '}
        {!!pix.value && <strong>{formatCurrency(pix.value)}</strong>}
      </P1>

      <PixHashParagraph>{pix.qrCodeData}</PixHashParagraph>
    </Container>
  )
}

export default FormModalViewPix
