import React from 'react'

import { colors, sizes } from '~/assets/styles/variables'
import { H5Bold } from '~/components/atoms/Heading'

import { P2, P2Bold, P3 } from '~/components/atoms/Paragraph'

import ProposalTypeEnum from '~/models/enums/ProposalTypeEnum'
import { ProposalNegotiationPortalInterface } from '~/models/interfaces/services/Proposal'

import { useFormats } from '~/utils/useFormats'

import {
  ContractType, ExpirationColumn, ExpirationValueRow, ValueColumn,
} from './ProposalCard.styles'

const { darkGray } = colors
const {
  size0, size4, size24,
} = sizes

interface ProposalCardInterface {
  proposal: ProposalNegotiationPortalInterface
  marginBottom?: string
}

const defaultProps = {
  marginBottom: size24,
}

const ProposalCard = ({
  proposal,
  marginBottom,
}: ProposalCardInterface) => {
  const { formatCurrency, formatShortDateDefault } = useFormats()

  return (
    <>
      <P2Bold marginBottom={size4}>
        PROPOSTA DE
        {' '}
        {proposal.type === ProposalTypeEnum.Renegotiation ? 'RENEGOCIAÇÃO' : 'QUITAÇÃO'}
      </P2Bold>

      {proposal.type === ProposalTypeEnum.Renegotiation ? (
        <>
          <ContractType>
            <P2 color={darkGray} marginBottom={size0}>
              Dívidas vinculadas à proposta
            </P2>
          </ContractType>

          <ExpirationValueRow marginBottom={marginBottom}>
            <ExpirationColumn>
              <P3 color={darkGray} marginBottom={size0} textAlign="center">
                Validade da proposta
              </P3>
              <H5Bold marginBottom={size0}>
                {formatShortDateDefault(new Date(proposal.validityDate))}
              </H5Bold>
            </ExpirationColumn>

            {proposal.conditions && proposal.conditions.length && (
              <ValueColumn>
                <P3 color={darkGray} marginBottom={size0} textAlign="center">
                  Valor da dívida com descontos
                </P3>
                <H5Bold marginBottom={size0}>
                  {formatCurrency(proposal.conditions[0].debtValue)}
                </H5Bold>
              </ValueColumn>
            )}
          </ExpirationValueRow>
        </>
      ) : (
        <>
          <ContractType>
            <P2 color={darkGray} marginBottom={size0}>
              Dívidas vinculadas à proposta
            </P2>
          </ContractType>

          <ExpirationValueRow marginBottom={marginBottom}>
            <ExpirationColumn>
              <P3 color={darkGray} marginBottom={size0} textAlign="center">
                Validade da proposta
              </P3>
              <H5Bold marginBottom={size0}>
                {formatShortDateDefault(new Date(proposal.validityDate))}
              </H5Bold>
            </ExpirationColumn>

            {proposal.conditions && proposal.conditions.length && (
              <ValueColumn>
                <P3 color={darkGray} marginBottom={size0} textAlign="center">
                  Valor da dívida com descontos
                </P3>
                <H5Bold marginBottom={size0}>
                  {formatCurrency(proposal.conditions[0].debtValue)}
                </H5Bold>
              </ValueColumn>
            )}
          </ExpirationValueRow>
        </>
      )}
    </>
  )
}

ProposalCard.defaultProps = defaultProps

export default ProposalCard
