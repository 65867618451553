import styled, { keyframes } from 'styled-components'

import { colors, sizes } from '~/assets/styles/variables'

const { blue, white } = colors
const { size50Percent } = sizes

interface SpinnerInterface {
  size?: string
  width?: string
}

const spin = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  animation: ${spin} 0.5s linear infinite;
  background: transparent;
  border: ${({ width }: SpinnerInterface) => width || 4}px solid ${blue};
  border-left: 12px solid ${white};
  border-radius: ${size50Percent};
  height: ${({ size }: SpinnerInterface) => size || 24}px;
  stroke-linejoin: round;
  transform: translateZ(0);
  width: ${({ size }: SpinnerInterface) => size || 24}px;
`

export default Spinner
