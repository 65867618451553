import React from 'react'

import { sizes } from '~/assets/styles/variables'

import { Checkbox as StyledCheckbox, CheckboxLabel, Checkmark } from './Checkbox.styles'

const { size24 } = sizes

interface CheckboxInterface {
  disabled?: boolean
  id: string
  label?: string;
  marginBottom?: string
  name:string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  value: string
}

const defaultProps = {
  disabled: false,
  label: '',
  marginBottom: size24,
}

const Checkbox = ({
  label, name, id, value, disabled, marginBottom, onChange, ...props
}: CheckboxInterface) => (
  <CheckboxLabel checked={id === value} disabled={disabled} marginBottom={marginBottom}>
    {label}
    <StyledCheckbox
      type="checkbox"
      id={id}
      name={name}
      checked={id === value}
      value={id}
      disabled={disabled}
      onChange={onChange}
      data-testid="input-checkbox"
      {...props}
    />
    <Checkmark />
  </CheckboxLabel>
)

Checkbox.defaultProps = defaultProps

export default Checkbox
