import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { lighten } from 'polished'

import { colors, fontSizes, sizes } from '~/assets/styles/variables'
import { device } from '~/config/device'

const {
  gray, orange, lightGray, white, textBlack,
} = colors

const {
  size8,
  size16,
  size18,
  size20,
  size24,
  size32,
  size56,
  size50Percent,
  size100Percent,
} = sizes
const { fontSize14 } = fontSizes

interface WizardInterface {
  active: boolean
  done: boolean
}

interface ContainerInterface {
  width?: number
}

export const WizardWrapper = styled.div`
  margin-bottom: ${size20};
  padding: ${size18} ${size16};
  width: ${size100Percent};
`

export const WizardContainer = styled.div`
  align-items: center;
  color: ${textBlack};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: auto;
  gap: ${size20} ${size56};
  max-width: ${({ width }: ContainerInterface) => `${width}px`};
  width: ${size100Percent};
`

export const WizardSteps = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: ${size100Percent};

  &:after {
    content: '';
    border-bottom: 3px solid ${lightGray};
    left: 0;
    position: absolute;
    top: calc(${size50Percent} - 1px);
    width: ${size100Percent};
    z-index: 0;
  }
`

type WizardStepProps = {
  background: 'primary' | 'orange'
} & WizardInterface

export const WizardStep = styled.div<WizardStepProps>`
  align-items: center;
  background-color: ${lightGray};
  border-radius: ${size16};
  color: ${gray};
  display: flex;
  font-size: ${fontSize14};
  height: ${size24};
  justify-content: center;
  line-height: 1;
  min-width: ${size24};
  position: relative;
  z-index: 1;

  ${({ theme: { palette }, background }) => css`
    ${({ active }) => active
      && css`
        background-color: ${background === 'orange' ? orange : lighten(0.2, palette.primary.main)};
        color: ${white};
        font-weight: 800;
        height: ${size32};
        min-width: ${size32};
      `}

    ${({ done }: WizardInterface) => done
      && css`
        background-color: ${palette.primary.main};
        color: ${white};
      `}
  `}
`

export const WizardBackground = styled.div`
  background-color: ${white};
  border-radius: ${size16};
  order: 1;
  padding: ${size16} ${size24};
  width: 100%;

  @media ${device.md} {
    flex: 1;
    order: 0;
  }
`

type LinkHomeProps = {
  underline?: 'always' | 'hover' | 'none'
}

export const LinkHome = styled(Link)<LinkHomeProps>`
  align-items: center;
  display: flex;

  ${({ underline }) => {
    if (underline === 'always') {
      return css`
        text-decoration: underline;
      `
    }

    if (underline === 'hover') {
      return css`
        &:hover {
          text-decoration: underline;
        }
      `
    }

    return css`
      text-decoration: none;
    `
  }}

  svg {
    margin-left: ${size8};
  }
`
