import styled from 'styled-components'

import { sizes } from '~/assets/styles/variables'

import { device } from '~/config/device'

const { size64 } = sizes

interface ContainerWrapperInterface {
  actionBar?: boolean,
  wizard?: boolean
}

export const ContainerWrapper = styled.div<ContainerWrapperInterface>`
  background-color: ${({ theme }) => theme.palette.gray[50]};
  padding-bottom: ${(props) => (props.actionBar ? `calc(78px + ${size64})` : size64)};
  padding-top: ${(props) => (props.wizard ? '73px' : `calc(73px + ${size64})`)};
  min-height: 100vh;

  @media ${device.md} {
    padding-bottom: ${(props) => (props.actionBar ? `calc(118px + ${size64})` : size64)};
  }
`
