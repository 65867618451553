import { useCallback } from 'react'

export const usePluralization = () => {
  const pluralize = useCallback((val: number, word: string, plural = `${word}s`): string => (
    val > 1 || val < -1 ? plural : word
  ), [])

  return {
    pluralize,
  }
}
