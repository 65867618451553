import React from 'react'
import { useSelector } from 'react-redux'
import { CheckIcon } from '~/assets/icons'
import { colors, sizes } from '~/assets/styles/variables'

import { P2Bold } from '~/components/atoms/Paragraph'

import { pageWidth } from '~/config/layout/pageWidth'

import GoogleAnalyticsEventCategoryTypeEnum from '~/models/enums/GoogleAnalyticsEventCategoryTypeEnum'
import GoogleAnalyticsEventTypeEnum from '~/models/enums/GoogleAnalyticsEventTypeEnum'
import { WizardInputInterface } from '~/models/interfaces/components/Wizard'
import { RootState } from '~/store/reducers'

import { useGoogleAnalytics } from '~/utils/useGoogleAnalytics'
import { useUniqueId } from '~/utils/useUniqueId'

import {
  LinkHome,
  WizardBackground,
  WizardContainer,
  WizardStep,
  WizardSteps,
  WizardWrapper,
} from './Wizard.styles'

const { textBlack } = colors
const { size0, size18 } = sizes

interface WizardInterface {
  children: WizardInputInterface[]
  width?: number
}

const defaultProps = {
  width: pageWidth.medium,
}

const Wizard = ({ children, width }: WizardInterface) => {
  const { regEventGa } = useGoogleAnalytics()
  const { generateUniqueStringId } = useUniqueId()

  const { campaignType } = useSelector((state: RootState) => state.campaign)

  const indexWizardActive = children.findIndex((wizard) => wizard.active)

  return (
    <>
      {!!children.length && (
        <WizardWrapper>
          <WizardContainer width={width}>
            <P2Bold color={textBlack} marginBottom={size0}>
              Passo
              {' '}
              {indexWizardActive + 1}
              {' '}
              de
              {' '}
              {children.length}
            </P2Bold>

            <WizardBackground>
              <WizardSteps>
                {children.map((wizard: WizardInputInterface, index: number) => (
                  <WizardStep
                    active={!!wizard.active}
                    done={index < indexWizardActive}
                    key={generateUniqueStringId()}
                    title={wizard.title}
                    background={campaignType ? 'primary' : 'orange'}
                  >
                    {index >= indexWizardActive ? <>{index + 1}</> : <CheckIcon size={size18} />}
                  </WizardStep>
                ))}
              </WizardSteps>
            </WizardBackground>

            <LinkHome
              color={textBlack}
              to="/menu-cliente"
              underline="hover"
              onClick={() => regEventGa(
                GoogleAnalyticsEventCategoryTypeEnum.MenuCliente,
                GoogleAnalyticsEventTypeEnum.MenuClienteVoltar,
              )}
            >
              Voltar ao início
            </LinkHome>
          </WizardContainer>
        </WizardWrapper>
      )}
    </>
  )
}

Wizard.defaultProps = defaultProps

export default Wizard
