import { createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from './reducers'

let store = createStore(rootReducer)

if (process.env.REACT_APP_ENV === 'local') {
  store = createStore(rootReducer, composeWithDevTools())
}

export default store
