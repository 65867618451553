import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Hidden, Row } from 'react-grid-system'
import { useHistory } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { Accordion, AccordionItem, AccordionTrigger } from '@radix-ui/react-accordion'

import { colors, sizes } from '~/assets/styles/variables'
import Button from '~/components/atoms/Button'

import { CampaignBanner } from '~/components/atoms/Banner/common'
import { Card, CardBody } from '~/components/atoms/Card'
import { H4 } from '~/components/atoms/Heading'
import PageContainer from '~/components/atoms/PageContainer'
import { P2 } from '~/components/atoms/Paragraph'
import ActionBar from '~/components/molecules/ActionBar'
import Wizard from '~/components/molecules/Wizard'
import PortalTemplate from '~/components/templates/PortalTemplate'

import { pageWidth } from '~/config/layout/pageWidth'
import setTitle from '~/config/title'

import ButtonTypeEnum from '~/models/enums/ButtonTypeEnum'
import ContractTypeEnum from '~/models/enums/ContractTypeEnum'

import { WizardInputInterface } from '~/models/interfaces/components/Wizard'
import { ContractBoletoCopyInterface } from '~/models/interfaces/services/Contracts'
import { DealPendingsInterface } from '~/models/interfaces/services/Deals'

import { getContractItems, getContractsToBoletoCopy } from '~/services/Contracts'
import { getPendings } from '~/services/Deals'

import store from '~/store'
import { RootState } from '~/store/reducers'

import { usePluralization } from '~/utils/usePluralization'
import { useUniqueId } from '~/utils/useUniqueId'
import useWindowDimensions from '~/utils/useWindowDimensions'

import ContractCard from './ContractCard'
import DealCard from './DealCard'
import {
  AccordionButton,
  AccordionContent,
  AccordionHeader,
  ContractActionsRow,
  ContractButtonsColumn,
} from './Contracts.styles'
import { FormModalDialogContractItemsFields } from './FormModalDialogContractItemsFields'
import { FormModalDialogInstallmentsFields } from './FormModalDialogInstallmentsFields'
import { CardActions } from './CardActions'

const { darkGray, focusBlue, orange } = colors
const { size0, size8 } = sizes

const Contracts = () => {
  setTitle('Seleção de contratos - Segunda via')

  const wizardElements: WizardInputInterface[] = [
    {
      title: 'Seleção de contratos',
      active: true,
    },
    {
      title: 'Geração de boletos',
    },
  ]

  const history = useHistory()
  const { pluralize } = usePluralization()
  const { md } = useWindowDimensions()
  const { generateUniqueStringId } = useUniqueId()

  const [authSelector, userDataSelector] = useSelector(
    ({ auth, userDataProcessing }: RootState) => [auth, userDataProcessing] as const,
  )

  const { user } = authSelector

  const { idEntryAutomation } = userDataSelector

  const [contracts, setContracts] = useState<ContractBoletoCopyInterface[]>([])

  const [pendings, setPendings] = useState<DealPendingsInterface[]>([])

  const containerPageWidth = pageWidth.medium

  const [block, setBlock] = useState<boolean>(true)

  useEffect(() => {
    if (!user) return

    getContractsToBoletoCopy(user.id, user.cpfCnpj, idEntryAutomation).then((response) => setContracts(response))

    getPendings(user.id).then((response) => setPendings(response))
  }, [idEntryAutomation, user])

  useEffect(() => {
    setBlock(true)
    const hasContracts = !!contracts.find((pend) => !!pend.installments.find((i) => !!i.selected))
    const hasPendings = !!pendings.find((pend) => !!pend.installments.find((i) => !!i.selected))
    setBlock(!hasContracts && !hasPendings)
  }, [contracts, pendings])

  const handleRefreshContractsState = () => {
    const freshContracts: ContractBoletoCopyInterface[] = [...contracts]
    setContracts(freshContracts)
  }

  const handleSelectInstallments = (formValues: any) => {
    const selectedContract = formValues as ContractBoletoCopyInterface

    const selectedContracts: ContractBoletoCopyInterface[] = [...contracts]

    selectedContracts.forEach((contract) => {
      if (selectedContract.externalCode === contract.externalCode) {
        contract.installments = selectedContract.installments
      }
    })

    setContracts(selectedContracts)
  }

  const showModalContractInstallments = (
    contract: ContractBoletoCopyInterface,
    modalTitle: string,
  ) => {
    store.dispatch({
      type: 'SET_FORM_MODAL_DIALOG_INFO',
      payload: {
        FormFields: FormModalDialogInstallmentsFields,
        initialValues: contract,
        handleCancel: handleRefreshContractsState,
        handleConfirm: (formValues: any) => handleSelectInstallments(formValues),
        title: modalTitle,
        validationSchema: null,
      },
    })
    store.dispatch({ type: 'SET_VISIBLE_FORM_MODAL_DIALOG' })
  }

  const showModalContractItems = (contract: ContractBoletoCopyInterface) => {
    getContractItems(contract.externalCode).then((response) => {
      store.dispatch({
        type: 'SET_FORM_MODAL_DIALOG_INFO',
        payload: {
          FormFields: FormModalDialogContractItemsFields,
          initialValues: { contract, contractItems: response.content },
          handleCancel: null,
          handleConfirm: null,
          title: 'Histórico de lançamentos',
          validationSchema: null,
        },
      })
      store.dispatch({ type: 'SET_VISIBLE_FORM_MODAL_DIALOG' })
    })
  }

  const handleSubmit = () => {
    history.push('/segunda-via/geracao-boletos', { contracts, pendings })
  }

  const handleRedirect = (page: string) => history.push(page)

  return (
    <PortalTemplate>
      {!!contracts.length && (
        <>
          <Wizard>{wizardElements}</Wizard>

          <PageContainer width={containerPageWidth}>
            <Row>
              <Col xs={12}>
                <CampaignBanner />
              </Col>
              <Col lg={10}>
                <H4 marginTop="1rem" marginBottom="2rem">
                  Selecione abaixo as parcelas, faturas ou negociações pendentes para gerar a
                  segunda via:
                </H4>
              </Col>
            </Row>

            <Formik enableReinitialize initialValues={{}} onSubmit={handleSubmit} validateOnMount>
              {() => (
                <Form>
                  <Accordion type="multiple" defaultValue={['item-1', 'item-2']}>
                    <AccordionItem value="item-1">
                      <AccordionHeader>
                        <H4>Minhas negociações pendentes:</H4>
                        <AccordionTrigger asChild>
                          <AccordionButton />
                        </AccordionTrigger>
                      </AccordionHeader>

                      <AccordionContent>
                        {!pendings.length ? (
                          <P2 color={darkGray} marginBottom={size0}>
                            Nenhuma negociação pendente no momento
                          </P2>
                        ) : (
                          pendings.map((deal) => (
                            <Card key={generateUniqueStringId()} negative>
                              <CardBody>
                                <DealCard contract={deal} />
                                <CardActions deal={deal} pendings={pendings} setPendings={setPendings} />
                              </CardBody>
                            </Card>
                          ))
                        )}
                      </AccordionContent>
                    </AccordionItem>

                    <AccordionItem value="item-2">
                      <AccordionHeader>
                        <H4>Meus carnês e/ou faturas:</H4>
                        <AccordionTrigger asChild>
                          <AccordionButton />
                        </AccordionTrigger>
                      </AccordionHeader>

                      <AccordionContent>
                        {contracts.map((contract) => {
                          const contractStateCopy = { ...contract }
                          contractStateCopy.installments = []

                          contract.installments.forEach((i) => {
                            contractStateCopy.installments.push({ ...i })
                          })

                          return (
                            <Card key={contract.contractNumber} negative>
                              <CardBody>
                                <ContractCard contract={contract} />
                                {contract.type === ContractTypeEnum.Fatura ? (
                                  <ContractActionsRow>
                                    {contract.selectable ? (
                                      <>
                                        {contract.installments.filter((i) => i.selected).length ? (
                                          <P2 color={focusBlue} marginBottom={md ? size0 : size8}>
                                            Fatura selecionada
                                          </P2>
                                        ) : (
                                          <P2 color={darkGray} marginBottom={md ? size0 : size8}>
                                            Fatura não selecionada
                                          </P2>
                                        )}
                                      </>
                                    ) : (
                                      <P2 color={darkGray} marginBottom={md ? size0 : size8}>
                                        Contrato incluso em renegociação
                                      </P2>
                                    )}

                                    <ContractButtonsColumn>
                                      <Button
                                        size="small"
                                        onClick={() => showModalContractItems(contract)}
                                        styledButton={ButtonTypeEnum.Outline}
                                      >
                                        <Hidden xs>Ver </Hidden>
                                        lançamentos
                                      </Button>

                                      <Button
                                        disabled={!contract.selectable}
                                        onClick={() => showModalContractInstallments(
                                          contractStateCopy,
                                          'Selecionar fatura',
                                        )}
                                        size="small"
                                      >
                                        Selecionar
                                        <Hidden xs> fatura</Hidden>
                                      </Button>
                                    </ContractButtonsColumn>
                                  </ContractActionsRow>
                                ) : (
                                  <ContractActionsRow>
                                    {contract.selectable ? (
                                      <>
                                        {contract.installments.filter((i) => i.selected).length ? (
                                          <P2 color={orange} marginBottom={md ? size0 : size8}>
                                            {`${
                                              contract.installments.filter((i) => i.selected).length
                                            } ${pluralize(
                                              contract.installments.filter((i) => i.selected)
                                                .length,
                                              ' parcela selecionada',
                                              ' parcelas selecionadas',
                                            )}`}
                                          </P2>
                                        ) : (
                                          <P2 color={darkGray} marginBottom={md ? size0 : size8}>
                                            Nenhuma parcela selecionada
                                          </P2>
                                        )}
                                      </>
                                    ) : (
                                      <P2 color={darkGray} marginBottom={md ? size0 : size8}>
                                        Contrato incluso em renegociação
                                      </P2>
                                    )}

                                    <ContractButtonsColumn>
                                      <Button
                                        disabled={!contract.selectable}
                                        displayBlock={!md}
                                        onClick={() => showModalContractInstallments(
                                          contractStateCopy,
                                          'Selecionar parcelas',
                                        )}
                                        size="small"
                                      >
                                        Selecionar parcelas
                                      </Button>
                                    </ContractButtonsColumn>
                                  </ContractActionsRow>
                                )}
                              </CardBody>
                            </Card>
                          )
                        })}
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>

                  <ActionBar
                    returnAction={() => handleRedirect('/menu-cliente')}
                    submitActive={!block}
                    width={containerPageWidth}
                  />
                </Form>
              )}
            </Formik>
          </PageContainer>
        </>
      )}
    </PortalTemplate>
  )
}

export default Contracts
