import React from 'react'

import { colors, sizes } from '~/assets/styles/variables'
import { H5Bold } from '~/components/atoms/Heading'

import { P2, P2Bold, P3 } from '~/components/atoms/Paragraph'

import NegotiationTypeEnum from '~/models/enums/NegotiationTypeEnum'
import { DealPendingsInterface } from '~/models/interfaces/services/Deals'

import { useFormats } from '~/utils/useFormats'

import {
  ContractType, ExpirationColumn, ExpirationValueRow, ValueColumn,
} from './DealCard.styles'

const { darkGray } = colors
const {
  size0, size4, size24,
} = sizes

interface DealCardInterface {
  contract: DealPendingsInterface
  marginBottom?: string
}

const defaultProps = {
  marginBottom: size24,
}

const DealCard = ({
  contract,
  marginBottom,
}: DealCardInterface) => {
  const { formatCurrency, formatDay } = useFormats()

  const {
    dueDate, valueTotal, installments, type, installmentNumbers, negotiation,
  } = contract

  const finalInstallment = installmentNumbers - 1

  return (
    <>
      <P2Bold marginBottom={size4}>
        {negotiation.name}
      </P2Bold>

      {type === NegotiationTypeEnum.Renegociacao ? (
        <>
          <ContractType>
            <P2 color={darkGray} marginBottom={size0}>
              {`Renegociação com entrada + ${finalInstallment} parcelas`}
            </P2>
          </ContractType>

          <ExpirationValueRow marginBottom={marginBottom}>
            <ExpirationColumn>
              <H5Bold marginBottom={size0}>
                {`Dia ${formatDay(new Date(dueDate))}`}
              </H5Bold>
              <P3 color={darkGray} marginBottom={size0}>
                Vencimento
              </P3>
            </ExpirationColumn>

            {installments && installments.length && (
            <ValueColumn>
              <H5Bold marginBottom={size0}>
                {formatCurrency(valueTotal)}
              </H5Bold>
              <P3 color={darkGray} marginBottom={size0}>
                Valor total
              </P3>
            </ValueColumn>
            )}
          </ExpirationValueRow>
        </>
      ) : (
        <>
          <ContractType>
            <P2 color={darkGray} marginBottom={size0}>
              Pagamento de parcelas à vista
            </P2>
          </ContractType>

          <ExpirationValueRow marginBottom={marginBottom}>
            <ExpirationColumn>
              <H5Bold marginBottom={size0}>
                {`Dia ${formatDay(new Date(dueDate))}`}
              </H5Bold>
              <P3 color={darkGray} marginBottom={size0}>
                Vencimento
              </P3>
            </ExpirationColumn>

            {installments && installments.length && (
            <ValueColumn>
              <H5Bold marginBottom={size0}>
                {formatCurrency(valueTotal)}
              </H5Bold>
              <P3 color={darkGray} marginBottom={size0}>
                Valor total
              </P3>
            </ValueColumn>
            )}
          </ExpirationValueRow>
        </>
      )}

    </>
  )
}

DealCard.defaultProps = defaultProps

export default DealCard
