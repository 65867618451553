import styled from 'styled-components'

import { colors, sizes } from '~/assets/styles/variables'
import { device } from '~/config/device'

import Button from '~/components/atoms/Button'

const { opacity30Blue, white } = colors

const {
  size0, size4, size10, size16, size20, size32, size100Percent,
} = sizes

interface ContainerInterface {
  width?: number
}

export const Footer = styled.footer`
  align-items: center;
  background: ${white};
  bottom: 0;
  box-shadow: ${size0} ${`-${size4}`} ${size32} ${opacity30Blue};
  left: 0;
  padding: ${size16};
  position: fixed;
  width: ${size100Percent};

  @media ${device.md} {
    padding: ${size32};
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: auto;
  max-width: ${({ width }: ContainerInterface) => `${width}px`};
  width: ${size100Percent};
`

export const ButtonSubmit = styled(Button)`
  @media ${device.xs} {
    margin-left: ${size10};
    line-height: ${size20};
  }
`
