import initialState from './initialState'
import { ModalDialogActionTypes } from './types'

export const modalDialogReducer = (
  state = initialState,
  action: ModalDialogActionTypes,
) => {
  switch (action.type) {
    case 'SET_VISIBLE_MODAL_DIALOG':

      return {
        ...state,
        isVisible: true,
      }

    case 'SET_INVISIBLE_MODAL_DIALOG':

      return {
        ...state,
        isVisible: false,
      }

    case 'SET_MODAL_DIALOG_INFO':

      return {
        ...state,
        modalDialogInfo: {
          ...state.modalDialogInfo,
          ...action.payload,
          title: action.payload.title || '',
        },
      }

    case 'RESET_MODAL_DIALOG_INFO':

      return {
        ...state,
        modalDialogInfo: initialState.modalDialogInfo,
      }

    default:
      return state
  }
}
