import React from 'react'
import { Hidden } from 'react-grid-system'

import { colors, sizes } from '~/assets/styles/variables'

import Checkbox from '~/components/atoms/Checkbox'
import { P3, P4 } from '~/components/atoms/Paragraph'
import Tag from '~/components/atoms/Tag'

import { ContractInstallmentBoletoCopyInterface } from '~/models/interfaces/services/Contracts'

import { useFormats } from '~/utils/useFormats'

import { InstallmentItem, InstallmentNumberColumn, InstallmentStatusColumn } from './InstallmentListItem.styles'

const { darkGray } = colors
const { size0, size2 } = sizes

interface InstallmentListItemInterface {
  handleCheck: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void
  installment: ContractInstallmentBoletoCopyInterface
}

const InstallmentListItem = (
  { handleCheck, installment }: InstallmentListItemInterface,
) => {
  const { formatDateDefault } = useFormats()

  return (
    <InstallmentItem disabled={!installment.selectable} selected={installment.selected}>
      <InstallmentNumberColumn>
        <Checkbox
          id="true"
          name={installment.externalCode}
          value={`${!installment.selected ? 'false' : 'true'}`}
          disabled={!installment.selectable}
          onChange={(event) => handleCheck(event, installment.externalCode)}
          marginBottom={size0}
        />
        <div>
          <P4 color={darkGray}>
            Parcela
          </P4>
          <P3 marginBottom={size0}>
            {String(installment.installmentNumber).padStart(3, '0')}
          </P3>
        </div>
      </InstallmentNumberColumn>
      <div>
        <P4 color={darkGray}>
          Vencimento
        </P4>
        <P3 marginBottom={size0}>
          {formatDateDefault(new Date(installment.dueDate))}
        </P3>
      </div>
      <InstallmentStatusColumn>
        <Tag marginBottom={!installment.selectable ? size2 : size0} small tagType={installment.lateStatus === 'Em atraso' ? 'negative' : 'alert'}>
          {installment.lateStatus}
        </Tag>
        { !installment.selectable && (
          <div>
            <Tag small tagType="disabled">
              Indisponível
              {' '}
              <Hidden xs>
                para impressão
              </Hidden>
            </Tag>
          </div>
        )}
      </InstallmentStatusColumn>
    </InstallmentItem>
  )
}

export default InstallmentListItem
