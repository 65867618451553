import styled from 'styled-components'
import { colors, fontSizes, sizes } from '~/assets/styles/variables'

const { textBlack, white } = colors
const { size12, size16, size24 } = sizes

export const Container = styled.div`
  color: ${colors.textBlack};
  padding: ${size24};
  text-align: center;
`

export const PixHashParagraph = styled.div`
  background-color: ${white};
  color: ${textBlack};
  font-size: ${fontSizes.fontSize14};
  font-weight: 600;
  line-height: 150%;
  padding: ${size16};
  word-break: break-word;
  border-radius: ${size12};
`
