import styled from 'styled-components'

import { colors, sizes } from '~/assets/styles/variables'

import { P2 } from '~/components/atoms/Paragraph'

const { red } = colors
const { size32 } = sizes

interface HintInterface {
  color?: string
  marginY?: string
}

const Hint = styled(P2)`
  color: ${({ color }: HintInterface) => (color || red)};
  margin: ${({ marginY }: HintInterface) => (marginY ? `${marginY} 0` : `${size32} 0`)};
`

export default Hint
