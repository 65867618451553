import api from '~/config/api'
import { baseChargeIntegrations } from '~/config/basePath'

import { ProposalActivationInterface, ProposalNegotiationPortalInterface } from '~/models/interfaces/services/Proposal'

const pathUrl = `${baseChargeIntegrations}/v1/negotiation-portal/proposal`

const getProposalByCustomer = (customerId: string, idEntryAutomation?: number): Promise<ProposalNegotiationPortalInterface[]> => {
  const params = { idEntryAutomation }

  return api
    .get<ProposalNegotiationPortalInterface[]>(`${pathUrl}/${customerId}`, { params })
    .then(({ data }) => data)
}

const activate = (
  proposalId: string,
  conditionId: string,
  idEntryAutomation?: number,
): Promise<ProposalActivationInterface> => {
  const params = { conditionId, idEntryAutomation }

  return api
    .post<ProposalActivationInterface>(`${pathUrl}/${proposalId}/activate`, null, { params })
    .then(({ data }) => data)
}

export { getProposalByCustomer, activate }
