import React, { ImgHTMLAttributes } from 'react'
import logo from '~/assets/images/logo.svg'
import { Image } from './styles'

type LogoProps = ImgHTMLAttributes<HTMLImageElement>

export const Logo = ({ src, ...rest }: LogoProps) => (
  <Image src={src || logo} {...rest} alt="VIACERTA Banking" />
)

Logo.defaultProps = {
  width: 110,
  height: 25,
}
