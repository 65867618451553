import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import {
  Redirect, Route, Switch, useLocation,
} from 'react-router-dom'

import IdentificationTemplate from '~/components/templates/IdentificationTemplate'

import BoletoCopyContracts from '~/pages/BoletoCopy/Contracts'
import BoletoCopyBoletoGeneration from '~/pages/BoletoCopy/BoletoGeneration'
import ConfirmAccessCode from '~/pages/ConfirmAccessCode'
import CustomerMenu from '~/pages/CustomerMenu'
import DischargeBoletoGeneration from '~/pages/Discharge/BoletoGeneration'
import DischargeContracts from '~/pages/Discharge/Contracts'
import DischargeEffectuation from '~/pages/Discharge/Effectuation'
import DischargeError from '~/pages/Discharge/Error'
import GenerateAccessCode from '~/pages/GenerateAccessCode'
import Identification from '~/pages/Identification'
import Maintenance from '~/pages/Maintenance'
import RenegotiationBoletoGeneration from '~/pages/Renegotiation/BoletoGeneration'
import RenegotiationContracts from '~/pages/Renegotiation/Contracts'
import RenegotiationEffectuation from '~/pages/Renegotiation/Effectuation'
import RenegotiationError from '~/pages/Renegotiation/Error'
import RenegotiationNegotiations from '~/pages/Renegotiation/Negotiations'
import Proposal from '~/pages/Proposals/Proposal'
import ProposalBoletoGeneration from '~/pages/Proposals/BoletoGeneration'
import PixGeneration from '~/pages/Discharge/PixGeneration'

import { RootState } from '~/store/reducers'

import { useCampaign } from '~/hooks/useCampaign'
import { useGoogleAnalytics } from '~/utils/useGoogleAnalytics'
import LoginByExternalApp from '~/pages/LoginByExternalApp'

const PrivateRoute = (
  { children }: JSX.ElementChildrenAttribute,
): JSX.Element => {
  const authSelector = useSelector(
    ({ auth }: RootState) => auth,
  )

  return (authSelector.loggedIn ? (
    <>
      { children }
    </>
  ) : (

    <Route>
      <Redirect to={{ pathname: '/', state: { } }} />
    </Route>

  ))
}

const Routes = ():JSX.Element => {
  const { pageView } = useGoogleAnalytics()
  const location = useLocation()
  useCampaign()

  useEffect(() => pageView(),
    [location, pageView])

  return (
    <Switch>
      <Route exact path="/manutencao">
        <IdentificationTemplate>
          <Maintenance />
        </IdentificationTemplate>
      </Route>

      <Route exact path="/">
        <IdentificationTemplate>
          <Identification />
        </IdentificationTemplate>
      </Route>

      <Route exact path="/external-auth/:id">
        <LoginByExternalApp />
      </Route>

      <Route exact path="/autenticacao">
        <GenerateAccessCode />
      </Route>

      <Route exact path="/autenticacao/confirmar-codigo-acesso">
        <ConfirmAccessCode />
      </Route>

      <PrivateRoute>
        <Route exact path="/menu-cliente">
          <CustomerMenu />
        </Route>

        <Route exact path="/segunda-via">
          <BoletoCopyContracts />
        </Route>

        <Route exact path="/segunda-via/geracao-boletos">
          <BoletoCopyBoletoGeneration />
        </Route>

        <Route exact path="/quitacao">
          <DischargeContracts />
        </Route>

        <Route exact path="/quitacao/efetivacao">
          <DischargeEffectuation />
        </Route>

        <Route exact path="/quitacao/geracao-boleto">
          <DischargeBoletoGeneration />
        </Route>

        <Route exact path="/quitacao/geracao-pix">
          <PixGeneration />
        </Route>

        <Route exact path="/quitacao/erro">
          <DischargeError />
        </Route>

        <Route exact path="/renegociacao">
          <RenegotiationNegotiations />
        </Route>

        <Route exact path="/renegociacao/revisao-contratos">
          <RenegotiationContracts />
        </Route>

        <Route exact path="/renegociacao/efetivacao">
          <RenegotiationEffectuation />
        </Route>

        <Route exact path="/renegociacao/geracao-boleto">
          <RenegotiationBoletoGeneration />
        </Route>

        <Route exact path="/renegociacao/erro">
          <RenegotiationError />
        </Route>

        <Route exact path="/propostas">
          <Proposal />
        </Route>

        <Route exact path="/propostas/geracao-boletos">
          <ProposalBoletoGeneration />
        </Route>

      </PrivateRoute>
    </Switch>
  )
}

export default Routes
