import React, { useEffect } from 'react'

import { useHistory, useLocation, useParams } from 'react-router-dom'

import setTitle from '~/config/title'

import { loginExternalApp } from '~/services/Auth'

import store from '~/store'

interface ParamsInterface {
  id: string
}

const LoginByExternalApp = () => {
  const location = useLocation()

  setTitle('Identificação')

  const { id } = useParams<ParamsInterface>()

  const history = useHistory()

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const redirect = urlParams.get('r')

    const loginExternal = () => {
      loginExternalApp(id)
        .then((response) => {
          store.dispatch({ type: 'LOGIN', payload: response })
          store.dispatch({
            type: 'UPDATE_USER_DATA_PROCESSING',
            payload: { idEntryAutomation: response.idEntryAutomation },
          })
          if (redirect) {
            history.push(redirect)
          } else {
            history.push('/menu-cliente')
          }
        }).catch(() => {
          history.push('/')
        })
    }

    if (id) {
      store.dispatch({ type: 'LOGOUT' })
      store.dispatch({
        type: 'SET_INITIAL_USER_DATA_PROCESSING',
      })
      loginExternal()
    }
  }, [history, id, location.search])

  return (
    <></>
  )
}

export default LoginByExternalApp
