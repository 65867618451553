import React from 'react'
import { Hidden, Visible } from 'react-grid-system'
import { CreditCardIcon } from '~/assets/icons'

import { colors, sizes } from '~/assets/styles/variables'
import { H5Bold } from '~/components/atoms/Heading'

import { P2, P2Bold, P3 } from '~/components/atoms/Paragraph'

import ContractTypeEnum from '~/models/enums/ContractTypeEnum'

import {
  RenegotiationSimulationContractOutputInterface,
  RenegotiationSimulationInstallmentOutputInterface,
} from '~/models/interfaces/services/Negotiations'

import { useFormats } from '~/utils/useFormats'
import { usePluralization } from '~/utils/usePluralization'

import {
  ContractType, DaysOfLateColumn, ExpirationValueRow, ValueColumn,
} from './ContractCard.styles'

const { darkGray, orange, red } = colors
const {
  size0, size4, size10,
} = sizes

interface ContractCardInterface {
  contract: RenegotiationSimulationContractOutputInterface
}

const ContractCard = ({
  contract,
}: ContractCardInterface) => {
  const { formatCurrency } = useFormats()
  const { pluralize } = usePluralization()

  const firstInstallment: RenegotiationSimulationInstallmentOutputInterface = { ...contract.installments[0] }

  return (
    <>
      <P2Bold marginBottom={size4}>
        {contract.productNameWithoutCode}
      </P2Bold>

      {contract.contractType === ContractTypeEnum.Fatura ? (
        <ContractType>
          <CreditCardIcon color={orange} margin={`${size0} ${size10} ${size0} ${size0}`} />
          <P2 color={darkGray} marginBottom={size0}>
            {`Fatura disponível de ${formatCurrency(firstInstallment.valueTotal)}`}
          </P2>
        </ContractType>
      ) : (
        <ContractType>
          <P2 color={darkGray} marginBottom={size0}>
            {`${contract.amountInstallments} ${pluralize(
              contract.amountInstallments,
              'parcela disponível',
              'parcelas disponíveis',
            )} de ${formatCurrency(firstInstallment.valueTotal)}`}
          </P2>
        </ContractType>
      )}

      <ExpirationValueRow>
        <DaysOfLateColumn>
          <P3 color={darkGray} marginBottom={size0}>
            {contract.installmentMoreDelayed > 0 ? (
              <>
                Parcela
                {' '}
                <Visible xs>
                  +
                </Visible>
                <Hidden xs>
                  mais
                </Hidden>
                {' '}
                atrasada
              </>
            ) : (
              <>
                Próximo vencimento
              </>
            )}
          </P3>
          <H5Bold marginBottom={size0}>
            {
              `${contract.installmentMoreDelayed} ${pluralize(
                contract.installmentMoreDelayed,
                'dia',
              )}`.replace('-', '')
            }
          </H5Bold>
        </DaysOfLateColumn>

        {contract.installments && contract.installments.length && (
          <ValueColumn>
            <P3 color={red} marginBottom={size0}>
              Valor total
            </P3>
            <H5Bold color={red} marginBottom={size0}>
              {formatCurrency(contract.valueTotal)}
            </H5Bold>
          </ValueColumn>
        )}
      </ExpirationValueRow>
    </>
  )
}

export default ContractCard
