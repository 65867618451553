import { lighten } from 'polished'
import styled, { css } from 'styled-components'

import { colors, sizes } from '~/assets/styles/variables'

const {
  blue, darkGray, lightBlue, white,
} = colors

const {
  size0, size2, size8, size10, size12, size20, size38, size50Percent,
} = sizes

interface RadioButtonInterface {
  checked?: boolean
  disabled?: boolean
  last?: boolean
  marginBottom?: string
  textAlign?: string
  spanAlign?: boolean
}

const Circle = css`
  border-radius: ${size50Percent};
  height: ${size12};
  left: ${size2};
  position: absolute;
  top: ${size2};
  width: ${size12};
`

export const Title = styled.div`
  color: ${darkGray};
  font-size: ${size12};
  margin-bottom: ${size8};
`

export const CheckMark = styled.span<RadioButtonInterface>`
  background-color: ${white};
  border: 2px solid ${({ disabled }) => (disabled ? darkGray : blue)};
  border-radius: ${size50Percent};
  height: ${size20};
  left: ${size10};
  position: absolute;
  transition: background-color 0.25s;
  width: ${size20};
  top: ${({ spanAlign }) => (spanAlign ? size50Percent : size12)};
  transform: ${({ spanAlign }) => (spanAlign ? `translateY(-${size50Percent})` : 'none')};

  &:after {
    background-color: ${({ disabled, theme: { palette } }) => (disabled ? darkGray : palette.primary.main)};
    content: '';
    display: none;

    ${Circle}
  }
`

export const Container = styled.label<RadioButtonInterface>`
  background-color: ${white};
  border: 2px solid
    ${({ checked, theme: { palette } }) => (checked ? lighten(0.3, palette.primary.main) : white)};
  border-radius: ${size8};
  color: ${({ theme: { palette } }) => palette.text.primary};
  cursor: pointer;
  display: block;
  line-height: 1.25;
  margin-bottom: ${({ last, marginBottom }) => (last ? size0 : marginBottom)};
  padding: ${size12} ${size38};
  position: relative;
  text-align: ${({ textAlign }) => textAlign};
  transition: border-color 0.25s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  word-wrap: break-word;

  ${({ checked }) => !checked
    && css`
      &:hover {
        border-color: ${lightBlue};
      }
    `}

  ${({ textAlign }) => textAlign !== 'center'
    && css`
      padding-right: ${size12};
    `}

  ${({ disabled }) => disabled
    && css`
      color: ${darkGray};
      cursor: not-allowed;
    `}

  ${({ disabled }) => !disabled
    && css`
      &:hover input ~ ${CheckMark} {
        background-color: ${({ theme: { palette } }) => palette.text.primary};
      }
    `}

  & input:checked ~ ${CheckMark} {
    background-color: ${white};
  }

  & input:checked ~ ${CheckMark}:after {
    display: block;
  }

  & {
    > input {
      display: none;
    }
  }
`
