import styled, { css } from 'styled-components'
import { darken } from 'polished'

import { device } from '~/config/device'
import { sizes, zIndex } from '~/assets/styles/variables'

const {
  size0, size16, size20, size24, size28, size32, size100Percent,
} = sizes

type HeaderWrapperProps = {
  hasBackgroundColor?: boolean
}

export const HeaderWrapper = styled.div<HeaderWrapperProps>`
  align-items: center;
  backdrop-filter: blur(8px);
  background-color: ${({ hasBackgroundColor, theme: { palette } }) => (hasBackgroundColor ? palette.background.default : 'transparent')};
  display: flex;
  left: ${size0};
  padding: ${size20} ${size16} ${size24};
  position: fixed;
  top: ${size0};
  width: ${size100Percent};
  z-index: ${zIndex.secondLayer};
  min-height: ${size28};

  @media ${device.xxl} {
    padding: ${size20} ${size32} ${size24};
  }
`

export const Figure = styled.figure`
  height: auto;
  width: 110px;

  img {
    width: ${size100Percent};
  }
`

export const Title = styled.h1`
  ${({ theme: { fontSizes } }) => css`
    font-size: ${fontSizes.fontSize14};
    font-weight: normal;
  `}
`

export const LogoutButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  transition: color 0.25s;

  &:focus,
  &:hover {
    color: ${({ theme: { palette } }) => darken(0.3, palette.primary.main)};
    text-decoration: underline;
  }
`
