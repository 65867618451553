import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Hidden, Row } from 'react-grid-system'
import { useHistory } from 'react-router-dom'

import { BarcodeIcon, HandsIcon, LateIcon } from '~/assets/icons'
import { colors, sizes } from '~/assets/styles/variables'

import { BannerImage } from '~/components/atoms/Banner'
import Button from '~/components/atoms/Button'
import { Card, CardBody, CardFooter } from '~/components/atoms/Card'
import { DevelopedByViAcerta } from '~/components/atoms/DevelopedByViAcerta'
import { Flex } from '~/components/atoms/Flex'
import { H4, H5 } from '~/components/atoms/Heading'
import PageContainer from '~/components/atoms/PageContainer'
import { P1, P2, P2Bold } from '~/components/atoms/Paragraph'
import Strong from '~/components/atoms/Strong'
import Tag from '~/components/atoms/Tag'
import ContactMethods from '~/components/organisms/ContactMethods'
import PortalTemplate from '~/components/templates/PortalTemplate'

import { pageWidth } from '~/config/layout/pageWidth'
import setTitle from '~/config/title'

import ButtonTypeEnum from '~/models/enums/ButtonTypeEnum'
import { CampaignType } from '~/models/enums/Campaign'
import GoogleAnalyticsEventCategoryTypeEnum from '~/models/enums/GoogleAnalyticsEventCategoryTypeEnum'
import GoogleAnalyticsEventTypeEnum from '~/models/enums/GoogleAnalyticsEventTypeEnum'
import { ProposalsAndPendingsInterface } from '~/models/interfaces/services/Deals'
import PersonTypeEnum from '~/models/enums/PersonTypeEnum'

import { getProposalsAndPendings } from '~/services/Deals'
import { hasContracts } from '~/services/Customers'

import store from '~/store'
import { RootState } from '~/store/reducers'

import { useGoogleAnalytics } from '~/utils/useGoogleAnalytics'
import { useName } from '~/utils/useName'

import {
  CardBodyStyled,
  CardInfoWrapper,
  ColStyled,
  H2Styled,
  IconWrapper,
  TagWrapper,
} from './CustomerMenu.styles'
import { usePluralization } from '~/utils/usePluralization'
import { breakpoint } from '~/config/device'

const { blue, textBlack } = colors

const {
  size0, size20, size32, size100Percent,
} = sizes

const CustomerMenu = () => {
  setTitle('Menu do cliente')
  const history = useHistory()
  const { regEventGa } = useGoogleAnalytics()
  const { firstName } = useName()

  const [authSelector, campaignSelector, userDataSelector] = useSelector(
    ({ auth, campaign, userDataProcessing }: RootState) => [auth, campaign, userDataProcessing] as const,
  )

  const { idEntryAutomation } = userDataSelector

  const { user } = authSelector

  const [loadedData, setLoadedData] = useState<boolean>(false)

  const [customerName, setCustomerName] = useState<string>('Cliente')

  const [customerHasContracts, setCustomerHasContracts] = useState<boolean>(false)

  const [customerOnlyContractSold, setCustomerOnlyContractSold] = useState<boolean>(false)

  const [containerPageWidth, setContainerPageWidth] = useState<number>(pageWidth.medium)

  const [pendingDeals, setPendingDeals] = useState<ProposalsAndPendingsInterface[]>([])

  const [pendingProposals, setPendingProposals] = useState<ProposalsAndPendingsInterface[]>([])

  const hasCampaign = !!campaignSelector.campaignType
  const buttonType = hasCampaign ? ButtonTypeEnum.Default : ButtonTypeEnum.Highlight
  const iconColor = hasCampaign ? undefined : blue

  useEffect(() => {
    if (!user) return

    getProposalsAndPendings(user.id).then((response) => {
      const deals = response.filter((deal) => deal.statusEnum === 'PE')
      const proposals = response.filter((proposal) => proposal.statusEnum === 'PR')

      setPendingDeals(deals)
      setPendingProposals(proposals)
    })

    setCustomerName(user.personType === PersonTypeEnum.PJ ? user.name : firstName(user.name))
    hasContracts(user.id, idEntryAutomation)
      .then((response) => {
        const { hasContracts: aHasContracts, onlyContractSold } = response
        const aCustomerOnlyContractSold = !!onlyContractSold
        setCustomerOnlyContractSold(aCustomerOnlyContractSold)
        setCustomerHasContracts(aHasContracts && !aCustomerOnlyContractSold)

        if (response) {
          setContainerPageWidth(pageWidth.large)
        }
      })
      .finally(() => setLoadedData(true))

    store.dispatch({
      type: 'SET_INITIAL_DISCHARGE_CONTRACTS',
    })
  }, [firstName, idEntryAutomation, user])

  const handleLogout = () => {
    regEventGa(
      GoogleAnalyticsEventCategoryTypeEnum.Autenticacao,
      GoogleAnalyticsEventTypeEnum.AutenticacaoSair,
      'Botão Menu do Cliente',
    )

    store.dispatch({ type: 'LOGOUT' })
  }

  const handleRedirect = (page: string) => {
    history.push(page)
  }

  const { pluralize } = usePluralization()

  const getCard = () => {
    const card = customerOnlyContractSold ? (
      <>
        <H4 marginBottom={size32} marginTop={size32}>Contrato cedido!</H4>
        <Card>
          <CardBody>
            <P2>
              Contrato cedido. Dúvidas ou mais informações, ligue para a central de relacionamento através do telefone: 0800 648 0048.
            </P2>
            <Button type="button" displayBlock onClick={handleLogout}>
              Ok, sair do Portal
            </Button>
          </CardBody>
        </Card>
      </>
    ) : (
      <>
        <H4 marginBottom={size32} marginTop={size32}>Tudo em ordem!</H4>
        <Card>
          <CardBody>
            <P2>
              No momento, você não tem contratos para negociar com a
              {' '}
              <Strong>VIACERTA</Strong>
              .
            </P2>
            <Button type="button" displayBlock onClick={handleLogout}>
              Ok, sair do Portal
            </Button>
          </CardBody>
        </Card>
      </>
    )

    return (
      <Row>
        <Col md={6}>{card}</Col>
        <Col md={6}>
          <H4 marginBottom={size32} marginTop={size32}>Dúvidas?</H4>
          <ContactMethods columnSize={12} />
        </Col>
      </Row>
    )
  }

  return (
    <PortalTemplate
      actionBar={false}
      wizard={false}
      hasDevelopedByViAcerta={[CampaignType.Both, CampaignType.Partner].includes(
        campaignSelector.campaignType as CampaignType,
      )}
    >
      <PageContainer width={containerPageWidth}>
        {loadedData && (
          <>
            <H2Styled>
              Olá,
              {' '}
              {customerName.toLowerCase()}
              !
            </H2Styled>
            <Row>
              <Col lg={customerHasContracts ? 8 : 12}>
                <P1 color={textBlack} marginBottom="0">
                  Aqui no Portal, você resolve pendências de empréstimos e cartões de crédito
                  adquiridos em nossas empresas parceiras. É muito rápido e fácil!
                </P1>
              </Col>
              {[CampaignType.Both, CampaignType.Charge].includes(
                campaignSelector.campaignType as CampaignType,
              ) && (
                <Col xs={12}>
                  <picture>
                    <source srcSet={campaignSelector.bannerCustomerMenuMobile} media={`(max-width: ${breakpoint.sm}px)`} />
                    <BannerImage
                      src={campaignSelector.bannerCustomerMenu}
                      marginTop="2rem"
                      alt="Banner da campanha"
                    />
                  </picture>
                </Col>
              )}
            </Row>
            {customerHasContracts ? (
              <>
                <H5 as="h2" fontWeight={600} marginTop={size20} marginBottom={size20}>
                  Escolha uma opção:
                </H5>
                <Row justify="center">
                  <ColStyled lg={pendingProposals.length ? 6 : 4}>
                    <Card height={size100Percent} marginBottom={size0}>
                      <CardBodyStyled>
                        <div>
                          <TagWrapper>
                            <Tag>Opção recomendada</Tag>
                          </TagWrapper>
                          <CardInfoWrapper>
                            <IconWrapper>
                              <LateIcon />
                            </IconWrapper>
                            <P2Bold marginBottom="0">
                              Quero quitar valores em atraso com desconto
                            </P2Bold>
                          </CardInfoWrapper>
                        </div>
                      </CardBodyStyled>
                      <CardFooter>
                        <Button
                          type="button"
                          displayBlock
                          onClick={() => handleRedirect('/quitacao')}
                          styledButton={
                            campaignSelector.campaignType
                              ? ButtonTypeEnum.White
                              : ButtonTypeEnum.Highlight
                          }
                        >
                          Quitar valores à vista
                        </Button>
                      </CardFooter>
                    </Card>
                  </ColStyled>
                  <ColStyled lg={pendingProposals.length ? 6 : 4}>
                    <Card height={size100Percent} marginBottom={size0} negative>
                      <CardBodyStyled>
                        <CardInfoWrapper>
                          <IconWrapper>
                            <HandsIcon color={iconColor} />
                          </IconWrapper>
                          <P2Bold marginBottom="0">
                            Estou com dificuldade de fazer o pagamento dos valores em dia
                          </P2Bold>
                        </CardInfoWrapper>
                      </CardBodyStyled>
                      <CardFooter>
                        <Button
                          onClick={() => handleRedirect('/renegociacao')}
                          type="button"
                          styledButton={buttonType}
                          displayBlock
                        >
                          Negociar dívida
                          <Hidden lg xs>
                            {' '}
                            e parcelar
                          </Hidden>
                        </Button>
                      </CardFooter>
                    </Card>
                  </ColStyled>
                  <ColStyled lg={pendingProposals.length ? 6 : 4}>
                    <Card height={size100Percent} marginBottom={size0} negative>
                      <CardBodyStyled>
                        <div>
                          {pendingDeals.length > 0 && (
                            <TagWrapper>
                              <Tag>
                                {pendingDeals.length}
                                {pluralize(
                                  pendingDeals.length,
                                  ' negociação pendente',
                                  ' negociações pendentes',
                                )}
                              </Tag>
                            </TagWrapper>
                          )}
                          <CardInfoWrapper>
                            <IconWrapper>
                              <BarcodeIcon color={iconColor} />
                            </IconWrapper>
                            <P2Bold marginBottom="0">
                              Perdi meus boletos de negociações, carnês ou faturas já emitidas
                            </P2Bold>
                          </CardInfoWrapper>
                        </div>
                      </CardBodyStyled>
                      <CardFooter>
                        <Button
                          type="button"
                          displayBlock
                          onClick={() => handleRedirect('/segunda-via')}
                          styledButton={buttonType}
                        >
                          Gerar segunda via
                        </Button>
                      </CardFooter>
                    </Card>
                  </ColStyled>
                  {pendingProposals.length > 0 && (
                    <ColStyled lg={6}>
                      <Card height={size100Percent} marginBottom={size0} negative>
                        <CardBodyStyled>
                          <div>
                            <TagWrapper>
                              <Tag tagType="primary">
                                {pendingProposals && pendingProposals.length}
                                {pluralize(
                                  pendingProposals.length,
                                  ' proposta disponível',
                                  ' propostas disponíveis',
                                )}
                              </Tag>
                            </TagWrapper>
                            <CardInfoWrapper>
                              <IconWrapper>
                                <HandsIcon color={iconColor} />
                              </IconWrapper>
                              <P2Bold marginBottom="0">
                                Propostas aprovadas para negociação com descontos e condições
                                imperdíveis
                              </P2Bold>
                            </CardInfoWrapper>
                          </div>
                        </CardBodyStyled>
                        <CardFooter>
                          <Button
                            type="button"
                            displayBlock
                            onClick={() => handleRedirect('/propostas')}
                            styledButton={buttonType}
                          >
                            Escolher proposta
                          </Button>
                        </CardFooter>
                      </Card>
                    </ColStyled>
                  )}
                </Row>
                <H4 marginBottom={size32}>Dúvidas?</H4>
                <ContactMethods columnSize={4} />
              </>
            ) : (
              getCard()
            )}
            <Flex marginTop="6rem" alignItems="center" justifyContent="center">
              <DevelopedByViAcerta />
            </Flex>
          </>
        )}
      </PageContainer>
    </PortalTemplate>
  )
}

export default CustomerMenu
