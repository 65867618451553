import { css } from 'styled-components'

import { colors, sizes } from '~/assets/styles/variables'

const { lightGray, gray } = colors
const { size0, size5, size10 } = sizes

export const scrollbar = css`
::-webkit-scrollbar {
  padding: ${size10} ${size0};
  width: ${size10};
}
::-webkit-scrollbar-track {
  background: ${lightGray};
}
::-webkit-scrollbar-thumb {
  background: ${gray};
  border-radius: ${size5};
}
`
