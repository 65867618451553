import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from 'react-grid-system'
import { useLocation } from 'react-router-dom'
import { Form, Formik } from 'formik'
import * as yup from 'yup'

import { colors, sizes } from '~/assets/styles/variables'

import Button from '~/components/atoms/Button'
import { H4, H5 } from '~/components/atoms/Heading'
import PageContainer from '~/components/atoms/PageContainer'
import { P4 } from '~/components/atoms/Paragraph'
import DefaultError from '~/components/molecules/DefaultError'
import FormControl from '~/components/molecules/FormControl'
import Wizard from '~/components/molecules/Wizard'
import ContactMethods from '~/components/organisms/ContactMethods'
import PortalTemplate from '~/components/templates/PortalTemplate'

import { pageWidth } from '~/config/layout/pageWidth'
import setTitle from '~/config/title'

import GoogleAnalyticsEventCategoryTypeEnum from '~/models/enums/GoogleAnalyticsEventCategoryTypeEnum'
import GoogleAnalyticsEventTypeEnum from '~/models/enums/GoogleAnalyticsEventTypeEnum'
import NegotiationPortalFlowTypeEnum from '~/models/enums/NegotiationPortalFlowTypeEnum'
import InstallmentTypeEnum from '~/models/enums/InstallmentTypeEnum'
import SendEmailTypeEnum from '~/models/enums/SendEmailTypeEnum'
import { WizardInputInterface } from '~/models/interfaces/components/Wizard'
import { ContractBoletoCopyInterface } from '~/models/interfaces/services/Contracts'
import { DealPendingsInterface } from '~/models/interfaces/services/Deals'
import SendBoletoEmailInterface, { InstallmentsSendBoletoEmailInterface } from '~/models/interfaces/services/SendBoletoEmail'

import { RootState } from '~/store/reducers'

import { getBoletosFile } from '~/services/Contracts'
import { getBoletosFile as getPendingBoletoFiles } from '~/services/Deals'
import { sendBoletoEmail } from '~/services/SendBoletoEmail'

import { useGoogleAnalytics } from '~/utils/useGoogleAnalytics'
import { useShowModalSendingMail } from '~/utils/useShowModalSendingMail'

import {
  BoletoGenerateButtonWrapper, EmailFormControlWrapper, OrWrapper, SendBoletoEmailRow, SendEmailButtonWrapper,
} from './BoletoGeneration.styles'

const { gray } = colors
const { size0, size32, size64 } = sizes

interface StateInterface {
  contracts: ContractBoletoCopyInterface[]
  pendings: DealPendingsInterface[]
}

interface SendBoletoDataInterface {
  email: string
}

const validationSchema: yup.SchemaOf<SendBoletoDataInterface> = yup.object({
  email: yup.string().email('').required(''),
})

const BoletoGeneration = () => {
  setTitle('Geração de boletos - Segunda via')

  const wizardElements: WizardInputInterface[] = [
    {
      title: 'Seleção de contratos',
    },
    {
      title: 'Geração de boletos',
      active: true,
    },
  ]

  const { regEventGa } = useGoogleAnalytics()
  const location = useLocation()
  const state = location.state as StateInterface

  const authSelector = useSelector(
    ({ auth }: RootState) => auth,
  )

  const { user } = authSelector

  const userDataSelector = useSelector(
    ({ userDataProcessing }: RootState) => userDataProcessing,
  )

  const { idEntryAutomation } = userDataSelector

  const { mailError, mailSuccess } = useShowModalSendingMail()

  const [initialValues,
    setInitialValues] = useState<SendBoletoDataInterface>({ email: '' })

  const [contracts,
    setContracts] = useState<ContractBoletoCopyInterface[]>([])

  const [pendings,
    setPendings] = useState<DealPendingsInterface[]>([])

  useEffect(() => {
    if (!user) return

    setInitialValues({ email: user.email })
  }, [user])

  useEffect(() => {
    if (!state || !user) return

    const selectedContracts = state.contracts.filter(
      (contract) => contract.installments.filter(
        (installment) => installment.selected,
      ).length > 0,
    )

    const selectedPendings = state.pendings.filter(
      (deal) => deal.installments.filter(
        (installment) => installment.selected,
      ).length > 0,
    )

    setContracts(selectedContracts)
    setPendings(selectedPendings)
  }, [state, user])

  const containerPageWidth = pageWidth.medium

  const handleSendEmail = (values: SendBoletoDataInterface) => {
    if (!user) return

    regEventGa(
      GoogleAnalyticsEventCategoryTypeEnum.SegundaViaBoleto,
      GoogleAnalyticsEventTypeEnum.SegundaViaBoletoEnvioEmail,
    )

    const installmentsSendBoletoEmail: InstallmentsSendBoletoEmailInterface[] = []
    const installmentsSendPendingBoletoEmail: InstallmentsSendBoletoEmailInterface[] = []

    const selectedContracts: string[] = []

    contracts.forEach((contract) => {
      let hasSelectedInstallments = false
      const installmentNumbers: number[] = []

      contract.installments.forEach((installment) => {
        if (installment.selected) {
          hasSelectedInstallments = true

          installmentsSendBoletoEmail.push(
            {
              contractId: installment.contractId,
              installmentId: installment.externalCode,
              installmentType: InstallmentTypeEnum.Contract,
            },
          )

          installmentNumbers.push(installment.installmentNumber)
        }
      })

      if (hasSelectedInstallments) {
        const selectedContract = `Produto: ${contract.product}, `
                               + `Contrato: ${contract.contractNumber}, `
                               + `Parcelas: ${installmentNumbers.join(', ')}`

        selectedContracts.push(selectedContract)
      }
    })

    pendings.forEach((pending) => {
      let hasSelectedInstallments = false
      const installmentNumbers: number[] = []

      pending.installments.forEach((installment) => {
        if (installment.selected) {
          hasSelectedInstallments = true

          installmentsSendPendingBoletoEmail.push(
            {
              dealId: pending.externalCode,
              installmentId: installment.externalCode,
              installmentType: InstallmentTypeEnum.Deal,
            },
          )

          installmentNumbers.push(installment.installmentNumber)
        }
      })

      if (hasSelectedInstallments) {
        const selectedContract = `Produto: ${pending.typeDescription}, `
                               + `Contrato: ${pending.externalCode}, `
                               + `Parcelas: ${installmentNumbers.join(', ')}`

        selectedContracts.push(selectedContract)
      }
    })

    const stateSendBoletoEmail: SendBoletoEmailInterface = {
      customerId: user.id,
      installments: [...installmentsSendPendingBoletoEmail, ...installmentsSendBoletoEmail] || [],
      to: values.email,
    }

    const eventDescription = `${selectedContracts.join('\n')}`
                           + `\nE-mail: ${values.email}`

    sendBoletoEmail(
      SendEmailTypeEnum.BoletoContratoAcordo,
      stateSendBoletoEmail,
      user.cpfCnpj,
      eventDescription,
      NegotiationPortalFlowTypeEnum.SegundaViaBoleto,
      idEntryAutomation || undefined,
    )
      .then(mailSuccess)
      .catch(mailError)
  }

  const generateBoleto = (contract: ContractBoletoCopyInterface) => {
    if (!user) return

    regEventGa(
      GoogleAnalyticsEventCategoryTypeEnum.SegundaViaBoleto,
      GoogleAnalyticsEventTypeEnum.SegundaViaBoletoImprimirPdf,
    )

    const selectedInstallments: string[] = []
    const installmentNumbers: number[] = []

    contract.installments.forEach((installment) => {
      if (installment.selected) {
        selectedInstallments.push(installment.externalCode)
        installmentNumbers.push(installment.installmentNumber)
      }
    })

    const eventDescription = `Produto: ${contract.product}, `
                           + `Contrato: ${contract.contractNumber}, `
                           + `Parcelas: ${installmentNumbers.join(', ')}`

    getBoletosFile(
      contract.externalCode,
      selectedInstallments,
      user.cpfCnpj,
      eventDescription,
      idEntryAutomation || undefined,
    )
      .then((data) => {
        const file = new Blob([data], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(file)
        window.open(fileURL, '_blank')
      })
  }

  const generatePendingBoleto = (deal: DealPendingsInterface) => {
    getPendingBoletoFiles(
      deal.externalCode,
      user ? user.cpfCnpj : '',
      NegotiationPortalFlowTypeEnum.SegundaViaBoleto,
      idEntryAutomation || undefined,
    )
      .then((data) => {
        const file = new Blob([data], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(file)
        window.open(fileURL, '_blank')
      })
  }

  return (
    <PortalTemplate actionBar={false}>

      <Wizard>{wizardElements}</Wizard>

      <PageContainer
        width={containerPageWidth}
        textAlign="center"
      >

        <Row justify="center">
          <Col md={10}>
            { (contracts.length || pendings.length) ? (
              <>
                <H4>
                  Escolha como deseja gerar os boletos para pagamento:
                </H4>

                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  onSubmit={handleSendEmail}
                  validateOnMount
                  validationSchema={validationSchema}
                >
                  {({
                    handleChange, isValid, values,
                  }) => (
                    <Form autoComplete="off">
                      <SendBoletoEmailRow>
                        <EmailFormControlWrapper>
                          <FormControl
                            marginBottom={size0}
                            name="email"
                            onChange={handleChange}
                            placeholder="Digite seu e-mail"
                            showError={false}
                            title="Enviar boleto por e-mail"
                            type="email"
                            value={values.email}
                          />
                        </EmailFormControlWrapper>

                        <SendEmailButtonWrapper>
                          <Button
                            type="submit"
                            disabled={!isValid}
                            displayBlock
                            size="large"
                          >
                            Enviar e-mail
                          </Button>
                        </SendEmailButtonWrapper>
                      </SendBoletoEmailRow>
                    </Form>
                  )}
                </Formik>

                <OrWrapper>ou</OrWrapper>

                <BoletoGenerateButtonWrapper>
                  <>
                    {contracts.map((contract) => (
                      <Button
                        type="button"
                        displayBlock
                        size="large"
                        onClick={() => generateBoleto(contract)}
                      >
                        {contracts.length > 1
                          ? `Boletos ${contract.product}`
                          : 'Gerar boleto em PDF'}
                      </Button>
                    ))}
                  </>
                </BoletoGenerateButtonWrapper>

                <BoletoGenerateButtonWrapper>
                  <>
                    {pendings.map((deal) => (
                      <Button
                        type="button"
                        displayBlock
                        size="large"
                        onClick={() => generatePendingBoleto(deal)}
                      >
                        {`Boletos ${deal.negotiation.name}`}
                      </Button>
                    ))}
                  </>
                </BoletoGenerateButtonWrapper>

                <P4
                  color={gray}
                  marginBottom={size64}
                >
                  Para visualizar os boletos protegidos por senha, é necessário informar os 4 primeiros dígitos de seu CPF.
                </P4>
              </>
            ) : (
              <DefaultError marginBottom={size32}>
                Nenhuma parcela selecionada para geração de boletos.
              </DefaultError>
            )}

            <H5 marginBottom={size32}>
              Dúvidas? Entre em contato:
            </H5>

            <ContactMethods />
          </Col>
        </Row>
      </PageContainer>
    </PortalTemplate>
  )
}

export default BoletoGeneration
