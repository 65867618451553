import { theme } from './initialState'
import { ThemeActionTypes } from './types'

export const themeReducer = (state = theme, action: ThemeActionTypes) => {
  switch (action.type) {
    case '@theme/SET_PRIMARY_COLOR':
      return {
        ...state,
        palette: {
          ...state.palette,
          primary: {
            ...state.palette.primary,
            main: action.payload,
          },
        },
      }

    default:
      return state
  }
}
