import React, { ChangeEvent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  FieldArray, Form, Formik,
} from 'formik'
import { useHistory, useLocation } from 'react-router-dom'
import { Col, Row } from 'react-grid-system'
import * as yup from 'yup'

import { sizes } from '~/assets/styles/variables'

import Button from '~/components/atoms/Button'
import { H4, H5 } from '~/components/atoms/Heading'
import PageContainer from '~/components/atoms/PageContainer'
import ActionBar from '~/components/molecules/ActionBar'
import DefaultError from '~/components/molecules/DefaultError'
import FormControl from '~/components/molecules/FormControl'
import CountDown from '~/components/molecules/Timer'
import ContactMethods from '~/components/organisms/ContactMethods'
import PortalTemplate from '~/components/templates/PortalTemplate'
import { AutoSubmit } from './components'

import { pageWidth } from '~/config/layout/pageWidth'
import setTitle from '~/config/title'

import AuthenticationCodeSendingTypeEnum from '~/models/enums/AuthenticationCodeSendingTypeEnum'
import ButtonTypeEnum from '~/models/enums/ButtonTypeEnum'
import GoogleAnalyticsEventCategoryTypeEnum from '~/models/enums/GoogleAnalyticsEventCategoryTypeEnum'
import GoogleAnalyticsEventTypeEnum from '~/models/enums/GoogleAnalyticsEventTypeEnum'

import {
  ConfirmAuthenticationCodeInputInterface,
  GenerateAuthenticationCodeInputInterface,
} from '~/models/interfaces/services/Auth'

import { confirmAuthenticationCode, generateAuthenticationCode } from '~/services/Auth'

import store from '~/store'
import { RootState } from '~/store/reducers'

import { useGoogleAnalytics } from '~/utils/useGoogleAnalytics'
import AuthenticationFlowTypeEnum from '~/models/enums/AuthenticationFlowTypeEnum'

const { size10, size32, size64 } = sizes

interface StateInterface {
  cpfCnpj: string
  hasMainCellPhone: boolean
  hasEmail: boolean
  flow?: AuthenticationFlowTypeEnum | string
}

interface CodeGenerationDataInterface {
  cpfCnpj: string
  flow?: AuthenticationFlowTypeEnum | string
  authenticationCode: string[]
  idEntryAutomation?: number
}

const GenerateAccessCode = () => {
  setTitle('Confirmar código de acesso')

  const containerPageWidth = pageWidth.smaller
  const history = useHistory()
  const location = useLocation()
  const { regEventGa } = useGoogleAnalytics()
  const stateLocal = location.state as StateInterface
  const {
    cpfCnpj, hasMainCellPhone, hasEmail, flow,
  } = stateLocal

  const requestHandlerSelector = useSelector(
    ({ requestHandler }: RootState) => requestHandler,
  )

  const userDataSelector = useSelector(
    ({ userDataProcessing }: RootState) => userDataProcessing,
  )

  const { idEntryAutomation } = userDataSelector

  const codeDuration = 90

  const initialValues: CodeGenerationDataInterface = {
    cpfCnpj,
    flow,
    authenticationCode: ['', '', '', '', '', ''],
    idEntryAutomation,
  }

  const [seconds,
    setSeconds] = useState(codeDuration)

  const [expired,
    setExpired] = useState<boolean>(false)

  const [exceededAttempts,
    setExceededAttempts] = useState<boolean>(false)

  const [errorMessage,
    setErrorMessage] = useState<string>('')

  useEffect(() => {
    const timer = setTimeout(() => {
      if (seconds > 1) {
        setSeconds(seconds - 1)
      } else {
        setExpired(true)
      }
    }, 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [seconds])

  useEffect(() => {
    setErrorMessage(expired ? 'O código expirou.' : '')
  }, [expired])

  useEffect(() => {
    if (requestHandlerSelector.error) {
      const { error } = requestHandlerSelector

      if (error.response && error.response.status === 400) {
        setErrorMessage(error.response.data.userMessage)
        setExceededAttempts(true)
      } else if (error.response && error.response.status === 404) {
        setErrorMessage('Código inválido.')
      } else {
        setErrorMessage('Ocorreu um erro inesperado. Por favor, tente novamente em alguns instantes.')
      }
    }
  }, [requestHandlerSelector])

  const selectField = (field: HTMLInputElement | null) => {
    if (field !== null) field.select()
  }

  const handleResendCode = (
    resetForm: () => void,
    sendingType: AuthenticationCodeSendingTypeEnum,
  ) => {
    const input: GenerateAuthenticationCodeInputInterface = {
      cpfCnpj,
      sendingType,
      idEntryAutomation,
      isResendToken: true,
    }

    const resendTypeDesc = sendingType === AuthenticationCodeSendingTypeEnum.Email
      ? GoogleAnalyticsEventTypeEnum.AutenticacaoEmailReenvio
      : GoogleAnalyticsEventTypeEnum.AutenticacaoSmsReenvio

    regEventGa(
      GoogleAnalyticsEventCategoryTypeEnum.Autenticacao,
      resendTypeDesc,
    )

    generateAuthenticationCode(input)
      .then(() => {
        setSeconds(codeDuration)
        setExpired(false)
        resetForm()
      })
  }

  const handleChangeDigit = (event: ChangeEvent<HTMLInputElement>) => {
    const { maxLength, value, name } = event.target

    const [fieldName, fieldIndex] = name.split('.')

    const fieldIntIndex = parseInt(fieldIndex, 10)
    if (value.length >= maxLength) {
      const nextfield: HTMLInputElement | null = document.querySelector(
        `input[name='${fieldName}.${fieldIntIndex + 1}']`,
      )
      selectField(nextfield)
    }
    return event.target.value.replace(/\D/g, '').substr(0, 1)
  }

  const handleKeyDown = (event: any) => {
    const { value, name } = event.target
    const [fieldName, fieldIndex] = name.split('.')
    if (event.keyCode === 8 && fieldIndex > 0 && value === '') {
      const previousfield: HTMLInputElement | null = document.querySelector(
        `input[name='${fieldName}.${fieldIndex - 1}']`,
      )
      selectField(previousfield)
    }
  }

  const handlePasteCode = (event: ClipboardEvent, fieldIndex: number, setFieldValue: any) => {
    event.preventDefault()

    if (fieldIndex === 0) {
      const clipboardData = event.clipboardData?.getData('text/plain') || ''

      let authenticationCode = clipboardData
        .replace(/\D/g, '')
        .substring(0, 6)
        .padEnd(6, '*')
        .split('')

      authenticationCode = authenticationCode.map((digit) => (digit === '*' ? '' : digit))

      authenticationCode.forEach((digit, index) => {
        setFieldValue(`authenticationCode.${index}`, digit)
      })

      const firstEmptyDigit = authenticationCode.findIndex((d) => d === '')

      const indexSelectField = firstEmptyDigit >= 0
        ? firstEmptyDigit
        : authenticationCode.length - 1

      const fieldToBeFocused: HTMLInputElement | null = document.querySelector(
        `input[name='authenticationCode.${indexSelectField}']`,
      )

      selectField(fieldToBeFocused)
    }
  }

  const handleSubmit = (data: CodeGenerationDataInterface) => {
    store.dispatch({ type: 'LOGOUT' })

    const isFlowMain = !data.flow || data.flow === AuthenticationFlowTypeEnum.Main

    const input: ConfirmAuthenticationCodeInputInterface = {
      cpfCnpj: data.cpfCnpj,
      authenticationCode: data.authenticationCode.join(''),
      idEntryAutomation,
    }

    confirmAuthenticationCode(input)
      .then((response) => {
        store.dispatch({ type: 'LOGIN', payload: response })
        if (isFlowMain) {
          history.push('/menu-cliente')
        } else {
          history.push('/propostas')
        }
      })
  }

  const validationSchema: yup.SchemaOf<CodeGenerationDataInterface> = yup.object({
    cpfCnpj: yup.string().required(''),
    authenticationCode: yup.array().of(yup.string().required('')),
    flow: yup.mixed<AuthenticationFlowTypeEnum>(),
    idEntryAutomation: yup.number().notRequired().transform((value) => (Number.isNaN(value) ? undefined : value)),
  })

  return (
    <PortalTemplate wizard={false}>
      <PageContainer textAlign="center" width={containerPageWidth}>
        <>
          <H4>
            Digite o código de autenticação de 6 dígitos recebido:
          </H4>

          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validateOnChange
            validateOnMount
            validationSchema={validationSchema}
          >
            {({
              handleChange, isValid, resetForm, values, setFieldValue,
            }) => (
              <Form>
                <Row gutterWidth={10}>
                  <FieldArray
                    name="authenticationCode"
                    render={({ name }) => (
                      initialValues.authenticationCode.map((digit, index) => (
                        <Col key={index.toString()}>
                          <FormControl
                            autoFocus={index === 0}
                            isDisabled={expired}
                            max={9}
                            maxLength={1}
                            min={0}
                            name={`${name}.${index}`}
                            onChange={(event) => handleChange(`${name}.${index}`)(handleChangeDigit(event))}
                            onKeyDown={(event) => handleKeyDown(event)}
                            onPaste={(event) => handlePasteCode(event, index, setFieldValue)}
                            required
                            showError={false}
                            textAlign="center"
                            type="number"
                            value={values.authenticationCode[index]}
                          />
                        </Col>
                      ))
                    )}
                  />
                </Row>

                {isValid && (
                  <AutoSubmit />
                )}

                {errorMessage && (
                  <DefaultError marginBottom={size32}>{errorMessage}</DefaultError>
                )}

                {!expired && (
                  <CountDown seconds={seconds} />
                )}

                <Row
                  gutterWidth={10}
                  justify="center"
                >
                  {hasMainCellPhone && (
                    <Col xs={7}>
                      <div style={{ marginBottom: hasEmail ? size10 : size64 }}>
                        <Button
                          displayBlock
                          disabled={!expired || exceededAttempts}
                          onClick={() => handleResendCode(
                            resetForm, AuthenticationCodeSendingTypeEnum.SMS,
                          )}
                          size="small"
                          styledButton={ButtonTypeEnum.Outline}
                          type="button"
                        >
                          Reenviar SMS
                        </Button>
                      </div>
                    </Col>
                  )}

                  {hasEmail && (
                    <Col xs={7}>
                      <div style={{ marginBottom: size64 }}>
                        <Button
                          disabled={!expired || exceededAttempts}
                          displayBlock
                          onClick={() => handleResendCode(
                            resetForm, AuthenticationCodeSendingTypeEnum.Email,
                          )}
                          size="small"
                          styledButton={ButtonTypeEnum.Outline}
                          type="button"
                        >
                          Reenviar e-mail
                        </Button>
                      </div>
                    </Col>
                  )}
                </Row>

                <H5 marginBottom={size32}>
                  Dúvidas? Entre em contato:
                </H5>

                <ContactMethods background="dark" columnSize={4} />

                <ActionBar
                  submitActive={isValid && !expired}
                  width={containerPageWidth}
                />
              </Form>
            )}
          </Formik>
        </>
      </PageContainer>
    </PortalTemplate>
  )
}

export default GenerateAccessCode
