import styled from 'styled-components'

import { colors, zIndex } from '~/assets/styles/variables'

const { blue } = colors

export const Container = styled.section`
  background: ${`${blue}66`};
  bottom: 0;
  display: grid;
  left: 0;
  place-items: center center;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${zIndex.fifthLayer};
`
