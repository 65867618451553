import { Col, Row } from 'react-grid-system'
import styled, { css } from 'styled-components'

import { colors, sizes } from '~/assets/styles/variables'

import { P3Bold } from '~/components/atoms/Paragraph'

import { device } from '~/config/device'

const { white, orange } = colors

const {
  size0, size4, size10, size12, size16, size24, size50Percent, size100Percent,
} = sizes

interface ContactMethodsInterface {
  background?: 'light' | 'dark'
  lg?: number
  marginBottom?: string
}

export const RowStyled = styled(Row)<ContactMethodsInterface>`
  margin-bottom: ${({ marginBottom }) => marginBottom};
  row-gap: ${size16};
`

export const ColStyled = styled(Col)`
  margin-bottom: ${size16};

  @media ${device.lg} {
    ${({ lg }: ContactMethodsInterface) => lg === 4
      && css`
        margin-bottom: 0;
      `}
  }

  &:last-child {
    margin-bottom: 0;
  }
`

type LinkContactMethodProps = {
  hoverColor?: 'primary' | 'orange'
} & ContactMethodsInterface

export const LinkContactMethod = styled.a<LinkContactMethodProps>`
  align-items: center;
  background-color: ${white};
  border-radius: ${size12};
  color: ${({ theme: { palette } }) => palette.text.primary};
  display: flex;
  padding: ${size16};
  text-align: left;
  transition: background-color 0.25s;
  word-break: break-word;

  &:hover {
    color: ${({ theme: { palette }, hoverColor = 'orange' }) => (hoverColor === 'orange' ? orange : palette.primary.main)};
  }

  &:focus .icon,
  &:hover .icon {
    transform: scale(1.2);
  }

  ${({ background }) => background === 'dark'
    && css`
      background-color: transparent;
      padding: ${size0};
    `}
`

type IconWrapperProps = {
  background?: 'orange' | 'primary'
  size?: number
}

export const IconWrapper = styled.div<IconWrapperProps>`
  border-radius: ${size50Percent};
  color: ${white};
  background-color: ${({ theme: { palette }, background = 'orange' }) => (background === 'orange' ? orange : palette.primary.main)};
  display: flex;
  height: ${({ size }) => (size ? `${size}px` : size24)};
  margin-right: ${size10};
  padding: ${size4};
  transition: background-color 0.25s, transform 0.25s ease-in-out;
  width: ${({ size }) => (size ? `${size}px` : size24)};

  svg {
    height: ${size100Percent} !important;
    width: ${size100Percent} !important;
  }
`

export const Info = styled.div`
  flex: 1;
`

export const Title = styled(P3Bold)<Pick<ContactMethodsInterface, 'background'>>`
  && {
    font-size: ${({ theme, background }) => theme.fontSizes[background === 'light' ? 'fontSize18' : 'fontSize14']};
    text-transform: ${({ background }) => (background === 'light' ? 'uppercase' : 'none')};
  }
  line-height: 1.7;
  margin-bottom: ${size0};
`
