import { CampaignInterface } from '~/models/interfaces/services/Campaign'
import { initialState } from './initialState'
import { CampaignActionTypes } from './types'

export const campaignReducer = (state = initialState, action: CampaignActionTypes) => {
  switch (action.type) {
    case '@campaign/SET_CAMPAIGN': {
      const getBucketURL = (env: 'prod' | 'dev') => `https://storage.googleapis.com/bucket-de-arquivos-${env}-open`

      const baseUrl = ['local', 'dev'].includes(process.env.REACT_APP_ENV || '')
        ? getBucketURL('dev')
        : getBucketURL('prod')

      return Object.entries(action.payload).reduce((acc, [key, value]) => {
        if (
          [
            'logo',
            'bannerCustomerMenu',
            'bannerCustomerMenuMobile',
            'bannerStartingPage',
            'bannerStartingPageMobile',
            'bannerFlow',
            'bannerFlowMobile',
          ].includes(key)
        ) {
          return {
            ...acc,
            [key]: `${baseUrl}/${value}`,
          }
        }

        return {
          ...acc,
          [key]: value,
        }
      }, {} as CampaignInterface)
    }
    case '@campaign/CLEAR_CAMPAIGN':
      return initialState
    default:
      return state
  }
}
